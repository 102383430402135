import { BrowserRouter, useRoutes } from 'react-router-dom';
import routes from './routes';
import Header from './components/public/Header';
import Footer from './components/public/Footer';
import AdminFooter from './components/admin/footer';
import AdminHeader from './components/admin/header';
import AdminSidebar from './components/admin/Siderbar';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
function App() {
    const [path, setpath] = useState(false);
    const AppRoutes = () => {
        return useRoutes(routes);
    };
    useEffect(() => {
        let pathLoc = document.location.pathname.toLowerCase().split('/');
        if (pathLoc[1] && pathLoc[2] && pathLoc[1]=== 'admin') {
            setpath(true);
        } else {
            setpath(false);
        }
    }, []);
    setInterval(() => {
        let pathLoc = document.location.pathname.toLowerCase().split('/');
        if (pathLoc[1] && pathLoc[2] && pathLoc[1]=== 'admin') {
            setpath(true);
        } else {
            setpath(false);
        }
    }, 1);
    return (
        <>
            <BrowserRouter>
            <ToastContainer />
                {
                    !path ?
                        <>
                            <Header />
                            <AppRoutes />
                            <Footer />
                        </>
                        :
                        <>
                            <div class="main-wrapper">
                                <AdminSidebar />
                                <div class="page-wrapper">
                                    <AdminHeader />
                                    <main className='page-content'>
                                        <AppRoutes />
                                    </main>
                                    <AdminFooter />
                                </div>
                            </div>
                        </>
                }

            </BrowserRouter>
        </>
    );
}

export default App;