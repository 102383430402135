import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { toast } from 'react-toastify';
import { FiEdit } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Api from '../Auth/Auth';
function EditSubmenu() {
    const urlredirect = useNavigate();
    const url = useParams();
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    let [info, setData] = useState({ data: [{ sno: '1', name: '' }] });
    let [cate, getcate] = useState([]);
    const getApiData = () => {
        Api.get("/menu-list").then(function (response) {
            setData(response.data);
        }).catch(function (err) {
            console.log(err);
        })
    };
    useEffect(() => async() =>{
        getApiData();
        Api.get("/getmenu?sno=" + url.id).then(function (response) {
            if (response.data.status == 'success') {
                document.getElementById('url').value = response.data.data[0].url;
                var status = '';
                if (response.data.data[0].status == 1) { status += "<option value='0'>Inactive</option><option value='1'selected>Active</option>" } else { status += "<option value='0'>Inactive</option><option value='1'>Active</option>" }
                document.getElementById('name').value = response.data.data[0].name;
                document.getElementById('actstatus').innerHTML = status;
                getcate(response.data.data[0].category);
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);
    const Updatesubmenu = (sub) => {
        sub.preventDefault();
        var name = sub.currentTarget.elements.name.value;
        var cate = sub.currentTarget.elements.preant.value;
        var urls = sub.currentTarget.elements.url.value;
        if (name != '' && cate != '' && urls != '') {
            fetch(process.env.REACT_APP_API_URL + '/update_menu', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({
                    sno: url.id,
                    sort: '',
                    name: name,
                    icon: '',
                    url: urls,
                    categorie: cate,
                    ActiveStatus: sub.currentTarget.elements.actstatus.value

                })
            }).then(response => response.json())
                .then(data => {
                    if (data['status'] == 'success') {
                        toast_alert('Menu Succussfully Update...', 0);
                        getApiData();
                        urlredirect('/admin/submenu');
                    } else {
                        toast_alert(data['status'], 1);
                    }
                }).catch((error) => {
                    toast_alert('something went wrong', 1);
                });
        } else {
            if (name == '') { toast_alert('Please enter manu name...!!', 1); return; }
            if (url == '') { toast_alert('Please enter manu url...!!', 1); return; }
            if (cate == '') { toast_alert('Please select manu category...!!', 1); return; }
        }
    }
    const Delete_menu = (m) => {
        fetch(process.env.REACT_APP_API_URL + '/del-menu', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({
                sno: m.currentTarget.id
            })
        }).then(response => response.json())
            .then(data => {
                if (data['status'] == 'success') {
                    toast_alert('Menu Succussfully deleted.', 0);
                    getApiData();
                    urlredirect('/admin/submenu');
                } else {
                    toast_alert(data['status'], 1);
                }
            }).catch((error) => {
                toast_alert('something went wrong', 1);
            });
    }
    return (<>
        <Card>
            <Card.Header>Submenu</Card.Header>
        </Card>
        <div className='row mt-4'>
            <div className='col-lg-5 col-xl-5 grid-margin grid-margin-xl-0 stretch-card'>
                <Card>
                    <Card.Header><FiEdit /> Edit Submenu</Card.Header>
                    <Card.Body>
                        <Form onSubmit={Updatesubmenu}>
                            <fieldset>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="name">Submenu Name</Form.Label>
                                    <Form.Control id="name" placeholder="Name" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="url">Url</Form.Label>
                                    <Form.Control id="url" placeholder="Url" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="preant">Parent</Form.Label>
                                    <Form.Select id="preant">
                                        <option value=''>--Select Mainmenu --</option>
                                        {
                                            info && info.data.map((menu) => {
                                                if (menu.category == 0) {
                                                    if(cate==menu.sno){
                                                        return (<>
                                                            <option value={menu.sno} selected>{menu.name}</option>
                                                        </>)
                                                    }else{
                                                        return (<>
                                                            <option value={menu.sno}>{menu.name}</option>
                                                        </>)
                                                    }
                                                    
                                                }
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="status">Status</Form.Label>
                                    <Form.Select id="actstatus">
                                        <option value={0}>Inactive</option>
                                        <option value={1}>Active</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        type="checkbox"
                                        id="disabledFieldsetCheck"
                                        label="Remember Me"
                                    />
                                </Form.Group>
                                <Button type="submit">Update</Button>
                            </fieldset>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
            <div className='col-lg-5 col-xl-7 grid-margin grid-margin-xl-0 stretch-card'>
                <Card>
                    <Card.Header>Submenu List</Card.Header>
                    <Card.Body>
                        <Table bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Menu</th>
                                    <th>Parent</th>
                                    <th>Children</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    info && info.data.map((pmenu, i) => {
                                        if (pmenu.category == 0) {
                                            var cate = pmenu.sno;
                                            return (<>{
                                                info && info.data.map((smenu, i) => {
                                                    if (cate == smenu.category)
                                                        return (<>
                                                            <tr>
                                                                <td>{smenu.name}</td>
                                                                <td>{pmenu.name}</td>
                                                                <td>
                                                                    {
                                                                        info && info.data.map((chl) => {
                                                                            if (smenu.sno == chl.category)
                                                                                return (<>
                                                                                    <div className="Container" dangerouslySetInnerHTML={{ __html: chl.name + ",<br/>" }}></div>
                                                                                </>)
                                                                        })
                                                                    }
                                                                </td>
                                                                <td>{smenu.status === 1 ? <div className='bg-success text-white rounded-pill text-center'>Active</div> : <div className='bg-warning text-white rounded-pill text-center'>inactive</div>}</td>
                                                                <td>
                                                                    <DropdownButton id="dropdown-basic-button" title="Action">
                                                                        <Dropdown.Item href={process.env.REACT_APP_BASE_URL + "/admin/submenu/" + smenu.sno}>Edit</Dropdown.Item>
                                                                        <Dropdown.Item onClick={Delete_menu} id={smenu.sno}>Delete</Dropdown.Item>
                                                                    </DropdownButton>
                                                                </td>
                                                            </tr>
                                                        </>)
                                                })
                                            }
                                            </>)
                                        }
                                    })

                                }


                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
        </div>
    </>);
}

export default EditSubmenu;