import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { useNavigate } from "react-router-dom";
import Api from '../Auth/Auth';
function EdiMainmenu() {
    const urlredirect = useNavigate();
    const url = useParams();
    const [menu, setmenu] = useState({sno: "", name: "", url: "", status: ""});
    const getvalue = (e) => setmenu({...menu,[e.currentTarget.id]: e.currentTarget.value});
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    let [info, setData] = useState({ data: [{ sno: '1', name: '' }] });
    const getApiData = () => {
        Api.get("/menu-list").then(function (response) {
            setData(response.data);
        }).catch(function (err) {
            console.log(err);
        })
    };
    useEffect(() => {
        Api.get("/getmenu?sno=" + url.id).then(function (response) {
            if (response.data.status == 'success') {
                setmenu(response.data.data[0]);
            }
        }).catch((error) => {
            console.log(error);
        })
        getApiData();
    }, []);
    const Delete_menu = (m) => {
        Api.post('/del-menu', { sno: m.currentTarget.id }).then(res => {
            if (res.data.status == 'success') {
                toast_alert('Menu Succussfully deleted.', 0);
                getApiData();
            } else {
                toast_alert(res.data.status, 1);
            }
        }).catch((error) => {
            toast_alert('something went wrong', 1);
        });
    }
    const updatemanu = (sub) => {
        sub.preventDefault();
        var name = sub.currentTarget.elements.name.value;
        if (name != '') {
            Api.post('/update_menu', {
                    sno: menu.sno,
                    sort: 1,
                    name: menu.name,
                    url: menu.url,
                    categorie: "0",
                    ActiveStatus: menu.status
                }).then(res => {
                    if (res.data.status == 'success') {
                        toast_alert('Menu Succussfully Update...', 0);
                        getApiData();
                        urlredirect('/admin/mainmenu');
                    } else {
                        toast_alert(res.data.status, 1);
                    }
                }).catch((error) => {
                    toast_alert('something went wrong', 1);
                });
        } else {
            if (name == '') { toast_alert('Please enter manu name...!!', 1); return; }
            if (url == '') { toast_alert('Please enter manu url...!!', 1); return; }
        }
    }
    console.log(menu);
    return (<>
        <Card>
            <Card.Header>Main Menu</Card.Header>
        </Card>
        <div className='row mt-4'>
            <div className='col-lg-5 col-xl-5 grid-margin grid-margin-xl-0 stretch-card'>
                <Card>
                    <Card.Header><FiEdit /> Edit Menu</Card.Header>
                    <Card.Body>
                        <Form onSubmit={updatemanu}>
                            <fieldset>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="name">Menu Name</Form.Label>
                                    <Form.Control id="name"value={menu.name} placeholder="Name" onInput={getvalue}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="url">Url</Form.Label>
                                    <Form.Control id="url" value={menu.url}placeholder="Url" onInput={getvalue}/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="status">Status</Form.Label>
                                    <Form.Select id="status"onChange={getvalue}>
                                        <option value={0}selected={menu.status==0?true:false}>Inactive</option>
                                        <option value={1}selected={menu.status==1?true:false}>Active</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        type="checkbox"
                                        id="disabledFieldsetCheck"
                                        label="Remember Me"
                                    />
                                </Form.Group>
                                <Button type="submit">Update</Button>
                            </fieldset>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
            <div className='col-lg-5 col-xl-7 grid-margin grid-margin-xl-0 stretch-card'>
                <Card>
                    <Card.Header>Menu List</Card.Header>
                    <Card.Body>
                        <Table bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Menu</th>
                                    <th>Children</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    info && info.data.map((menu) => {
                                        if (menu.category == 0) {
                                            return (<>
                                                <tr>
                                                    <td>{menu.sort}</td>
                                                    <td>{menu.name}</td>
                                                    <td>{
                                                        info && info.data.map((submenu) => {
                                                            if (menu.sno == submenu.category) {
                                                                return (<>
                                                                    <div className="Container" dangerouslySetInnerHTML={{ __html: submenu.name + ",<br/>" }}></div>

                                                                </>)
                                                            }
                                                        })
                                                    }</td>
                                                    <td>{menu.status === 1 ? <div className='bg-success text-white rounded-pill text-center'>Active</div> : <div className='bg-warning text-white rounded-pill text-center'>inactive</div>}</td>
                                                    <td>
                                                        <DropdownButton id="dropdown-basic-button" title="Action">
                                                            <Dropdown.Item><Link to={"/admin/mainmenu/" + menu.sno}>Edit</Link></Dropdown.Item>
                                                            <Dropdown.Item onClick={Delete_menu} id={menu.sno}>Delete</Dropdown.Item>
                                                        </DropdownButton>
                                                    </td>
                                                </tr>
                                            </>)
                                        }
                                    })
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
        </div>
    </>);
}

export default EdiMainmenu;