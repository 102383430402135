import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { MDBDataTable } from 'mdbreact';
import 'mdbreact/dist/css/mdb.css';
import Api from '../Auth/Auth';
import Moment from 'react-moment';
import 'moment-timezone';
import { Button, Dropdown, Space } from 'antd';
import { Link } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineTool } from 'react-icons/ai';
import { toast } from 'react-toastify';
const VolunteerList = () => {
    const [volunteers, setVolunteer] = useState([]);
    const volunteer = () => {
        Api.get("/membership_all").then(function (res) {
            if (res.status===200 && res.data.status === 1) {
                setVolunteer(res.data.data);
            }
        }).catch(function (err) {
            console.log(err);
        })
    }
    const Deletepage = (e) => {
        Api.get('/delete_membership?id='+ e).then((res)=>{
            if(res.status===200 && res.data.status === 1) {
                toast("Successfully deleted.");
                volunteer();
            }
            toast("Something went wrong...!!");
        }).catch((err) => {
            toast("try again...!!");
        })
    }
    const data = {
        columns: [
            {
                label: '#',
                field: 'sno',
                sort: 'asc',
                width: 'auto'
            },
            {
                label: 'Pic',
                field: 'pic',
                sort: 'adc',
                width: 'auto'
            },
            {
                label: 'Name',
                field: 'name',
                sort: 'asc',
                width: 'auto'
            },
            {
                label: 'Dob',
                field: 'dob',
                sort: 'asc',
                width: 'auto'
            },
            {
                label: 'Phone',
                field: 'phone',
                sort: 'asc',
                width: 'auto'
            }
            ,
            {
                label: 'Status',
                field: 'status',
                sort: 'asc',
                width: 'auto'
            }
            ,{
                label: 'Action',
                field: 'action',
                sort: 'asc',
                width: 'auto'
            }
        ],
        rows: []
    };
    useEffect(() => {
        volunteer();
    }, []);
    console.log(volunteers);
    volunteers && volunteers.map((inf, i) => {
        const items = [
            {
                key: '1',
                label: (
                    <Link rel="noopener noreferrer" to={"/admin/volunteer/" + inf.id}><BiEdit /> Edit</Link>
                ),
            },
            {
                key: '2',
                label: (
                    <Link rel="noopener noreferrer" onClick={()=>Deletepage(inf.id)}><AiFillDelete /> Delete</Link>
                ),
            },
        ];
        data.rows.push({
            sno: i+1,
            pic: <img src={process.env.REACT_APP_IMG_BASE_URL+"/files/membership/"+inf.photo}></img>,
            name: inf.name,
            dob: <Moment format="DD/MMMM/YYYY">{inf.dob}</Moment>,
            phone: inf.phone,
            status: inf.status==1?'Active':'Inactive',
            action: <Space direction="vertical">
                <Space wrap>
                    <Dropdown
                        menu={{
                            items,
                        }}
                        placement="bottom"
                    >
                        <Button><AiOutlineTool /> Action</Button>
                    </Dropdown>
                </Space>
            </Space>
        })
    })

    return (
        <>
            <Card>
                <Card.Header>Voluntees List</Card.Header>
                <Card.Body>
                    <div className='d-flex justify-content-between'>
                        <div className=''>
                            <p className="text-muted mb-3">Total Voluntees :<code>{volunteers.length < 10 ? '0' + volunteers.length : volunteers.length}</code></p>
                        </div>
                        <div>
                            <Link to="/admin/volunteer/add"><button className='custom-btn'> Add New</button></Link>
                        </div>
                    </div>

                    <MDBDataTable
                        striped
                        bordered
                        small
                        data={data}
                    />
                </Card.Body>
            </Card>
        </>
    );
}

export default VolunteerList;