import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { ImPhone } from 'react-icons/im';
import { MdLocationOn } from 'react-icons/md';
import { AiFillMail } from 'react-icons/ai';
import Api from './../Auth/Auth';
const Contactus = () => {
    const navigate = useNavigate();
    const [loader, setloader] = useState(false);
    const [from, setform] = useState({ fname: "", lname: "", phone: "", message: "" })
    const getFromdata = (e) => setform({ ...from, [e.currentTarget.id]: e.currentTarget.value });
    const formSubmit = (e) => {
        e.preventDefault();
        setloader(true);
        Api.post('/form', { name: from.fname + " " + from.lname, phone: from.phone, message: from.message }).then((res) => {
            if (res.status === 200 && res.data.status === 1) {
                toast(res.data.message);
                setform({ fname: "", lname: "", phone: "", message: "" });
                navigate('thankyou');
            } else {
                toast(res.data.message);
            }
            setloader(false);
        }).catch((err) => {
            toast("Try again...!!");
            setloader(false);
        })
    }
    const [contact, setContact] = useState({ info: [{ sno: '', phone: '', email: '', address: '', location: '' }], banner: [{ sno: '', image: '' }] });
    useEffect(() => {
        Api.get("/contact").then((responce) => {
            if (responce.status === 200 && responce.data.status === 'success') {
                setContact(responce.data.data);
            } else {
                console.log('Api Error :' + responce.status, responce.data.status);
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);
    return (<>
        <div className="breadcrumb-area" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMG_BASE_URL + "/img/" + contact?.banner[0]?.image + ")" }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner">
                            <h2 className="page-title">Contact Us</h2>
                            <ul className="page-list">
                                <li><Link to="/">Home</Link></li>
                                <li><Link>Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="contact-inner-area padding-bottom-120 padding-top-120">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-6">
                        <div className="content-area-wrapper bg-image"
                            style={{ backgroundImage: "url(/main/files/static/contactus.jpg)" }}>
                            <div className="content-area">
                                <div className="single-contact-item">
                                    <div className="icon">
                                        <ImPhone />
                                    </div>
                                    <div className="content">
                                        <p><Link to={'tel: ' + contact?.info[0]?.phone} className="details">{contact?.info[0]?.phone}</Link></p>
                                    </div>
                                </div>
                                <div className="single-contact-item">
                                    <div className="icon">
                                        <AiFillMail />
                                    </div>
                                    <div className="content">
                                        <p><Link to={'mailto:' + contact?.info[0]?.email} className="details">{contact?.info[0]?.email}</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="single-contact-item">
                                    <div className="icon">
                                        <MdLocationOn />
                                    </div>
                                    <div className="content">
                                        <div className="Container" dangerouslySetInnerHTML={{ __html: contact?.info[0]?.address }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 offset-lx-1">
                        <div className="row">
                            <div className="col-lg-10 col-sm-11 col-11">
                                <div className="section-title padding-top-25 margin-bottom-55">
                                    <span>Fill The Form</span>
                                    <h2 className="title">
                                        Send us <span>message</span> for any help
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="contact-form style-01">
                            <form className="contact-page-form style-01" onSubmit={formSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" id="fname" placeholder="First Name *" value={from.fname} className="form-control" onInput={getFromdata} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" id="lname" placeholder="Last Name *" value={from.lname} className="form-control" onInput={getFromdata} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <input type="text" id="phone" placeholder="phone" value={from.phone} className="form-control" onInput={getFromdata} required />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea id="message" cols="1" rows="4" value={from.message} placeholder="Send Message *" onInput={getFromdata}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="btn-wrapper">
                                            {
                                                loader ?
                                                    <button class="boxed-btn reverse-color" type="button" disabled>
                                                        <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                        <span role="status">  Sending...</span>
                                                    </button>
                                                    :
                                                    <input type='submit' className="boxed-btn reverse-color" value='Submit Message' />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="map-area">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <div className="contact_map">
                            <iframe
                                src={contact?.info[0]?.location}
                                width="100%" height="100%" style={{ border: "0" }}></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
export default Contactus;
