import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import 'moment-timezone';
import Api from './../Auth/Auth';
const Eventsdetails = () => {
    const [events, setEvents] = useState({ banner: [{ image: { image: '' } }], event: [{ sno: '', image: '', title: '', start_time: '', end_time: '', location: '', charges: '', content: '', map_url: '', organizer: '' }], info: [{ phone: '' }] });
    const url = useParams();
    useEffect(() => {
        Api.get('/event-datails?title=' + url.id).then((response) => {
            if (response.status === 200 && response.data.status === 'success') {
                setEvents(response.data.data);
            } else {
                console.log("Api error: " + response.status);
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [])
    return (
        <>
            <div className="breadcrumb-area" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMG_BASE_URL + "/img/" + events?.banner[0]?.image + ")" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner">
                                <div className="icon">
                                    <img src="/main/files/static/yellow-hart.png" />
                                </div>
                                <h2 className="page-title">Event Deatis</h2>
                                <ul className="page-list">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Event Deatis</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content our-attoryney padding-bottom-120 padding-top-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="events-details-item">
                                <div className="thumb margin-bottom-20">
                                    <img src={process.env.REACT_APP_IMG_BASE_URL + "/events/" + events?.event[0]?.image} />
                                    <div className="post-time">
                                        <h5 className="title"><Moment format="DD">{events?.event[0]?.start_time}</Moment></h5>
                                        <span><Moment format="MMMM">{events?.event[0]?.start_time}</Moment></span>
                                    </div>
                                </div>
                                <div className="content">
                                    <h4 className="title"><Link to="#">{events?.event[0]?.title}</Link></h4>
                                    <ul className="post-meta">
                                        <li><Link to="#"><i className="fas fa-map-marker-alt"></i><span className="Container" dangerouslySetInnerHTML={{ __html: events?.event[0]?.location }}></span></Link></li>
                                        <li><Link to="#"><i className="far fa-clock"></i><Moment format="hh:mm A">{events?.event[0]?.start_time}</Moment> - <Moment format="hh:mm A">{events?.event[0]?.end_time}</Moment></Link></li>
                                    </ul>
                                    <p><div className="Container" dangerouslySetInnerHTML={{ __html: events?.event[0]?.content }}></div></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="widget-area">
                                <div className="widget widget_event style-01">
                                    <h3 className="widget-title style-02">Event Details</h3>
                                    <ul>
                                        <li><Link to="#">Price :<span>{events?.event[0]?.charges === 0 ? 'Free' : <span className="Container" dangerouslySetInnerHTML={{ __html: '&#8377; ' + events?.event[0]?.charges }}></span>}</span></Link></li>
                                        <li><Link to="#">Date : <span> <Moment format="DD-MMMM">{events?.event[0]?.start_time}</Moment> - <Moment format="DD-MMMM">{events?.event[0]?.end_time}</Moment></span></Link></li>
                                        <li><Link to="#">Duration : <span> <Moment format="hh:mm A">{events?.event[0]?.start_time}</Moment> - <Moment format="hh:mm A">{events?.event[0]?.end_time}</Moment> </span></Link></li>
                                        <li><Link to="#">Organizer : <span> {events?.event[0]?.organizer}</span></Link></li>
                                        <li><Link to={"tel:" + events?.info[0]?.phone}>Contact : <span> {events?.info[0]?.phone} </span></Link></li>
                                        <div className="d-grid gap-2">
                                            <Link to={"/event/booking/"+events?.event[0]?.title}><button to="" style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '130px',
                                                border: 'none',
                                                outline: 'none',
                                                background: '#96BE0F',
                                                color: '#FFF',
                                                height: '40px',
                                                marginTop: '20px',
                                            }}>Booking</button></Link>
                                        </div>
                                    </ul>
                                </div>
                                <div className="widget widget_map style-01">
                                    <h4 className="widget-title style-02">Event Location</h4>
                                    <p className="widget-para">{events?.event[0]?.location}</p>
                                    <div className="contact_map-02">
                                        <iframe src={events?.event[0]?.map_url}
                                            style={{ border: "0", width: "100%", height: "100%" }}></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Eventsdetails;