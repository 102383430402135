import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { useNavigate } from "react-router-dom";
const AddSocialIcon = () => {
    const urlredirect = useNavigate();
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    let AddIcon = (e) => {
        e.preventDefault();
        if (e.target.name.value != '' && e.target.icon.value != '') {
            fetch(process.env.REACT_APP_API_URL + '/social_icon', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({
                    name: e.target.name.value,
                    icon: e.target.icon.value,
                    url: e.target.url.value,
                    status: e.target.status.value
                })
            }).then(response => response.json())
                .then(data => {
                    if (data['status'] == 'success') {
                        toast_alert('successfully added', 0);
                        urlredirect('/admin/socialIcon');
                    } else {
                        toast_alert('Fill all requireded field...!!', 1);
                    }
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            toast_alert('Enter icon name and Icon', 1);
        }
    }
    return (<>
        <Card>
            <Card.Header>Add Icon</Card.Header>
            <Card.Body>
                <div className='d-flex justify-content-between'>
                    <div className=''>
                        
                    </div>
                    <div>
                        <Link to="/admin/socialIcon"><button className='custom-btn'>Icon Listing</button></Link>
                    </div>
                </div>
                <div className='mt-4'>
                    <Form onSubmit={AddIcon}>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" id="name" autocomplete="off" required />
                            </Form.Group>

                            <Form.Group as={Col} controlId="icon">
                                <Form.Label>Icon</Form.Label>
                                <Form.Control type="text" id="icon" autocomplete="off" />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="url">
                                <Form.Label>Url</Form.Label>
                                <Form.Control type="text" id="url" autocomplete="off" />
                            </Form.Group>
                        </Row>
                        <Form.Group className="mb-3" controlId="status">
                            <Form.Label htmlFor="status">Status</Form.Label>
                            <Form.Select id="status">
                                <option value="0">Inactive</option>
                                <option value="1">Active</option>
                            </Form.Select>
                        </Form.Group>
                        <Button variant="primary" type="submit">Submit</Button>
                    </Form>
                </div>
            </Card.Body>
        </Card>
    </>)
}
export default AddSocialIcon;