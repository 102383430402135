import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api from './../Auth/Auth';
const Volunteer = () => {
    const [volunteer, setVolunteer] = useState({ banner: {}, volunteer: [] });
    useEffect(() => {
        Api.get('/volunteer-view').then((response) => {
            if (response.status === 200 && response.data.status === 'success') {
                setVolunteer(response.data.data);
            } else {
                console.log('Api Error' + response.status);
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [])
    return (<>
        <div className="breadcrumb-area" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMG_BASE_URL + "/img/" + volunteer?.banner[0]?.image + ")" }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner">
                            <div className="icon">
                                <img src="/main/files/static/green-hart.png" />
                            </div>
                            <h2 className="page-title">Become a volunteer</h2>
                            <ul className="page-list">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Become a volunteer</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="page-content our-attoryney padding-bottom-0 padding-top-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="volunteer-details-item">
                            <div className="section-title margin-bottom-35">
                                <Link to="/membership" className='join-now'>Join Us</Link>                               <span>Join As Volunteer</span>
                                <h2 className="title">Fill the <span>form</span> to join as volunteer
                                </h2>
                                <p>Join us in our mission to change people's lives and build a better future. We can have a
                                    major and long-lasting influence if we work together. as well as emotional.</p>
                            </div>
                            <div className="content">
                                <h4 className="sub-title"><Link to="#">Responsibility of volunteers</Link></h4>
                                <p>Join us in our mission to change people's lives and build a better future. We can have a
                                    major and long-lasting influence if we work together. as well as emotional. Ut enim ad
                                    minim veniam, quis nostrud exercitation ullamco laboris
                                    nisi ut aliquip.</p>
                                <h4 className="sub-title"><Link to="#">Requirment to Join</Link></h4>
                                <ul>
                                    <li><Link to="#"><i className="fas fa-check"></i>If you want to change the world</Link></li>
                                    <li><Link to="#"><i className="fas fa-check"></i>Keep the same cleaner for every visit</Link>
                                    </li>
                                    <li><Link to="#"><i className="fas fa-check"></i>One-off, weekly or fortnightly visits</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <section className="volunteer-area bg-image m-top-02 padding-bottom-90 padding-top-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="volunteer-title-content margin-bottom-50">
                            <div className="section-title desktop-left">
                                <span>Our Volunteer  </span>
                                <h3 className="title"><span>Volunteers</span> who always support us</h3>
                            </div>
                            <div className="section-paragraph">
                                <p>Join us in our mission to change people's lives and build a better future. We can have a
                                    major and long-lasting influence if we work together. as well as emotional.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        volunteer?.volunteer?.map((vol) => {
                            return (<div className="col-lg-3 col-sm-6">
                                <div className="team-single-item">
                                    <div className="thumb">
                                        <img src={process.env.REACT_APP_IMG_BASE_URL + "/files/membership/" + vol.photo} />
                                        <div className="content">
                                            <h4 className="title">{vol.name}</h4>
                                            <div className="social-link">
                                                <ul>
                                                    <li><Link><i className="fas fa-share-alt"></i></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        })
                    }
                </div>
            </div>
        </section>
    </>)
}
export default Volunteer;