import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { MDBDataTable } from 'mdbreact';
import 'mdbreact/dist/css/mdb.css';
import 'moment-timezone';
import { Link, useParams } from 'react-router-dom';
import Api from '../Auth/Auth';
const Booking_details = () => {
  const url = useParams();
  const [event, getEvent] = useState({ booking: [{sno: ''}],event: [{ title: '' ,start_time: '', start_time:'', location: ''}]});
  const data = {
    columns: [
      {
        label: '#',
        field: 'sno',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'DOB',
        field: 'dob',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Phone',
        field: 'phone',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 200
      },
      
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 200
      }
    ],
    rows: []
  };
  useEffect(() => {
    Api.get("/booked_event?event_id=" + url.id).then(function (response) {
      if (response.data.status == 'success') {
        getEvent(response.data.data);
      }
    }).catch(function (err) {
      console.log(err);
    })
  }, []);
  event.booking && event.booking.map((inf) => {
    data.rows.push({
      sno: inf.sno,
      name: inf.name,
      dob: inf.DOB,
      phone: inf.phone,
      email: inf.email,
      status: inf.status==1? 'complite':'cancel'
    })
  })

  return (
    <>
      <Card>
        <Card.Header><strong>Event:</strong> {event?.event[0]?.title}</Card.Header>
        <Card.Body>
          <div className='d-flex justify-content-between'>
            <div className=''>
              <p className="text-muted mb-3">Total Event :<code>{event.booking.length < 10 ? '0' + event.booking.length : event.booking.length}</code></p>
            </div>
            <div>
              <Link to="/admin/event"><button className='custom-btn'> Event List</button></Link>
            </div>
          </div>
          <MDBDataTable
            striped
            bordered
            small
            data={data}
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default Booking_details;