import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdHealthAndSafety } from "react-icons/md";
import { FaGraduationCap } from "react-icons/fa";
import { FaEnvira } from "react-icons/fa";
import Api from './../Auth/Auth';
let About = () => {
    const [about, setAbout] = useState({ what_we_do: [{ sno: '', title: '', content: ''}],banner: [{ sno: '',image: '' }] });
    useEffect(()=>{
        Api.get('/abouts').then((response)=>{
            if(response.status==200 && response.data.status==='success'){
                setAbout(response.data.data);
            }else{
                console.log('Error Api: '+response.status);
            }
        }).catch((error)=>{
            console.log(error);
        })
    },[])
    return (
        <>
            <section>
                <div className="breadcrumb-area" style={{ backgroundImage: "url("+process.env.REACT_APP_IMG_BASE_URL+"/img/"+about?.banner[0]?.image+")" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner">
                                    <h2 className="page-title">About Us</h2>
                                    <ul className="page-list">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="">About Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-area padding-top-115 padding-bottom-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="about-title-content margin-bottom-50">
                                    <div className="section-title desktop-left">
                                        <span>About Us</span>
                                        <h3 className="title">
                                            We Are In A Mission To
                                            Help The <span>
                                                Helpless
                                            </span>
                                        </h3>
                                    </div>
                                    <div className="section-paragraph">
                                        <p>
                                            <span>W</span>elcome to the MNB Foundation's exceptional world, where our constant trust in the power of unity drives our mission to effect significant change.
                                        </p>
                                        <p>
                                            In the face of tragedy and uncertainty, we remain strong and committed to offering timely solutions that answer our communities' pressing needs.
                                            Our undying commitment to improving lives is at the heart of our foundation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-content-area padding-top-60 padding-bottom-60">
                            {/* <div className="bg-img" style={{ backgroundImage: "url(/main/files/static/about1.jpg)" }}></div> */}
                            <div className="row">
                            <div className="col-lg-5">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/ewkDONFjBlQ?si=TW0kssCeuJT4lbL0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                                
                                <div className="col-lg-7">
                                    <div className="">
                                        <div className="section-title">
                                            <p>We recognize that many individuals and families are dealing with difficult situations that compromise their well-being. That is why we focus our efforts on initiatives that have a significant impact on people who need it the most.</p>
                                            <p>
                                                We start on a quest to promote positive change and improve society's most disadvantaged members through our appealing charity fundraising activities. When you donate to our cause, you help to provide important resources, essential services, and life-changing opportunities to individuals in need. We can construct a route to success for persons confronting problems, encouraging them to overcome hurdles and achieve success.
                                            </p>
                                            <p>
                                                Our Foundation is built on transparency and accountability. We hold ourselves to the highest standards, ensuring that every donation is used properly and efficiently, with the greatest possible impact on the ground. Your generous donation will go directly to programs that address essential issues including education, healthcare, reducing hunger, and environmental sustainability.

                                            </p>
                                        </div>
                                        <div className="content">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="problem-area padding-top-90 padding-bottom-50">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-sm-11 col-11">
                                <div className="section-title b-top desktop-center padding-top-25 margin-bottom-55">
                                    <span>What we do</span>
                                    <h2 className="title">
                                        We have chosen the following issues.
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex">
                            <div className="col-lg-4 col-md-6">
                                <div className="problem-single-item margin-bottom-30">
                                    <div className="icon">
                                        <MdHealthAndSafety />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">
                                            <Link href>{about?.what_we_do[0]?.title}</Link>
                                        </h4>
                                        <p><div className="Container" dangerouslySetInnerHTML={{__html: about?.what_we_do[0]?.content}}></div><br /><br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="problem-single-item margin-bottom-30">
                                    <div className="icon style-01">
                                        <FaGraduationCap />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">
                                            <Link href="">{about?.what_we_do[1]?.title}</Link>
                                        </h4>
                                        <p><div className="Container" dangerouslySetInnerHTML={{__html: about?.what_we_do[1]?.content}}></div><br /> <br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="problem-single-item margin-bottom-30">
                                    <div className="icon style-02">
                                        <FaEnvira />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">
                                            <Link href="">{about?.what_we_do[2]?.title}</Link>
                                        </h4>
                                        <p><div className="Container" dangerouslySetInnerHTML={{__html: about?.what_we_do[2]?.content}}></div></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="work-towards-area bg-image padding-bottom-80 padding-top-80" style={{ backgroundImage: "url(/main/files/static/join.jpg)" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="left-content">
                                    <div className="inner-section-title desktop-center">
                                        <h2 className="title">
                                            Join the community to give
                                            education for children
                                        </h2>
                                        <div className="btn-wrapper margin-top-30">
                                            <Link to="#" className="boxed-btn reverse-color"><span>Join Our Community</span></Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="our-latest-area padding-bottom-90 padding-top-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-12">
                                <div className="help-and-support-left">
                                    <div className="section-title margin-bottom-35">
                                        <h2 className="title">
                                            Save a life. Donate to  <span>MNB Foundation</span>
                                        </h2>
                                        <p>
                                            MNB FOUNDATION goes above and above to make a significant difference in the areas that we serve. Here's what differentiates and separates us:

                                        </p>
                                        <p>
                                            1. <strong>
                                                Holistic Approach:
                                            </strong> To address the various needs of individuals and communities, we take a thorough and  holistic approach. We recognize that addressing issues from multiple perspectives, such as healthcare, education, livelihood, and social empowerment, is necessary for long-term transformation.
                                        </p>
                                        <p>
                                            2. <strong>
                                                Collaboration and Partnerships:
                                            </strong> We believe in the power of teamwork. We optimize our collective effect by forming strategic collaborations with like-minded groups, governments, and stakeholders. We pool our resources, knowledge, and skills to achieve better results and help more people in need.
                                        </p>
                                        <p>
                                            3. <strong>
                                                Innovative Solutions:
                                            </strong> We are committed to developing novel solutions to complicated problems. We aspire to develop and implement advanced programs and projects that offer tangible outcomes through research, technology, and continual learning. In our effort to make a lasting change, we are continually pushing limits and exploring new possibilities.
                                        </p>
                                        <p>
                                            4. <span>Transparent and Accountable:</span> We take pleasure in our honesty and accountability. We realize the importance of resource management and make certain that every dollar donated to MNB FOUNDATION is used properly and effectively. We provide our stakeholders with regular updates, reports, and financial disclosures, providing complete openness in our operations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-12">
                                <div className="help-and-support-right bg-image padding-top-20">
                                    <img src="/main/files/static/heands.jpg" style={{ borderRadius: "15px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="problem-area padding-top-80 padding-bottom-80">
                    <div className="bg-img" style={{ backgroundImage: "url(/main/files/static/about1.jpg)" }}></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-4">
                                <div className="problem-area-wrapper">
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-11 col-11">
                                            <div className="section-title margin-bottom-55">
                                                <span>
                                                    Our Mission
                                                </span>
                                                <h2 className="title">
                                                    Providing Access to <span>Healthcare</span> for a Healthier Today and a Brighter Tomorrow.
                                                </h2>
                                                <p>
                                                    Our purpose at MNB FOUNDATION is to enhance people's lives via compassionate and innovative solutions. We work to empower individuals, particularly those experiencing socioeconomic hardships, by giving them the resources, opportunities, and support systems they require. Our mission is to help people live satisfying lives, reach their full potential, and constructively contribute to society.
                                                </p>
                                                <div className="btn-wrapper margin-top-30">
                                                    <Link to="#" className="boxed-btn reverse-color"><span>Donate Now</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}
export default About;