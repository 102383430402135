import React from 'react';
//	Public 
import Home from'./components/public/Home';
import Aboutus from'./components/public/About';
import Ourwork from'./components/public/Ourwork';
import Eventsdetails from'./components/public/Eventsdetails';
import Events from'./components/public/Events';
import Contactus from'./components/public/Contactus';
import Blog from'./components/public/Blog';
import Blog_Details from'./components/public/Blog_details';
import Volunteer from'./components/public/Volunteer';
import DonationPage from'./components/public/DonationPage';
import Donationuser from'./components/public/DonateUser_dt';
import Myaccount from'./components/public/Myaccount';
import Thanks from'./components/public/Thanks';
import Event_booking from'./components/public/Event_booking';
import Error404 from'./components/public/Error404';
import Gallery from'./components/public/Gallery';
// Admin
import Login from'./components/admin/Login';
import Admin404 from'./components/admin/Admin404';
import Dashboard from'./components/admin/Dashboard';
import Banner from'./components/admin/Banner';
import Form from'./components/admin/Form';
import EditBanner from'./components/admin/EditBanner';
import Info from'./components/admin/Info';
import SocialIcon from'./components/admin/Social_icon';
import Sendmail from'./components/admin/Sendmail';
import Subscriber from'./components/admin/Subscriber';
import Mainmenu from'./components/admin/Mainmenu';
import Submenu from'./components/admin/Submenu';
import BlogList from'./components/admin/BlogList';
import Addblog from'./components/admin/AddBlog';
import EditBlog from'./components/admin/EditBlog';
import NewPage from'./components/admin/NewPage';
import Pagelist from'./components/admin/Pagelist';
import AddTestimonial from'./components/admin/AddTestimonial';
import Testimonial from'./components/admin/Testimonial';
import EdiMainmenu from'./components/admin/EdiMainmenu';
import Edisubmenu from'./components/admin/EditSubmenu';
import EditTestimonial from'./components/admin/EditTestimonial';
import EditPage from'./components/admin/EditPage';
import DonatinList from'./components/admin/Donation_List';
import DonationGrid from'./components/admin/Donation_Grid';
import Whatwedo_List from'./components/admin/What_we_do_List';
import Membership from './components/public/Membership';
import MembershipSuccess from './components/public/MembershipSuccess';
import AddWhatwedo from'./components/admin/Add_What_we_do_List';
import Edit_What_we_do from'./components/admin/Edit_What_we_do';
import CountDwon from'./components/admin/CountDwon';
import OurWorkList from'./components/admin/OurWorkList';
import AddOurWorkList from'./components/admin/Add_our_work';
import EditOurWork from'./components/admin/Edit_OurWork';
import AddSocialIcon from'./components/admin/AddSocialIcon';
import EditIcon from'./components/admin/EditIcon';
import Image from'./components/admin/Images';
import Events_list from'./components/admin/EventsList';
import New_Event from'./components/admin/New_Event';
import EditEvent from'./components/admin/EditEvent';
import VolunteerList from'./components/admin/VolunteerList';
import Booking_details from'./components/admin/Booking_details';
import Add_volunnteer from'./components/admin/Add_volunnteer';
import Update_volunnteer from'./components/admin/Update_volunnteer';
import Vender_query from'./components/admin/Vender_query';
import Vender_query_details from'./components/admin/Voluntee_details';
import Form_query from'./components/admin/Form_query';
import GalleryAdd from'./components/admin/Gallery';
import CotactUsThnk from './components/public/ContactUs_Thankyou';
const Routes = [
	{
		path: '/',
		element: <Home />
	},
	{
		path: '/about-us',
		element: <Aboutus />
	},
	{
		path: '/our-work',
		element: <Ourwork />
	},
	{
		path: '/events',
		element: <Events />
	},
	{
		path: '/events/:id',
		element: <Eventsdetails />
	},
	{
		path: '/event/booking/:id',
		element: <Event_booking />
	},
	{
		path: '/blog',
		element: <Blog />
	},
	{
		path: '/blog/:id',
		element: <Blog_Details />
	},
	{
		path: '/contact-us',
		element: <Contactus />
	},
	{
		path: '/contact-us/thankyou',
		element: <CotactUsThnk />
	},
	{
		path: '/volunteer',
		element: <Volunteer />
	},
	{
		path: '/donate',
		element: <DonationPage />
	},
	{
		path: '/donate/details/:id',
		element: <Donationuser />
	},
	{
		path: '/login',
		element: <Login />
	},
	{
		path: '/donation/thanks',
		element: <Thanks />
	},
	{
		path: '/myaccount',
		element: <Myaccount />
	},
	{
		path: '/gallery',
		element: <Gallery/>
	},
	{
		path: '/membership',
		element: <Membership/>
	},
	{
		path: '/membership/:id',
		element: <MembershipSuccess/>
	},
	{
		path: '*',
		element: <Error404 />
	},
	{
		path: '/admin',
		children: [
			{
				path: '',
				element: <Login />
			},
			{
				path: 'dashboard',
				element: <Dashboard />
			},
			{
				path: '/admin/banner',
				element: <Banner />
			},
			{
				path: '/admin/form',
				element: <Form />
			},
			{
				path: '/admin/banner/edit/:sno',
				element: <EditBanner />
			},
			{
				path: '/admin/Info',
				element: <Info />
			},
			{
				path: '/admin/socialIcon',
				element: <SocialIcon />
			},
			{
				path: '/admin/subscriber',
				element: <Subscriber />
			},
			{
				path: '/admin/sendmail',
				element: <Sendmail />
			},
			{
				path: '/admin/mainmenu',
				element: <Mainmenu />
			},
			{
				path: '/admin/submenu',
				element: <Submenu />
			},
			{
				path: '/admin/mainmenu/:id',
				element: <EdiMainmenu />
			},
			{
				path: '/admin/submenu/:id',
				element: <Edisubmenu />
			},
			{
				path: '/admin/blog',
				element: <BlogList />
			},
			{
				path: '/admin/blog/add',
				element: <Addblog />
			},
			{
				path: '/admin/blog/edit/:id',
				element: <EditBlog />
			},
			{
				path: '/admin/newpage',
				element: <NewPage />
			},
			{
				path: '/admin/pagelist',
				element: <Pagelist />
			},
			{
				path: 'pagelist/page-edit/:sn',
				element: <EditPage />
			},
			{
				path: '/admin/testimonial',
				element: <Testimonial />
			},
			{
				path: '/admin/addtestimonial',
				element: <AddTestimonial />
			},
			{
				path: '/admin/testimonial/edit/:id',
				element: <EditTestimonial />
			},
			{
				path: '/admin/donation-list',
				element: <DonatinList />
			},
			{
				path: '/admin/donation-grid',
				element: <DonationGrid />
			},
			{
				path: '/admin/what-we-do',
				element: <Whatwedo_List />
			},
			{
				path: '/admin/what-we-do/add',
				element: <AddWhatwedo />
			},
			{
				path: '/admin/what-we-do/edit/:id',
				element: <Edit_What_we_do />
			},
			{
				path: '/admin/counters',
				element: <CountDwon />
			},
			{
				path: '/admin/our-work',
				element: <OurWorkList />
			},
			{
				path: '/admin/our-work/add',
				element: <AddOurWorkList />
			},
			{
				path: '/admin/our-work/edit/:id',
				element: <EditOurWork />
			},
			{
				path: '/admin/socialIcon/add',
				element: <AddSocialIcon />
			},
			{
				path: '/admin/socialIcon/edit/:id',
				element: <EditIcon />
			},
			{
				path: '/admin/library',
				element: <Image/>
			},
			{
				path: '/admin/event',
				element: <Events_list/>
			},
			{
				path: '/admin/event/add',
				element: <New_Event/>
			},
			{
				path: '/admin/event/edit/:id',
				element: <EditEvent/>
			},
			{
				path: '/admin/event/booking/:id',
				element: <Booking_details/>
			},
			{
				path: '/admin/volunteer',
				element: <VolunteerList/>
			},
			{
				path: '/admin/volunteer/add',
				element: <Add_volunnteer/>
			},
			{
				path: '/admin/volunteer/:id',
				element: <Update_volunnteer/>
			},
			{
				path: '/admin/volunteer-query',
				element: <Vender_query/>
			},
			{
				path: '/admin/volunteer-query/:id',
				element: <Vender_query_details/>
			},
			{
				path: '/admin/form/:id',
				element: <Form_query/>
			},
			{
				path: '/admin/gallery',
				element: <GalleryAdd/>
			},
			{
				path: '*',
				element: <Admin404 />
			},
		]
	}
];
export default Routes;