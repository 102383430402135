import Form from 'react-bootstrap/Form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Api from '../Auth/Auth';
const EditTestimonial = () => {
    const url = useParams();
    const urlredirect = useNavigate();
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const [contents, getdata] = useState([]);
    useEffect(() => {
     Api.get('/select?table=testimonial&sno='+url.id).then((responce)=>{
        if(responce.data.status=='success'){
            var sts='';
            if(responce.data.data[0].status==1){ sts += '<option value="0">Inactive</option><option value="1"selected>Active</option>';}else{ sts += '<option value="0">Inactive</option><option value="1">Active</option>'; }
            document.getElementById('name').value = responce.data.data[0].name;
            document.getElementById('status').innerHTML = sts;
            getdata(responce.data.data[0].content);
        }
        }).catch((error)=>{
            console.log(error);
        })
    },[])
    const updates = (e) => {
        e.preventDefault();
        if (e.currentTarget.name.value != '' && contents != '') {
            var img = e.currentTarget.profile.files;
            const formData = new FormData();
            formData.append('image', img[0]);
            formData.append('sno', url.id);
            formData.append('title', e.currentTarget.name.value);
            formData.append('content', contents);
            formData.append('status', e.currentTarget.status.value);
            fetch(process.env.REACT_APP_API_URL + '/testimonial_update', {
                method: 'POST',
                body: formData
            }).then(response => response.json())
                .then(data => {
                    if (data['status'] == 'success') {
                        toast_alert('Succussfully up to date.', 0);
                        urlredirect('/admin/testimonial');
                    } else {
                        toast_alert(data['status'], 1);
                    }
                }).catch((error) => {
                    toast_alert('Somthing a worng...!!', 1);
                });
        } else {
            if (e.currentTarget.name.value == '') { toast_alert('Please select page title...', 1); return false; }
            if (contents == '') { toast_alert('Please enter section...', 1); return false; }
        }
    }
    return (<>
        <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className='d-flex justify-content-between'>
                            <div className=''>
                                <h6 className="card-title">Testimonial</h6>
                                <p className="text-muted mb-3">Add New</p>
                            </div>
                            <Link to="/admin/testimonial"><button className='border border-success bg-success text-white'>Testimonial List</button></Link>
                        </div>
                        <div className="table-responsive pt-3">
                            <Form onSubmit={updates}>
                                <div className="mb-3">
                                    <label htmlFor="profile" className="form-label">Pic</label>
                                    <input className="form-control" type="file" id="profile" />
                                </div>
                                <Form.Group className="mb-3">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control id="name" type="text" placeholder="Title" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Content</Form.Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={contents}
                                        onChange={(event, editor) => {
                                            getdata(editor.getData());
                                        }}
                                    />
                                    <Form.Group className="mb-3 mt-3">
                                        <Form.Label htmlFor="status">Status</Form.Label>
                                        <Form.Select id="status">
                                            <option value={0}>Inactive</option>
                                            <option value={1}>Active</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="mt-3" controlId="description">
                                        <input type='submit' className="btn btn-outline-primary" value="Update" />
                                    </Form.Group>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default EditTestimonial;