import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import { Link, useParams } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Api from '../Auth/Auth';
const EditEvent = () => {
    const urlredirect = useNavigate();
    const [contents,setContacts] = useState("");
    const [event, setEvents] = useState({ sno: '', title: '', start_time: '', end_time: '', location: '', map_url: '', charges: '', sheet: '', image: '', content: '', organizer: '', status: '' });
    const getValue = (e) => setEvents({ ...event, [e.currentTarget.id]: e.currentTarget.value });
    const url = useParams();
    useState(() => {
        Api.get('/select?table=event&sno=' + url.id).then((res) => {
            if (res.status === 200 && res.data.status === 'success') {
                setEvents(res.data.data[0]);
                setContacts(res.data.data[0].content);
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);
    const editEvents = (e) => {
        e.preventDefault();
        var img = e.currentTarget.image.files;
        const formData = new FormData();
        formData.append('image', img[0]);
        formData.append('sno', event.sno);
        formData.append('title', event.title);
        formData.append('event_location', event.location);
        formData.append('map_url', event.map_url);
        formData.append('start_time', event.start_time);
        formData.append('end_time', event.end_time);
        formData.append('total_sheet', event.sheet);
        formData.append('event_charege', event.charges);
        formData.append('content', event.contents);
        formData.append('organizer', event.organizer);
        formData.append('status', event.status);
        fetch(process.env.REACT_APP_API_URL + '/editevent', {
            method: 'POST',
            body: formData
        }).then(response => response.json()).then(data => {
            if (data['status'] === 'success') {
                toast(data.status);
                urlredirect('/admin/event');
            } else {
                toast(data.status);
            }
        }).catch((error) => {
            toast("something went wrong");
        })
    }

    return (<>
        <Card>
            <Card.Header><BiEdit /> Event</Card.Header>
            <Card.Body>
                <div className='d-flex justify-content-between'>
                    <div className=''>
                        <p className="text-muted mb-3"> Edi Event</p>
                    </div>
                    <div>
                        <Link to="/admin/event"><button className='custom-btn'>Event List</button></Link>
                    </div>
                </div>
                <Form onSubmit={editEvents}>
                    <Form.Group controlId="faviconicon" className="mb-3">
                        <Form.Label>Image</Form.Label>
                        <Form.Control type="file" id="image" accept="image/*" />
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} >
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Title" id="title" value={event?.title} autocomplete="off" onInput={getValue} required />
                        </Form.Group>

                        <Form.Group as={Col} >
                            <Form.Label>Location</Form.Label>
                            <Form.Control type="text" placeholder="Location" id="location" value={event?.location} autocomplete="off" onInput={getValue} required />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Form</Form.Label>
                            <Form.Control type="datetime-local" id="start_time" onInput={getValue} required />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>To </Form.Label>
                            <Form.Control type="datetime-local" id="end_time" onInput={getValue} required />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} >
                            <Form.Label>Total Sheet</Form.Label>
                            <Form.Control type="number" placeholder="Sheet" id="sheet" value={event?.sheet} onInput={getValue} autocomplete="off" required />
                        </Form.Group>

                        <Form.Group as={Col} >
                            <Form.Label>Charges</Form.Label>
                            <Form.Control type="number" placeholder="charges" value={event?.charges} id="charges" onInput={getValue} autocomplete="off" required />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} >
                            <Form.Label>Event Organizer</Form.Label>
                            <Form.Control type="text" placeholder="Event Organizer" id="organizer" value={event?.organizer} onInput={getValue} autocomplete="off" required />
                        </Form.Group>
                        <Form.Group as={Col} >
                            <Form.Label htmlFor="status">Status</Form.Label>
                            <Form.Select id="status" onChange={getValue} required>
                                <option value="0" selected={event?.status === 0 ? true : false}>Inactive</option>
                                <option value="1" selected={event?.status === 1 ? true : false}>Active</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3">
                        <Form.Label>Event about</Form.Label>
                        <CKEditor id="cont"
                            editor={ClassicEditor}
                            data={contents}
                            onChange={(event, editor) => {
                                setContacts( editor.getData());
                            }}
                        />
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} >
                            <Form.Label>Map Url</Form.Label>
                            <Form.Control type="url" placeholder="Map url" id="map_url" value={event?.map_url} onInput={getValue} autocomplete="off" required />
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3">
                        <Form.Check type="checkbox" label="Remember me" />
                    </Form.Group>
                    <input type="Submit" className='custom-btn' />
                </Form>
            </Card.Body>
        </Card>
    </>)
}
export default EditEvent;