import Card from 'react-bootstrap/Card';
import React, { useState } from 'react';
import { Button, Space, Tooltip } from 'antd';
import { Modal, Upload } from 'antd';
import Api from '../Auth/Auth';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { FiUpload } from 'react-icons/fi';
import { BiCopy } from 'react-icons/bi';
const Gallery = () => {
    const data = [];
    const [tol, setTotal] = useState();
    const [images, setImages] = useState();
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const GetImages = () => {
        Api.get('/gallery').then((response) => {
            if (response.status === 200) {
                if (response.data.status === 'success') {
                    setImages(response.data.data);
                    if(response.data.data.length < 10){ setTotal('0'+response.data.data.length); }else{ setTotal(response.data.data.length); }
                } else {
                    console.log('Error: ' + response.data.status);
                }
            } else {
                console.log("Error: " + response.status);
            }
        })
    }
    useEffect(() => {
        GetImages();
    }, [])
    images && images.map((image, i) => {
        data.push({
            uid: images[i].sno,
            name: 'gallery',
            status: '1',
            url: process.env.REACT_APP_IMG_BASE_URL + "/gallery/" + images[i].image
        })
    })
    const uploadImage = (e) => {
        const formData = new FormData();
        formData.append('image', e.target.files[0]);
        fetch(process.env.REACT_APP_API_URL + '/gallery', {
            method: 'POST',
            body: formData
        }).then(response => response.json())
            .then(data => {
                if (data['status'] == 'success') {
                    toast_alert('Succussfully uplaod.', 0);
                    GetImages();
                } else {
                    toast_alert(data['status'], 1);
                }
            }).catch((error) => {
                console.log(error);
            });
    }
    const customColors = ['#108ee9'];
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [url, setUrl] = useState('');
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setUrl(file.url);
    };
    const handleChange = (e) => {
        fetch(process.env.REACT_APP_API_URL + '/del', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({
                table: 'gallery',
                sno: e.file.uid
            })
        }).then(response => response.json())
            .then(data => {
                if (data['status'] == 'success') {
                    toast_alert('Succussfully deleted.', 0);
                    GetImages();
                } else {
                    toast_alert(data['status'], 1);
                }
            }).catch((error) => {
                toast_alert('something went wrong', 1);
            })
    }
    const CopyUrl = (e) =>{
        navigator.clipboard.writeText(e.currentTarget.value);
    }
    return (<>
        <Card>
            <Card.Header>
                <div className='d-flex justify-content-between'>
                    <div className=''>
                        <p className="text-muted mb-3">Image Gallery: <code>{tol}</code></p>
                    </div>
                    <div>
                        <label htmlFor="uplaod-img" className='custom-btn' style={{cursor: "pointer"}}><FiUpload/> Upload</label>
                        <input type="file" id="uplaod-img"accept="image/*" onChange={uploadImage} hidden />
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <Upload
                    listType="picture-card"
                    fileList={data}
                    onPreview={handlePreview}
                    onChange={handleChange}
                >
                </Upload>
                <Modal open={previewOpen} title={ <Space wrap>
                {customColors.map((color) => (
                    <Tooltip title="Copy image url" color={color} key={color}>
                        <Button value={url} onClick={CopyUrl}> <BiCopy/> </Button>
                    </Tooltip>
                ))}
            </Space>} footer={null} onCancel={handleCancel}> <img style={{ width: '100%', }} src={previewImage} /> </Modal>
            </Card.Body>
        </Card>
    </>)
}
export default Gallery;