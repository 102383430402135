import { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import {decode} from 'html-entities';
import Api from './../Auth/Auth';
const Error404 = () => {
    const [page, setPage] = useState([]);
    useEffect(() => {
        let path = document.location.pathname.split("/");
        Api.get('/getpage?title=' + path[path.length - 1]).then((response) => {
            if (response.status === 200) {
                if (response.data.data.length == 1) {
                    setPage(response.data.data[0]);
                } else {
                    console.log('page not Found: 404')
                }
            } else {
                console.log("Api error...!!");
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);
    return (<>
        {page.length == 0 ?
            <div className="d-flex align-items-center justify-content-center"style={{ height: "90vh", background: "#EFEFEF" }}>
                <div className="text-center">
                    <h1 className="display-1 fw-bold">404</h1>
                    <p className="fs-3"> <span className="text-danger">Opps!</span> Page not found.</p>
                    <p className="lead">
                        The page you’re looking for doesn’t exist.
                    </p>
                    <a href="/" className="btn" style={{ background: "#96BE0F", color: "#FFF" }}>Go Home</a>
                </div>
            </div>
            :
            <section>
                <style>
                    {page.title}
                </style>
                <div className="breadcrumb-area" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMG_BASE_URL + "/main/files/static/banner.jpg)" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner">
                                    <h2 className="page-title">{page.title}</h2>
                                    <ul className="page-list">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="">{page.title}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dy-contener">
                <div className="Container" dangerouslySetInnerHTML={{__html: page.content}}></div>
                </div>
            </section>
        }
    </>)
}
export default Error404;