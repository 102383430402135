import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { MDBDataTable } from 'mdbreact';
import 'mdbreact/dist/css/mdb.css';
import Moment from 'react-moment';
import 'moment-timezone';
import { Button, Dropdown, Space } from 'antd';
import { Link } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineTool } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Api from '../Auth/Auth';
const BlogList = () => {
    const [blogs, getBlog] = useState([]);
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const getPage = () => {
        Api.get("/bloglist").then(function (response) {
            if (response.data.status == 'success') {
              getBlog(response.data.data);
            }
        }).catch(function (err) {
            console.log(err);
        })
    }
    const Deleteblogs = (e) => {
        fetch(process.env.REACT_APP_API_URL + '/del', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({
                table: 'blog',
                sno: e.target.id
            })
        }).then(response => response.json())
            .then(data => {
                if (data['status'] == 'success') {
                    toast_alert('Menu Succussfully deleted.', 0);
                    getPage();
                } else {
                    toast_alert(data['status'], 1);
                }
            }).catch((error) => {
                toast_alert('something went wrong', 1);
            })
    }
    const data = {
        columns: [
            {
                label: '#',
                field: 'sno',
                sort: 'asc',
                width: 150
            },
            {
              label: 'Image',
              field: 'img',
              sort: 'asc',
              width: 150
          },
            {
                label: 'Title',
                field: 'title',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Status',
                field: 'status',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Last Modified',
                field: 'last',
                sort: 'asc',
                width: 200
            }
            ,
            {
                label: 'Action',
                field: 'action',
                sort: 'asc',
                width: 200
            }
        ],
        rows: []
    };
    useEffect(() => {
        getPage();
    }, []);
    blogs && blogs.map((inf, i) => {
        const items = [
            {
                key: '1',
                label: (
                    <Link rel="noopener noreferrer" to={"edit/" + blogs[i].sno}><BiEdit /> Edit</Link>
                ),
            },
            {
                key: '2',
                label: (
                    <Link rel="noopener noreferrer" id={blogs[i].sno} onClick={Deleteblogs}><AiFillDelete /> Delete</Link>
                ),
            },
        ];
        data.rows.push({
            sno: i + 1,
            img: <img src={process.env.REACT_APP_IMG_BASE_URL+'/blogs/'+blogs[i].image} alt=""/>,
            title: blogs[i].title,
            status: blogs[i].status==1? 'Active' : 'Inactive',
            last: <Moment format="DD/MMMM/YYYY">{blogs[i].date}</Moment>,
            action: <Space direction="vertical">
                <Space wrap>
                    <Dropdown
                        menu={{
                            items,
                        }}
                        placement="bottom"
                    >
                        <Button><AiOutlineTool /> Action</Button>
                    </Dropdown>
                </Space>
            </Space>
        })
    })

    return (
        <>
            <Card>
                <Card.Header>Blog List</Card.Header>
                <Card.Body>
                  <div className='d-flex justify-content-between'>
                        <div className=''>
                            <p className="text-muted mb-3">Total Blog :<code>{blogs.length<10? '0'+blogs.length:blogs.length}</code></p>
                        </div>
                        <div>
                        <Link to="/admin/blog/add"><button className='custom-btn'> Add Blog</button></Link>
                        </div>
                    </div>  

                    <MDBDataTable
                        striped
                        bordered
                        small
                        data={data}
                    />
                </Card.Body>
            </Card>
        </>
    );
}

export default BlogList;