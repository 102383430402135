import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { BsCurrencyRupee } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import Api from './../Auth/Auth';
const DonationPage = () => {
    const urlredirect = useNavigate();
    const toast_alert = (sms, status) => {
        if (status == 0) {
          toast.success(sms, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error(sms, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    const SelctAmt = (a) =>{
        document.getElementById("amount").value = a.currentTarget.value;
    }
    const DonateNow = () => {
        var amt = document.getElementById("amount").value;
        if(amt<1){
            toast_alert("Please select donation amount..!!", 1);
        }else{
            urlredirect('/donate/details/' + amt);
        }
    }
    const [banner,setBanner] = useState();
    useEffect(()=>{
        Api.get('/donations').then((response)=>{
            if(response.status===200 && response.data.status==='success'){
                setBanner(response.data.data[0]);
            }else{
                console.log('Api Error'+response.status);
            }
        }).catch((error)=>{
            console.log(error);
        })
    },[])
    return (<>,
    <ToastContainer />
        <div className="breadcrumb-area" style={{backgroundImage: "url("+process.env.REACT_APP_IMG_BASE_URL+"/img/"+banner?.image+")"}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner">
                            <div className="icon">
                                <img src="/main/files/static/green-hart.png" />
                            </div>
                            <h2 className="page-title">Donation</h2>
                            <ul className="page-list">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Donation </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='hght'>
                    </div>
                </div>
            </div>
        </div>
        <div className='form-sect'>
            <div className='w-100'>
                <div className="container">
                    <div className="row dnt-row">
                        <div className="col-7 section-title cls">
                            <div className='title'>YOU HAVE THE POWER TO CHANGE!</div>
                            <div className='dnt-text'>
                                <p>Going to school is not just about getting an education. School memories make up a big part of our lives, and are the foundation of a happy and safe childhood.</p>
                                <p>Thousands of children in the country who are unable to go to school are deprived of education, growth opportunities and a happy childhood. Many of them are working in harsh conditions to earn a living, while others are falling into the darkness of crime and substance abuse.</p>
                                <p>A country can only make real progress when its children are aware, educated, and empowered. By supporting Smile Foundation's Shiksha Na Ruke initiative, you take a step towards enabling India's young generation to become independent and responsible.</p>
                            </div>
                        </div>
                        <div className="col-5 cls fromcls">
                            <div className="d-grid gap-2">
                                <div className='bg-color'>Educate A Child Today!</div>
                                <div className='dont'>
                                    <div className="cnt-btn">
                                        <button className='' value="1000" onClick={SelctAmt}><BsCurrencyRupee />1000</button>
                                        <button className='' value="3000" onClick={SelctAmt}><BsCurrencyRupee />3000</button>
                                        <button className='' value="6000" onClick={SelctAmt}><BsCurrencyRupee />6000</button>
                                        <button className='' value="12000" onClick={SelctAmt}><BsCurrencyRupee />12000</button>
                                    </div>
                                    <div className='dont-frm-txt'>YOUR DONATION WILL HELP FOR THE EDUCATION OF 1 CHILD FOR 6 MONTHS</div>
                                    <img src="/main/files/static/childs.webp" />
                                    <div id="inpu-rup"><BsCurrencyRupee /></div>
                                    <Form.Group className="mb-3" controlId="dont-amt">
                                        <Form.Control type="number" id="amount"/>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <button type="submit" className='donste-btn' onClick={DonateNow}>DONATE NOW</button>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </>)
}
export default DonationPage;