
import { Link } from 'react-router-dom';
import api from './../Auth/Auth';
import { useEffect, useState } from 'react';
const Volunteer = () => {
    const [gallery,setgallery] = useState({banner: {},gallery: []});
    useEffect(()=>{
        api.get('/getgallery').then((res)=>{
            if(res.status===200 && res.data.status==='success'){
                setgallery(res.data.data);
            }
            console.log(res);
        }).catch((error)=>{
            console.log(error);
        })
    },[])
    return (<>
        <div className="breadcrumb-area" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMG_BASE_URL + "/img/" + gallery?.banner[0]?.image + ")" }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner">
                            <div className="icon">
                                <img src="/main/files/static/green-hart.png" />
                            </div>
                            <h2 className="page-title">Gallery</h2>
                            <ul className="page-list">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Gallery</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section className="volunteer-area bg-image m-top-02 padding-bottom-90 padding-top-120">
            <div className="container">
                <div className="row">
                    {
                        gallery.gallery && gallery.gallery.map((img)=>{
                            return(<div className="col-lg-3 col-sm-6">
                            <div className="team-single-item">
                                <div className="thumb">
                                    <img src={`${process.env.REACT_APP_IMG_BASE_URL}/gallery/${img.image}`}/>
                                </div>
                            </div>
                        </div>  )
                        })
                    }          
                </div>
            </div>
        </section>
    </>)
}
export default Volunteer;