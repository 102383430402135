import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import Api from '../Auth/Auth';
function Form_query() {
    const url = useParams();
    const [details,setDetails] = useState();
    useEffect(() => {
        Api.get("/select?table=form&sno="+url.id).then((response)=>{
            setDetails(response.data.data[0]);
          }).catch((error)=>{
            console.log(error);
          })
      }, []);
    return (
        <Card>
            <Card.Header>Volunteer Query</Card.Header>
            <Card.Body>
                <Card.Title>
                    <div className='d-flex justify-content-between'>
                        <div className=''><Moment format="DD/MMMM/YYYY hh:mm A">{details?.last_modify}</Moment></div>
                        <div>
                            <Link to="/admin/form"><button className='custom-btn'> Query List</button></Link>
                        </div>
                    </div>
                </Card.Title>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Name:</Form.Label>
                        <Form.Control type="text"value={details?.name} readOnly />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Phone:</Form.Label>
                        <Form.Control type="text"value={details?.phone} readOnly />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Message:</Form.Label>
                        <Form.Control as="textarea" rows={3} style={{resize: 'none'}} value={details?.message}  readOnly/>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

export default Form_query;