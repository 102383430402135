import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { MDBInput, MDBBtn, MDBCheckbox } from 'mdb-react-ui-kit';
import { BsCurrencyRupee } from "react-icons/bs";
import { Link, useParams } from 'react-router-dom';
import CryptoJS from "crypto-js";
import axios from 'axios';
import { useEffect, useState } from 'react';
import Api from './../Auth/Auth';
const DonateUser_dt = () => {
    const rediect = useNavigate();
    var dt = new Date();
    if (dt.getMonth() < 10) {
        var date = dt.getFullYear() + '-0' + dt.getMonth() + '-' + dt.getDate();
    } else {
        var date = dt.getFullYear() + '-' + dt.getMonth() + '-' + dt.getDate();
    }
    const url = useParams();
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const getApiData = (pin) => {
        axios.get('https://phplaravel-979432-4660383.cloudwaysapps.com/api/location/pincode/' + pin)
            .then(response => {
                if (response.data[0].Status == 'Success') {
                    var post = '<option value="">-- Select Post Office --</option>';
                    for (var i = 0; i < response.data[0].PostOffice.length; i++) { post += '<option>' + response.data[0].PostOffice[i].Name + '</option>'; }
                    document.getElementById("state").value = response.data[0].PostOffice[0].State;
                    document.getElementById("city").value = response.data[0].PostOffice[0].District;
                    document.getElementById("postoffice").innerHTML = post;
                } else {
                    toast_alert("Plase enter valid Pin Code...!!", 1);
                    document.getElementById("pin").fld[i].focus();
                }
            }).catch(err => {
                console.log(err);
            })
    };
    const donate = (tr_id) => {
        var userid = document.getElementById('phone').value;
        if (sessionStorage.getItem('user')) {
            userid = CryptoJS.AES.decrypt(sessionStorage.getItem('user'), process.env.REACT_APP_ECCRIPTION_KEY).toString(CryptoJS.enc.Utf8);
        }
        fetch(process.env.REACT_APP_API_URL + "/donate", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({
                userid: userid,
                transaction_id: tr_id,
                name: document.getElementById('name').value,
                dob: document.getElementById('dob').value,
                phone: document.getElementById('phone').value,
                email: document.getElementById('email').value,
                country: document.getElementById('cuntry').value,
                state: document.getElementById('state').value,
                city: document.getElementById('city').value,
                postoffice: document.getElementById('postoffice').value,
                address: document.getElementById('address').value,
                pincode: document.getElementById('pin').value,
                pan: document.getElementById('pan').value,
                amount: document.getElementById('amount').value
            })
        }).then(response => response.json()).then(data => {
            if (data.status == 'success') {
                localStorage.setItem('mndonation', 'success');
                rediect('/donation/thanks/');
                toast_alert('Donation Successs...', 0);
            } else {
                toast_alert(data.status, 1);
            }
        }).catch(error => {
            toast_alert("Somthing a worng...!!", 1);
        })
    }
    const SubmitForm = (event) => {
        event.preventDefault();
        var fld = document.querySelectorAll(".inputfld");
        var pan = document.getElementById("pan");
        var phn = document.getElementById("phone");
        var eml = document.getElementById("email");
        for (var i = 0; i < fld.length; i++) {
            if (fld[i].value == '') {
                fld[i].focus();
                toast_alert("Plase enter your " + fld[i].name + " ...!!", 1);
                return;
            }
        }
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(eml.value)) {
            toast_alert("Please enter valid Email..!!", 1);
            return;
        }
        if (!/^\(?([6-9]{1})\)?([0-9]{9})$/.test(phn.value)) {
            toast_alert("Please enter valid phone no..!!", 1);
            return;
        }
        if (pan.value == '') {
            toast_alert("Please enter pan number..!!", 1);
            return;
        } else {
            if (pan.value.length != 10 && !/[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(pan.value)) {
                toast_alert("Please enter valid pan number..!!", 1);
                return;
            }
        }
        donate((Math.random() * 1000000).toFixed());
    }
    const Pincode = (e) => {
        if (e.target.value.length > 6) {
            e.target.value = e.target.value.slice(0, 6);
        } else if (e.target.value.length == 6) {
            getApiData(e.target.value);
        }
    }
    const [banner,setBanner] = useState();
    useEffect(() => {
        Api.get('/donations').then((response)=>{
            if(response.status===200 && response.data.status==='success'){
                setBanner(response.data.data[0]);
            }else{
                console.log('Api Error'+response.status);
            }
        }).catch((error)=>{
            console.log(error);
        })
        if (sessionStorage.getItem('user')) {
            Api.get('/userprofile?userid=' + CryptoJS.AES.decrypt(sessionStorage.getItem('user'), process.env.REACT_APP_ECCRIPTION_KEY).toString(CryptoJS.enc.Utf8)).then((response) => {
                if (response.data.status == 'success') {
                    if (response.data.profile.length == 1) {
                        document.getElementById('name').value = response.data.profile[0].name;
                        document.getElementById('dob').value = response.data.profile[0].dob;
                        document.getElementById('email').value = response.data.profile[0].email;
                        document.getElementById('phone').value = response.data.profile[0].phone;
                        document.getElementById('pin').value = response.data.profile[0].pin;
                        document.getElementById('address').value = response.data.profile[0].address;
                        getApiData(response.data.profile[0].pin);
                        var fld = document.querySelectorAll(".inputfld");
                        for (var i = 0; i < fld.length; i++) {
                            fld[i].focus(); 
                        }
                    }
                }
            }).catch((error) => {
                console.error(error);
            })
        }
    }, [])
    return (<>,
        <ToastContainer />
        <div className="breadcrumb-area" style={{backgroundImage: "url("+process.env.REACT_APP_IMG_BASE_URL+"/img/"+banner?.image+")"}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner">
                            <div className="icon">
                                <img src="/main/files/static/green-hart.png"  />
                            </div>
                            <h2 className="page-title">Donation</h2>
                            <ul className="page-list">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Donation </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='hght'>
                    </div>
                </div>
            </div>
        </div>
        <div className='form-sect'>
            <div className='w-100'>
                <div className="container">
                    <div className="row dnt-row">
                        <div className="col-4 section-title cls d-col">
                            <div className='formimg'>
                                <img src="/main/files/static/donatepage.jpg" />
                                <div className='imctext'>Your donation will support skill training and healthcare for marginalized women</div>
                            </div>
                        </div>
                        <div className="col-8 d-col">
                            <div className='donte-amt'><BsCurrencyRupee />{url.id}</div>
                            <Form onSubmit={SubmitForm}>
                                <Row className="g-2">
                                    <Col md><MDBInput label='Name*' name="Name" id='name' autoComplete='off' type='text' className='inputfld' /></Col>
                                    <Col md>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>DOB</InputGroup.Text>
                                            <Form.Control type="date" name="Date of Birth" id="dob" max={date} className='inputfld' />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="g-2">
                                    <Col md>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="eml">@</InputGroup.Text>
                                            <MDBInput label='Email*' name="Email" id='email' autoComplete='off' type='email' className='inputfld' />
                                        </InputGroup>
                                    </Col>
                                    <Col md>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="phn">+91</InputGroup.Text>
                                            <MDBInput label='Phone Number*' name="Phone no" id='phone' autoComplete='off' type='number' className='inputfld' />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="g-2">
                                    <Col md>
                                        <Form.Select className='inputfld' id="cuntry">
                                            <option>India</option>
                                        </Form.Select>
                                    </Col>
                                    <Col md>
                                        <InputGroup className="mb-3">
                                            <Col md><MDBInput label='Pin Code*' id='pin' name="Pin Code" type='number' autoComplete='off' onInput={Pincode} className='inputfld' maxlength="4" /></Col>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="g-2 mb-3">
                                    <Col md><Form.Control type="text" id='state' name="State" autoComplete='off' placeholder="State*" className='inputfld' disabled /></Col>
                                    <Col md><Form.Control type="text" id='city' name="City" autoComplete='off' placeholder="City*" className='inputfld' disabled /></Col>
                                </Row>
                                <Row className="g-2">
                                    <Col md>
                                        <Form.Select id="postoffice" className='inputfld' name="Post Office" >
                                            <option value=''>-- Select Post Office --</option>
                                        </Form.Select>
                                    </Col>
                                    <Col md>
                                        <InputGroup className="mb-3">
                                            <MDBInput label='Address*' id='address' name="Address" autoComplete='off' type='text' className='inputfld' />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="g-2">
                                    <Col md>
                                        <InputGroup className="mb-3">
                                            <MDBInput label='Pan Number' autoComplete='off' id='pan' type='text' />
                                        </InputGroup>
                                    </Col>
                                    <Col md>
                                        <InputGroup className="mb-3">
                                            <MDBInput label='Amount' id='amount' type='number' className='inputfld' value={url.id} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Row>
                                        <Col md className='form80'>YOUR CONTRIBUTIONS ARE ELIGIBLE FOR UPTO 50% TAX BENEFIT UNDER SECTION 80G" </Col>
                                    </Row>
                                    <Col md>
                                        <MDBCheckbox name='flexCheck' value='' id='chechbox-dnt' label='You agree that mnb Foundation can reach out to you through Whatsapp/email/SMS/Phone to provide information of your donation, campaigns, 80G receipt etc.' />
                                    </Col>
                                </Row>
                                <Col md className='mt-3'>
                                    <Link to="/donate"><button className='dnt-back'>Back</button></Link>
                                    <MDBBtn className='me-1 donate-continue'> Continue </MDBBtn>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>)
}
export default DonateUser_dt;

