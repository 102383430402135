import { Col, Row } from 'antd';
import Card from 'react-bootstrap/Card';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LuIndianRupee } from 'react-icons/lu';
import { AiOutlineUser } from 'react-icons/ai';
import { MDBDataTable } from 'mdbreact';
import 'mdbreact/dist/css/mdb.css';
import Api from '../Auth/Auth';
import Moment from 'react-moment';
import 'moment-timezone';
const Dashboard = () => {
    const [tody, setTody] = useState(0);
    const [donater, setDonator] = useState();
    const [mails, setForm] = useState();
    const [donation, setDonation] = useState();
    const [donetorslist, setDonetorslist] = useState();
    var amount=0,dt=new Date(),current_month=dt.getMonth()+1;
    if(current_month<10){ current_month='0'+current_month; }
    useEffect(() => {
        Api.get('/dashboard').then((response) => {
            if (response.status == 200) {
                if (response.data.status === 'success') {
                    setDonator(response.data.card.length)
                    setForm(response.data.form);
                    setDonation(response.data.donation);
                    setDonetorslist(response.data.card);
                    var tody_amounts=0;
                    for(var i=0; i<response.data.donation.length; i++){
                        if(response.data.donation[i].donation_date.slice(0, 10)===dt.getFullYear()+'-'+current_month+'-'+dt.getDate()){
                            tody_amounts+=response.data.donation[i].amount;
                        }
                    }
                    setTody(tody_amounts);
                } else {
                    console.log('error: ' + response.data.status);
                }
            } else {
                console.log('error: ' + response.status);
            }
        }).catch((error) => {
            console.error(error);
        })
    }, []);
    const mail = {
        columns: [
            {
                label: '#',
                field: 'sno',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Name',
                field: 'name',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Phone',
                field: 'phone',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Message',
                field: 'sms',
                sort: 'asc',
                width: 200
            }
            ,
            {
                label: 'Date',
                field: 'dt',
                sort: 'asc',
                width: 200
            }
        ],
        rows: [{}]
    };
    mails && mails.map((inf, i) => {
        mail.rows.push({
            sno: i + 1,
            name: mails[i].name,
            phone: mails[i].phone,
            sms: mails[i].message,
            dt: <Moment format="DD/MMMM/YYYY">{mails[i].date}</Moment>
        })
    })
    const donations = {
        columns: [
            {
                label: '#',
                field: 'sno',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Name',
                field: 'name',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Phone',
                field: 'phone',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Amount',
                field: 'amt',
                sort: 'asc',
                width: 200
            }
            ,
            {
                label: 'Date',
                field: 'dt',
                sort: 'asc',
                width: 200
            }
        ],
        rows: [{}]
    };
    donation && donation.map((inf, i) => {
        donations.rows.push({
            sno: i + 1,
            name: donation[i].name,
            phone: donation[i].phone,
            amt: <div className="Container" dangerouslySetInnerHTML={{ __html: '<span>&#8377;</span> ' + donation[i].amount }}></div>,
            dt: <Moment format="DD/MMMM/YYYY">{donation[i].date}</Moment>
        })
    })
    const donators = {
        columns: [
            {
                label: 'Phone',
                field: 'phone',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Name',
                field: 'name',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Amount',
                field: 'amt',
                sort: 'asc',
                width: 200
            }
        ],
        rows: [{}]
    };
    donetorslist && donetorslist.map((inf, i) => {
        amount += donetorslist[i].amount;
        donators.rows.push({
            phone: donetorslist[i].phone,
            name: donetorslist[i].name,
            amt: <div className="Container" dangerouslySetInnerHTML={{ __html: '<span>&#8377;</span> ' + donetorslist[i].amount }}></div>
        })
    })
    return (<>
        <section>
            <Row gutter={16}>
                <Col span={8} className='mb-3'>
                    <Link to="/admin/donation-list">
                        <Card bordered={false}>
                            <div className='d-flex justify-content-between align-items-center my-3 mx-3'>
                                <div>
                                    <span className="text-muted dst-hd">Today Donation</span>
                                </div>
                                <div>
                                    <code><span className='dt-amunt'><LuIndianRupee />{tody}</span></code>
                                </div>
                            </div>
                        </Card>
                    </Link>
                </Col>
                <Col span={8} className='mb-3'>
                    <Link to="/admin/donation-list">
                        <Card bordered={false}>
                            <div className='d-flex justify-content-between align-items-center my-3 mx-3'>
                                <div>
                                    <span className="text-muted  dst-hd">Total Donation</span>
                                </div>
                                <div>
                                    <code><span className='dt-amunt'><LuIndianRupee />{amount}</span></code>
                                </div>
                            </div>
                        </Card>
                    </Link>
                </Col>
                <Col span={8} className='mb-3'>
                    <Link to="/admin/donation-grid">
                        <Card bordered={false}>
                            <div className='d-flex justify-content-between align-items-center  my-3 mx-3'>
                                <div>
                                    <span className="text-muted dst-hd">Total Donator</span>
                                </div>
                                <div>
                                    <code><span className='dt-amunt'><AiOutlineUser />{donater}</span></code>
                                </div>
                            </div>
                        </Card>
                    </Link>
                </Col>
            </Row>
            <div className='row my-4'>
                <div className='col-lg-5 col-xl-5 grid-margin grid-margin-xl-0 stretch-card'>
                    <Card>
                        <Card.Header>Donator List</Card.Header>
                        <Card.Body>
                        <MDBDataTable
                                striped
                                bordered
                                small
                                data={donators}
                            />
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-lg-5 col-xl-7 grid-margin grid-margin-xl-0 stretch-card'>
                    <Card>
                        <Card.Header>Donation List</Card.Header>
                        <Card.Body>
                            <MDBDataTable
                                striped
                                bordered
                                small
                                data={donations}
                            />
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <Card className='mt-4'>
                <Card.Header>Mails</Card.Header>
                <Card.Body>
                    <MDBDataTable
                        striped
                        bordered
                        small
                        data={mail}
                    />
                </Card.Body>
            </Card>
        </section>
    </>)
}
export default Dashboard;