import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
function Login() {
    const Navigate = useNavigate();
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const login = (e) => {
        e.preventDefault();
        var username = e.target.userEmail.value;
        var password = e.target.userPasswords.value;
        if (username !== '' && password !== '') {
            fetch(process.env.REACT_APP_API_URL+'/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            }).then(response => response.json())
                .then(data => {
                    if (data['status'] == 'success') {
                        localStorage.setItem('mnb', data['token']);
                        Navigate('/admin/dashboard');
                    } else {
                        toast_alert(data['status'], 1);
                    }
                }).catch((error) => {
                    toast_alert('something went wrong', 1);
                });
        } else {
            if (username == '' && password == '') {
                toast_alert('Please enter username and password', 1);
            } else if (username == '') {
                toast_alert('Please enter username', 1);
            } else if (password == '') {
                toast_alert('Please enter password', 1);
            }

        }
    }
    return (
        <div className="main-wrapper">
            <ToastContainer />
            <div className="page-wrapper full-page">
                <div className="page-content d-flex align-items-center justify-content-center">
                    <div className="row w-100 mx-0 auth-page">
                        <div className="col-md-7 col-xl-6 mx-auto" style={{ maxWidth: "430px" }}>
                            <div className="card">
                                <div className="row">
                                    <div className="col-md-11 ps-md-0 mx-auto">
                                        <div className="auth-form-wrapper px-4 py-5">
                                            <Link to="#" className="noble-ui-logo d-block mb-2">Mnb<span>Foundation</span></Link>
                                            <h5 className="text-muted fw-normal mb-4">Welcome to mnboundation.</h5>
                                            <form className="forms-sample" onSubmit={login}>
                                                <div className="mb-3">
                                                    <label htmlFor="userEmail" className="form-label">Username</label>
                                                    <input type="text" className="form-control" id="userEmail" placeholder="Email" />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="userPasswords" className="form-label">Password</label>
                                                    <input type="password" className="form-control" id="userPasswords" placeholder="Password" />
                                                </div>
                                                <div className="form-check mb-3">
                                                    <input type="checkbox" className="form-check-input" id="authCheck" required />
                                                    <label className="form-check-label" for="authCheck">
                                                        Remember me
                                                    </label>
                                                </div>
                                                <div className="mb-3">
                                                    <input type="submit" className="btn btn-primary form-control" id="userPassword" autocomplete="current-password" value="Login" />
                                                </div>
                                                <Link to="" className="d-block mt-3 text-muted text-center">Forget Password?</Link>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;