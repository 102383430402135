import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import React from 'react';
import Form from 'react-bootstrap/Form';
import { Table } from 'antd';
import $ from 'jquery';
import { Link, useNavigate } from "react-router-dom";
import Api from './../Auth/Auth';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { useState, useEffect } from "react";
import { MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import CryptoJS from "crypto-js";
import Moment from 'react-moment';
import 'moment-timezone';
import { toast } from 'react-toastify';
import axios from 'axios';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody, MDBCardImage, } from 'mdb-react-ui-kit';
const Myaccount = () => {
    let [finddata, getdata] = useState({ profile: [{ sno: '1' }] });
    const urlredirect = useNavigate();
    function logend() {
        if (!sessionStorage.getItem('user')) {
            urlredirect('/');
        }
    }
    setInterval(logend, 1);
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    useEffect(() => {
        Api.get('/userprofile?userid=' + CryptoJS.AES.decrypt(sessionStorage.getItem('user'), process.env.REACT_APP_ECCRIPTION_KEY).toString(CryptoJS.enc.Utf8))
            .then(function (response) {
                getdata(response.data.data);
                if (response.data.data.profile.length === 1) {
                    var post = response.data.data.profile[0].post;
                    document.getElementById('name').value = response.data.data.profile[0].name;
                    document.getElementById('dob').value = response.data.data.profile[0].dob;
                    document.getElementById('email').value = response.data.data.profile[0].email;
                    document.getElementById('phone').value = response.data.data.profile[0].phone;
                    document.getElementById('pin').value = response.data.data.profile[0].pin;
                    document.getElementById('state').value = response.data.data.profile[0].state;
                    document.getElementById('dist').value = response.data.data.profile[0].dist;
                    document.getElementById('address').value = response.data.data.profile[0].address;
                    document.getElementById('logintm').value = response.data.data.profile[0].last_login.slice(11, 19);
                    if (response.data.profile[0].pin != 'false') {
                        axios.get('https://api.postalpincode.in/pincode/' +  response.data.data.profile[0].pin).then((response) => {
                            if (response.data[0].Status == 'Success') {
                                var posts = '<option value="">-- Select Post Office --</option>';
                                for (var i = 0; i < response.data[0].PostOffice.length; i++) {
                                    if (post == response.data[0].PostOffice[i].Name) {
                                        posts += '<option selected>' + response.data[0].PostOffice[i].Name + '</option>';
                                    } else {
                                        posts += '<option>' + response.data[0].PostOffice[i].Name + '</option>';
                                    }
                                }
                                document.getElementById('postoffice').innerHTML = posts;
                            }
                        }).catch((error) => {
                            console.error(error);
                        })
                    }
                }
            }).catch(function (err) {
                console.log(err);
            })
    }, [])
    const profileOption = (e) => {
        $('.option').css('color', '#616161');
        $(this).css('color', '#96BE0F');
        if (e.target.id == 'profile') {
            $('.contener').css('display', 'none');
            $('.profiles').css('display', 'block');
            $(this).css('color', '#96BE0F');
        } else if (e.target.id == 'details') {
            $('.contener').css('display', 'none');
            $('.donation_dt').css('display', 'block');
        } else if (e.target.id == 'event') {
            $('.contener').css('display', 'none');
            $('.event_dt').css('display', 'block');
        }
    }
    const logout = () => {
        sessionStorage.removeItem('user');
    }
    const columns1 = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Donation Date',
            dataIndex: 'donation_date',
            key: 'donation_date',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];
    const data1 = [];
    finddata.donation && finddata.donation.map((inf, i) => {
        data1.push({
            key: i + 1,
            name: finddata.donation[i].name,
            email: finddata.donation[i].email,
            donation_date: <Moment format="DD/MMMM/YYYY">{finddata.donation[i].donation_date}</Moment>,
            amount: <div className="Container" dangerouslySetInnerHTML={{ __html: '<span>&#8377;</span> ' + finddata.donation[i].amount }}></div>,
            description: <table>
                <tr><th>User Id</th><td>:</td><td>{finddata.donation[i].user_id}</td><th>Transaction Id</th><td>:</td><td>#{finddata.donation[i].transaction_id}</td></tr>
                <tr><th>Phone</th><td>:</td><td>{finddata.donation[i].phone}</td><th>Email</th><td>:</td><td>{finddata.donation[i].email}</td></tr>
                <tr><th>DOB</th><td>:</td><td><Moment format="DD/MMMM/YYYY">{finddata.donation[i].dob}</Moment></td><th>Pin Code</th><td>:</td><td>{finddata.donation[i].pin}</td></tr>
                <tr><th>Address</th><td>:</td><td colspan="4">{finddata.donation[i].address + ', ' + finddata.donation[i].post + ', ' + finddata.donation[i].city + ', ' + finddata.donation[i].state + ', ' + finddata.donation[i].country + ', ' + finddata.donation[i].pin}</td></tr>
                <tr><th>Amount</th><td>:</td><td colspan="4"><span>&#8377;</span> {finddata.donation[i].amount}</td></tr>
            </table>
        })
    })
    const columns2 = [
        {
            title: 'Order id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Event',
            dataIndex: 'event',
            key: 'event',
        },
        {
            title: 'Event Date/Time',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];
    const data2 = [];
    finddata.event && finddata.event.map((inf, i) => {
        data2.push({
            key: i + 1,
            id: "M-"+finddata.event[i].sno,
            event: finddata.event[i].title,
            date: <Moment format="DD/MMMM/YYYY hh:mm:A">{finddata.event[i].start_date}</Moment>,
            time: <Moment format="hh:mm A">{finddata.event[i].start_time}</Moment>,
            status: finddata.event[i].status==0?'Booked':'Cancel',
            description: <table>
                <tr><th>Event</th><td>:</td><td colspan="4">{finddata.event[i].title}</td></tr>
                <tr><th>Event Date</th><td>:</td>{<Moment format="DD/MMMM/YYYY">{finddata.event[i].start_date}</Moment>} - {<Moment format="DD/MMMM/YYYY">{finddata.event[i].end_date}</Moment>}<td></td><th>Event Time</th><td>:</td><td>{<Moment format="hh:mm A">{finddata.event[i].start_date}</Moment>} - {<Moment format="hh:mm A">{finddata.event[i].end_date}</Moment>}</td></tr>
                <tr><th>Location</th><td>:</td><td colspan="4">{finddata.event[i].location}</td></tr>

                <tr><th>Name</th><td>:</td>{finddata.event[i].name}<td></td><th>DOB</th><td>:</td><td>{<Moment format="DD/MMMM/YYYY">{finddata.event[i].DOB}</Moment>}</td></tr>
                <tr><th>Phone</th><td>:</td>{finddata.event[i].phone}<td></td><th>Email</th><td>:</td><td>{finddata.event[i].email}</td></tr>
                <tr><th>Event Fee</th><td>:</td>{finddata.event[i].amount===0?'Free':finddata.event[i].amount}<td></td><th>Booking Date</th><td>:</td><td>{<Moment format="DD/MMMM/YYYY hh:mm A">{finddata.event[i].booking_time}</Moment>}</td></tr>
            </table>
        })
    })
    const postoffice = (e) => {
        e.target.value = e.target.value.slice(0, 6);
        if (e.target.value.length == 6) {
            axios.get('https://api.postalpincode.in/pincode/' + e.target.value).then((response) => {
                console.log(response);
                if (response.data[0].Status == 'Success') {
                    var post = '<option value="">-- Select Post Office --</option>';
                    for (var i = 0; i < response.data[0].PostOffice.length; i++) { post += '<option>' + response.data[0].PostOffice[i].Name + '</option>'; }
                    document.getElementById("state").value = response.data[0].PostOffice[0].State;
                    document.getElementById("dist").value = response.data[0].PostOffice[0].District;
                    document.getElementById("postoffice").innerHTML = post;
                } else {
                    toast_alert("Plase enter valid Pin Code...!!", 1);
                    document.getElementById("pin").fld[i].focus();
                }
            }).catch((error) => {
                console.log(error);
            })
        }
    }
    const UpdateProfile = (e) => {
        e.preventDefault();
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.email.value)) {
            toast_alert("Please enter valid Email..!!", 1);
            return;
        }
        if (e.target.pin.value.length != 6) {
            toast_alert("Enter Valid Pin Code...!!", 1);
            return;
        }
        fetch(process.env.REACT_APP_API_URL + "/user_profile_update", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({
                phone: CryptoJS.AES.decrypt(sessionStorage.getItem('user'), process.env.REACT_APP_ECCRIPTION_KEY).toString(CryptoJS.enc.Utf8),
                name: e.target.name.value,
                email: e.target.email.value,
                dob: e.target.dob.value,
                cuntery: e.target.cuntery.value,
                state: e.target.state.value,
                dist: e.target.dist.value,
                post: e.target.postoffice.value,
                address: e.target.address.value,
                pin: e.target.pin.value
            })
        }).then(response => response.json()).then(data => {
            toast_alert('Profile Successfully update...', 0);
        }).catch(error => {
            toast_alert('Try Agine after sometime....', 1);
        })
    }
    return (<>
        <div className="breadcrumb-area" style={{ backgroundImage: "url(/main/img/images/contact.png)" }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner">
                            <h2 className="page-title">My Account</h2>
                            <ul className="page-list">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="">My Account</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section style={{ backgroundColor: '#EFEFEF' }}>
            <MDBContainer className="py-5">
                <MDBRow>
                    <MDBCol lg="3">
                        <MDBCard className="mb-4">
                            <MDBCardBody className="text-center">
                                <MDBCardImage
                                    src="/main/files/static/peo.webp"
                                    alt="Profile"
                                    className="rounded-circle"
                                    style={{ width: '150px' }}
                                    fluid />
                                <p className="text-muted mb-0">{finddata?.profile[0]?.name}</p>
                                <p className="text-muted mb-0"><Moment format="DD/MMMM/YYYY"></Moment></p>
                                <p className="text-muted mb-0">{finddata?.profile[0]?.phone}</p>
                                <p className="text-muted mb-0">{finddata?.profile[0]?.email}</p>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol lg="9">
                        <Card>
                            <Card.Header>
                                <Nav className="me-auto">
                                    <Nav.Link id="profile" className="option" onClick={profileOption}>Profile</Nav.Link>
                                    <Nav.Link id="details" className="option" onClick={profileOption}>Donation</Nav.Link>
                                    <Nav.Link id="event" className="option" onClick={profileOption}>Events</Nav.Link>
                                    <Nav.Link onClick={logout}>Logout</Nav.Link>
                                </Nav>
                            </Card.Header>
                            <Card.Body>
                                <div className='contener profiles'>
                                    <Form onSubmit={UpdateProfile}>
                                        <Row className="g-2">
                                            <Col md><MDBInput label='Name*' name="Name" id='name' autoComplete='off' type='text' className='inputfld' required /></Col>
                                            <Col md>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text>DOB</InputGroup.Text>
                                                    <Form.Control type="date" name="Date of Birth" id="dob" className='inputfld' required />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row className="g-2">
                                            <Col md>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="eml">@</InputGroup.Text>
                                                    <MDBInput label='Email*' name="Email" id='email' autoComplete='off' type='email' className='inputfld' required />
                                                </InputGroup>
                                            </Col>
                                            <Col md>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="phn">+91</InputGroup.Text>
                                                    <MDBInput label='Phone Number*' name="Phone no" id='phone' autoComplete='off' type='number' className='inputfld' required disabled />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row className="g-2 mb-3">
                                            <Col md><Form.Control type="text" id='cuntery' autoComplete='off' placeholder="Cuntery*" className='inputfld' value="India" disabled /></Col>
                                            <Col md><Form.Control type="number" id='pin' name="pin" autoComplete='off' placeholder="Pin Code*" className='inputfld' onInput={postoffice} /></Col>
                                        </Row>
                                        <Row className="g-2 mb-3" >
                                            <Col md><Form.Control type="text" id='state' name="State" autoComplete='off' placeholder="State*" className='inputfld' disabled required /></Col>
                                            <Col md><Form.Control type="text" id='dist' name="City" autoComplete='off' placeholder="City*" className='inputfld' disabled required /></Col>
                                        </Row>
                                        <Row className="g-2">
                                            <Col md>
                                                <Form.Select id="postoffice" className='inputfld' name="Post Office" required>
                                                    <option value=''>-- Select Post Office --</option>
                                                </Form.Select>
                                            </Col>
                                            <Col md>
                                                <InputGroup className="mb-3">
                                                    <MDBInput label='Address*' id='address' name="Address" autoComplete='off' type='text' className='inputfld' required />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row className="g-2">
                                            <Col md>
                                                <InputGroup className="mb-3">
                                                    <MDBInput label='Login Time*' id='logintm' autoComplete='off' type='text' className='inputfld' disabled />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Col md className='mt-3'>
                                            <MDBBtn className='me-1 donate-continue'>Update</MDBBtn>
                                        </Col>
                                    </Form>
                                </div>
                                <div className='contener donation_dt'>
                                    <div className='d-flex justify-content-between'>
                                        <div className=''>
                                            <p className="text-muted mb-3">Total no of donation :<code>{finddata?.donation?.length < 10 ? '0' + finddata?.donation?.length : finddata?.donation?.length}</code></p>
                                        </div>
                                    </div>
                                    <Table
                                        columns={columns1}
                                        expandable={{
                                            expandedRowRender: (record) => (
                                                <p
                                                    style={{
                                                        margin: 0,
                                                    }}
                                                >
                                                    {record.description}
                                                </p>
                                            ),
                                            rowExpandable: (record) => record.name !== 'Not Expandable',
                                        }}
                                        dataSource={data1}
                                    />
                                </div>
                                <div className='contener event_dt'>
                                    <div className='d-flex justify-content-between'>
                                        <div className=''>
                                            <p className="text-muted mb-3">Total Booked Events :<code>{finddata?.event?.length < 10 ? '0' + finddata?.event?.length : finddata?.event?.length}</code></p>
                                        </div>
                                    </div>
                                    <Table
                                        columns={columns2}
                                        expandable={{
                                            expandedRowRender: (record) => (
                                                <p
                                                    style={{
                                                        margin: 0,
                                                    }}
                                                >
                                                    {record.description}
                                                </p>
                                            ),
                                            rowExpandable: (record) => record.name !== 'Not Expandable',
                                        }}
                                        dataSource={data2}
                                    />
                                </div>
                            </Card.Body>
                        </Card>

                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>
    </>)
}
export default Myaccount;