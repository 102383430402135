import Form from 'react-bootstrap/Form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import {encode} from 'html-entities';
const NewPage = () => {
    const [conts, getComponent] = useState([]);
    const urlredirect = useNavigate();
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        fetch(process.env.REACT_APP_API_URL + '/addpage', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({
                title: e.target.subject.value,
                content: encode(conts),
                status: e.target.currnrt_status.value,
                route: e.target.route.value,
                css: e.target.css.value
            })
        }).then(response => response.json())
            .then(data => {
                if (data['status'] == 'success') {
                    toast_alert('page successfully added', 0);
                    urlredirect('/admin/pagelist');
                } else {
                    toast_alert('page allready exist...!!', 1);
                }
            })
            .catch((error) => {
                toast_alert('Somthing a worng...!!', 1);
            });
    }
    return (<>
        <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className='d-flex justify-content-between'>
                            <div className=''>
                                <div className=''>
                                    <h6 className="card-title">Page</h6>
                                    <p className="text-muted mb-3">Add New</p>
                                </div>
                            </div>
                            <div>
                                <Link to="/admin/pagelist"><button className='custom-btn'>Page Lsit</button></Link>
                            </div>
                        </div>
                        <div className="table-responsive pt-3">
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Add Title</Form.Label>
                                    <Form.Control id="subject" type="text" placeholder="Title" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Route</Form.Label>
                                    <Form.Control id="route" type="text" placeholder="Route" required />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Content</Form.Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        onChange={(event, editor) => {
                                            getComponent(editor.getData());
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Additional CSS</Form.Label><br/>
                                    <textarea id="css" cols="30" rows="10"style={{width: "100%",height: "200px"}}></textarea>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="status">
                                    <Form.Label htmlFor="status">Status</Form.Label>
                                    <Form.Select id="currnrt_status">
                                        <option value="0">Inactive</option>
                                        <option value="1">Active</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="description">
                                    <input type='submit' className="btn btn-outline-primary" value="Add New" />
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default NewPage;