import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './css/core.css';
import './css/admin2.css';
import { isExpired, decodeToken } from "react-jwt";
const Header = () => {
  const urlredirect = useNavigate();
  useEffect(() => {
    const path = document.location.pathname.split('/');
    if (localStorage.getItem("mnb") === null || isExpired(localStorage.getItem("mnb"))) {
      if (path[1] && path[2] && path[1] == 'admin') {
        urlredirect('/admin');
      }
    }
  }, []);
  return (<>
    <nav className="navbar">
      <Link to="#" className="sidebar-toggler">
        <i data-feather="menu"></i>
      </Link>
      <div className="navbar-content">
        <form className="search-form">
          <div className="input-group">
            <div className="input-group-text">
              <i data-feather="search"></i>
            </div>
            <input type="text" className="form-control" id="navbarForm" placeholder="Search here..." />
          </div>
        </form>
        <ul className="navbar-nav">

          <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" to="#" id="profileDropdown" role="button" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <img className="wd-30 ht-30 rounded-circle" src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg" alt="profile" />
            </Link>
            <div className="dropdown-menu p-0" aria-labelledby="profileDropdown">
              <ul className="list-unstyled p-1">
                <li className="dropdown-item py-2">
                  <Link to="profile.html" className="text-body ms-0">
                    <i className="me-2 icon-md" data-feather="user"></i>
                    <span>Profile</span>
                  </Link>
                </li>
                <li className="dropdown-item py-2">
                  <Link to="javascript:;" className="text-body ms-0">
                    <i className="me-2 icon-md" data-feather="edit"></i>
                    <span>Edit Profile</span>
                  </Link>
                </li>
                <li className="dropdown-item py-2">
                  <Link to="javascript:;" className="text-body ms-0">
                    <i className="me-2 icon-md" data-feather="repeat"></i>
                    <span>Switch User</span>
                  </Link>
                </li>
                <li className="dropdown-item py-2">
                  <Link to="javascript:;" className="text-body ms-0">
                    <i className="me-2 icon-md" data-feather="log-out"></i>
                    <span>Log Out</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <ToastContainer />
  </>)
}
export default Header;