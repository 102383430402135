import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import { FaUserTie } from "react-icons/fa";
import { BiDonateBlood } from "react-icons/bi";
import { FaUsersCog } from "react-icons/fa";
import { MdHealthAndSafety } from "react-icons/md";
import { FaGraduationCap } from "react-icons/fa";
import { FaEnvira } from "react-icons/fa";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { BsPiggyBank } from "react-icons/bs";
import Api from './../Auth/Auth';
let Home = () => {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    const [home, setHome] = useState({ counter: [{ sno: '0', title: '', count: '' }], what_we_do: [{ sno: '1', titel: '', content: '' }], our_work: [{ sno: '1', titel: '', content: '' }] });
    useEffect(() => {
        Api.get('/home').then((response) => {
            if (response.status === 200) {
                if (response.data.status === 'success') {
                    setHome(response.data.data);
                } else {
                    console.log('Api Error');
                }
            } else {
                console.log('Error: ' + response.status)
            }
        }).catch((error) => {
            console.error('Error 404');
        })
    }, []);
    return (<>
        <section>
            <Carousel activeIndex={index} onSelect={handleSelect} interval={'3000'}>
                {
                    home.banner && home.banner.map((bnr) => {
                        return (
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={process.env.REACT_APP_IMG_BASE_URL + "/img/" + bnr.image}
                                    alt="First slide"
                                />
                                <Carousel.Caption>
                                    <h3><div className="Container" dangerouslySetInnerHTML={{ __html: bnr.Heading }}></div></h3>
                                    <p><div className="Container" dangerouslySetInnerHTML={{ __html: bnr.Content }}></div></p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
            <div className="header-bottom-area m-top bg-image padding-bottom-120 padding-top-120" style={{ backgroundImage: "url(/main/files/static/bg-02.png)"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-12">
                            <div className="help-and-support-left">
                                <div className="section-title margin-bottom-35">
                                    <h1><i className="fas fa-heart"></i>About Us</h1>
                                    <h2 className="title">
                                        Changing the Lives of <span>Countless</span>   Children Together.
                                    </h2>
                                    <p>
                                        We empower poor Indian children by unlocking their potential through programs that promote physical, mental, and emotional development. Our activities create opportunities, resources, and direction for a better future. We are working together to build a society in which every child, regardless of background, can thrive and make a positive difference.
                                    </p>
                                </div>
                                <div className="content">
                                    <ul>

                                        <li><Link to="#"><i className="fas fa-check"></i>If You Want to Change the World, Start from Here.               </Link></li>
                                        <li><Link to="#"><i className="fas fa-check"></i>If you want to contribute to a better future, Start from Here.  </Link></li>
                                        <li><Link to="#"><i className="fas fa-check"></i>If you want to shape a brighter tomorrow, Start from Here.      </Link></li>
                                        <li><Link to="#"><i className="fas fa-check"></i>If you want to create a positive impact, Start from Here.       </Link></li>
                                        <li><Link to="#"><i className="fas fa-check"></i>If you want to make a difference, Start from Here.              </Link></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12">
                            <div className="help-and-support-right bg-image" style={{ backgroundImage: "url(/main/files/static/bg-doute.png)" }}>
                                <div className="support-img">
                                    <div className="thumb">
                                        <img src="/main/files/static/child.jpg"/>
                                    </div>
                                    <div className="donation-content">
                                        <h3 className="price">200+</h3>
                                        <span> Volunteer</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="volunteer-area padding-top-160">
                        <div className="row">
                            <div className="col-lg-6 col-xl-4 col-md-6">
                                <div className="volunteer-single-item margin-bottom-30 bg-image" style={{ backgroundImage: "url(/main/files/static/vendros-bg.png)" }}>
                                    <div className="icon">
                                        <FaUserTie />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">
                                            <Link to="">{home?.our_work[0]?.title}</Link>
                                        </h4>
                                        <p><div className="Container" dangerouslySetInnerHTML={{ __html: home?.our_work[0]?.content }}></div></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4 col-md-6">
                                <div className="volunteer-single-item style-01 margin-bottom-30 bg-image" style={{ backgroundImage: "url(/main/files/static/vendros-bg.png)" }}>
                                    <div className="icon">
                                        <BiDonateBlood />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">
                                            <Link to="">{home?.our_work[1]?.title}</Link>
                                        </h4>
                                        <p><div className="Container" dangerouslySetInnerHTML={{ __html: home?.our_work[1]?.content }}></div></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4 col-md-6">
                                <div className="volunteer-single-item style-02 margin-bottom-30 bg-image" style={{ backgroundImage: "url(/main/files/static/vendros-bg.png)" }}>
                                    <div className="icon">
                                        <FaUsersCog />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">
                                            <Link to="">{home?.our_work[2]?.title}</Link>
                                        </h4>
                                        <p><div className="Container" dangerouslySetInnerHTML={{ __html: home?.our_work[2]?.content }}></div></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-latest-area padding-bottom-90 padding-top-90">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-12">
                            <div className="help-and-support-left">
                                <div className="section-title margin-bottom-35">

                                    <h2 className="title">
                                        Save a life. Donate to  <span>MNB Foundation</span>
                                    </h2>
                                    <p>
                                        ‘MNB Foundation’ is a non-profit Charity Organization with more than 10 branches in India, MNB Foundation has transformed lives in every dimension spoken. From eliminating the root problems of education by providing free education and meals to providing special guided education to the deaf and dumb. From teaching life skills to the visually impaired to the professional skill development of the differently-abled.
                                    </p>
                                    <p>‘MNB Foundation’ was established in the year 2023 with a fistful of flour and to serve the patients belonging to the physically and economically disadvantaged sections of the society through the free-of-cost corrective surgeries of patients suffering from polio and other congenital disabilities. The headquarters of the organization is at Noida city in the state of Uttar Pradesh, India.</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12">
                            <div className="help-and-support-right bg-image padding-top-50">
                                <div className="feature-box"><div className="feature-icon"><img src="https://quomodosoft.com/wp/profund/wp-content/uploads/2019/08/icon-1.png" className="attachment-full size-full" loading="lazy" width="48" height="48" /></div><div className="box-content"><h4 className="feature-title">Educations for Children</h4></div></div>

                                <div className="feature-box"><div className="feature-icon"><img src="https://quomodosoft.com/wp/profund/wp-content/uploads/2019/08/icon-2.png" className="attachment-full size-full" loading="lazy" width="42" height="47" /></div><div className="box-content"><h4 className="feature-title">Home for homeless people</h4></div></div>
                                <div className="feature-box"><div className="feature-icon"><img src="https://quomodosoft.com/wp/profund/wp-content/uploads/2019/08/icon-3.png" className="attachment-full size-full" loading="lazy" width="48" height="42" /></div><div className="box-content"><h4 className="feature-title">Food for hungry peoples</h4></div></div>
                                <div className="feature-box"><div className="feature-icon"><img src="https://quomodosoft.com/wp/profund/wp-content/uploads/2019/08/icon-4.png" className="attachment-full size-full" loading="lazy" width="48" height="48" /></div><div className="box-content"><h4 className="feature-title">Medicine for poor people</h4></div></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="work-towards-area bg-image padding-bottom-115 padding-top-120" style={{ backgroundImage: "url(/main/files/static/works-towards.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-12">
                            <div className="left-content">
                                <div className="inner-section-title padding-top-160 bg-image" style={{ backgroundImage: "url(/main/files/static/charity.png)"}}>
                                    <h2 className="title">Works towards supporting underprivileged people</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="right-content">
                                <div className="vdo-btn">
                                    <Link className="video-play mfp-iframe" href="https://www.youtube.com/watch?v=-ZwQtICNbRc"><i className="fas fa-play"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="problem-area padding-top-90 padding-bottom-85">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-sm-11 col-11">
                            <div className="section-title b-top desktop-center padding-top-25 margin-bottom-55">
                                <span>What we do</span>
                                <h2 className="title">

                                    We have chosen the following issues.
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex">
                        <div className="col-lg-4 col-md-6">
                            <div className="problem-single-item margin-bottom-30">
                                <div className="icon">
                                    <MdHealthAndSafety />
                                </div>
                                <div className="content">
                                    <h4 className="title">
                                        <Link href>{home?.what_we_do[0]?.title}</Link>
                                    </h4>
                                    <p><div className="Container" dangerouslySetInnerHTML={{ __html: home?.what_we_do[0]?.content }}></div><br /><br /><br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="problem-single-item margin-bottom-30">
                                <div className="icon style-01">
                                    <FaGraduationCap />
                                </div>
                                <div className="content">
                                    <h4 className="title">
                                        <Link href="">{home?.what_we_do[1]?.title}</Link>
                                    </h4>
                                    <p><div className="Container" dangerouslySetInnerHTML={{ __html: home?.what_we_do[1]?.content }}></div><br /> <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="problem-single-item margin-bottom-30">
                                <div className="icon style-02">
                                    <FaEnvira />
                                </div>
                                <div className="content">
                                    <h4 className="title">
                                        <Link href="">{home?.what_we_do[2]?.title}</Link>
                                    </h4>
                                    <p><div className="Container" dangerouslySetInnerHTML={{ __html: home?.what_we_do[2]?.content }}></div></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="counterup-area bg-image padding-bottom-75 padding-top-90" style={{ backgroundImage: "url(/main/files/static/bg03.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-counterup-01">
                                <div className="icon">
                                    <BsPiggyBank />
                                </div>
                                <div className="content">
                                    <div className="count-wrap"><span className="count-num">{home?.counter[0]?.count}</span>+</div>
                                    <h4 className="title">{home?.counter[0]?.title}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-counterup-01">
                                <div className="icon">
                                    <AiOutlineUsergroupAdd />
                                </div>
                                <div className="content">
                                    <div className="count-wrap"><span className="count-num">{home?.counter[1]?.count}</span>+</div>
                                    <h4 className="title">{home?.counter[1]?.title}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-counterup-01">
                                <div className="icon">
                                    <AiOutlineUserSwitch />
                                </div>
                                <div className="content">
                                    <div className="count-wrap"><span className="count-num">{home?.counter[2]?.count}</span>+</div>
                                    <h4 className="title">{home?.counter[2]?.title}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="testimonial-area padding-bottom-105 padding-top-115">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-12 p-0">
                            <div className="section-title desktop-center margin-bottom-50">
                                <span>Testimonial</span>
                                <h3 className="title">
                                    What <span>peoples</span> say about us
                                </h3>
                            </div>
                        </div>
                    </div>
                    <Carousel>
                        {
                            home.testimonial && home.testimonial.map((test) => {
                                return (
                                    <Carousel.Item>
                                        <div className="d-block w-100">
                                            <div className="thumb-prof text-center" >
                                                <img src={process.env.REACT_APP_IMG_BASE_URL + '/img/' + test.image} /></div>
                                            <h4 className="title text-center">{test.name}</h4>
                                            <p className="description text-center mb-5"><div className="Container" dangerouslySetInnerHTML={{ __html: test.content }}></div></p>
                                        </div>
                                    </Carousel.Item>
                                )
                            })
                        }
                    </Carousel>
                </div>
            </section>
        </section>
    </>)
}
export default Home;