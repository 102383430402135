import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Card from 'react-bootstrap/Card';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import 'mdbreact/dist/css/mdb.css';
import Api from '../Auth/Auth';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import 'moment-timezone';
const Testimonial = () => {
  const toast_alert = (sms, status) => {
    if (status == 0) {
      toast.success(sms, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(sms, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  const deletetestimpnial = (e) => {
    fetch(process.env.REACT_APP_API_URL + '/del', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', },
      body: JSON.stringify({
        sno: e.target.value,
        table: 'testimonial'
      })
    }).then(response => response.json())
      .then(data => {
        if (data['status'] == 'success') {
          toast_alert('Menu Succussfully deleted.', 0);
          getdata();
        } else {
          toast_alert(data['status'], 1);
        }
      }).catch((error) => {
        toast_alert('something went wrong', 1);
      });
  }
  const data = {
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'asc',
        width: 50
      },
      {
        label: 'Image',
        field: 'image',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 200
      }
      ,
      {
        label: 'Last Modify',
        field: 'lst',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'asc',
        width: 200
      }
    ],
    rows: [{}]
  };
  const [temp, setUsers] = useState([{ data: { sno: '1' } }]);
  const getdata = async () => {
    Api.get("/testimonial_list").then(function (response) {
      setUsers(response.data.data);
    }).catch(function (err) {
      console.log(err);
    })
  }
  useEffect(() => {
    getdata();
  }, [temp]);
  temp && temp.map((inf, i) => {
    var act;
    if (temp[i].status == 1) { act = 'Active' } else { act = 'Inactive'; }
    data.rows.push({
      id: i + 1,
      image: <img src={process.env.REACT_APP_IMG_BASE_URL + "/img/" + temp[i].image} />,
      name: temp[i].name,
      status: <div className="Container" dangerouslySetInnerHTML={{ __html: temp[i].status == 1 ? '<div className="bg-success text-white rounded-pill text-center">Active</div>' : '<div className="bg-warning text-white rounded-pill text-center">inactive</div>' }}></div>,
      lst: <Moment format="DD/MMMM/YYYY hh:mm:ss">{temp[i].date}</Moment>,
      action: <Dropdown><Dropdown.Toggle variant="success" id="dropdown-basic">Action</Dropdown.Toggle><Dropdown.Menu><Dropdown.Item><Link to={"/admin/testimonial/edit/"+temp[i].sno}>Edit</Link></Dropdown.Item><Dropdown.Item><button value={temp[i].sno} onClick={deletetestimpnial}>Del</button></Dropdown.Item></Dropdown.Menu> </Dropdown>
    })
  })
  return (
    <>
      <Card>
        <Card.Header>Testimonial List</Card.Header>
        <Card.Body>
          <div className=''>
            <div className='d-flex justify-content-between'>
              <div className="text-muted mb-3">Total Testimonial :<code>{temp.length<10? '0'+temp.length:temp.length}</code></div>
              <Link to="/admin/addtestimonial"><button className='border border-success bg-success text-white'>Add Testimonial</button></Link>
            </div>
          </div>
          <MDBDataTable
            striped
            bordered
            small
            data={data}
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default Testimonial;