import { useState } from "react";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Api from '../Auth/Auth';
const Add_volunnteer = () => {
    const Navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [pincodeMessage, setPincodeMessage] = useState("");
    const [location, setLocation] = useState([]);
    const [profileImg, setProfileImg] = useState("/main/files/static/profile.webp");
    const [age, setAge] = useState("");
    const pickProfileImag = (e) => e.currentTarget.files[0] ? setProfileImg(URL.createObjectURL(e.currentTarget.files[0])) : setProfileImg("/main/files/static/profile.webp");
    const CalAge = (e) => e.currentTarget.value ? setAge(parseInt(Math.floor((Date.parse(new Date()) - Date.parse(new Date(e.currentTarget.value))) / 86400000) / 366) + ' Years') : setAge("");
    const membership = (e) => {
        e.preventDefault();
        let lag = document.getElementsByName("language");
        let lang = [];
        lag.forEach(select => { if (select.checked) { lang.push(select.value); } });
        if (lang.length === 0) { toast('Please select language.'); return }
        setLoader(true);
        Api.post('/membership', {
            photo: e.currentTarget.profileimage.files[0],
            name: e.currentTarget.name.value,
            father_name: e.currentTarget.fname.value,
            sex: e.currentTarget.sex.value,
            dob: e.currentTarget.dob.value,
            age: e.currentTarget.age.value,
            education: e.currentTarget.edu.value,
            pin: e.currentTarget.pincode.value,
            country: e.currentTarget.country.value,
            state: e.currentTarget.state.value,
            district: e.currentTarget.district.value,
            block: e.currentTarget.block.value,
            post: e.currentTarget.post.value,
            address: e.currentTarget.address.value,
            phone: e.currentTarget.phone.value,
            email: e.currentTarget.email.value,
            panno: e.currentTarget.pan.value,
            phone_recived_by: e.currentTarget.rphone.value,
            language: lang.join(),
            fee: parseInt(e.currentTarget.fees.value),
            apid: e.currentTarget.apid.value,
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            if (res.status === 200 && res.data.status === 1) {
                toast(res.data.message);
                Navigate('/admin/volunteer');
            } else {
                toast('Fill all required fields...!!');
            }
            setLoader(false);
        }).catch(err => {
            toast('Something went wrong...!!');
            setLoader(false);
        })

    }
    const FindLocation = (e) => {
        setLocation([]);
        e.currentTarget.value = e.currentTarget.value.slice(0, 6);
        if (e.currentTarget.value.length === 6) {
            axios.get('https://api.postalpincode.in/pincode/' + e.currentTarget.value).then(res => {
                if (res.status === 200 && res.data[0].Status === 'Success') {
                    setLocation(res.data[0].PostOffice);
                    setPincodeMessage("");
                } else {
                    setPincodeMessage("Invalid pin code...!!")
                }
            }).catch(err => {
                console.log(err);
            })
        }
    }
    return (<>
        <div className="formbg">
            <div className="py-5">
                <form method="post" onSubmit={membership}>
                    <div className="row">
                        <div className="col-md-8 ma m-auto">
                            <div className="bg-white border p-5 rounded">
                                <h5 className="text-center">MEMBERSHIP FORM OF MNB FOUNDATION (TRUST)</h5>
                                <div className="my-0">
                                    <div class="content">
                                        <div class="box box-solid">
                                            <div class="box-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group row mt-0">
                                                            <div className="text-center">(To be filled in by office) </div>
                                                        </div>
                                                        <hr />
                                                        <div className="border mt-3" style={{ float: "right", left: "clear", bottom: "clear" }}>
                                                            <label htmlFor="profileimage">
                                                                <img src={profileImg} className="profileImg" />
                                                                <input type="file" id="profileimage" onChange={pickProfileImag} accept="image/*" hidden />
                                                            </label>
                                                        </div>
                                                        <div class="form-group row mt-2 pt-2">
                                                            <div>
                                                                To,
                                                                <div style={{ margin: '0px 0px 17px 50px' }}>
                                                                    <p className="mb-0 ml-5">The Chairman,  </p>
                                                                    <p className="mb-0 ml-5">MNB Foundation (Trust) </p>
                                                                    <p className="mb-0 ml-5">Villa No.34, Sindhuja Valley Colony, Baidapura,  GR.Noida,</p>
                                                                    <p className="mb-0 ml-5">Distt. GB Nagar,</p>
                                                                    <p className="mb-0 ml-5"> GR. Noida-201009, Uttar Pradesh, India. </p>
                                                                </div>
                                                                Sir,
                                                            </div>
                                                        </div>
                                                        <div className="w-100 d-block">
                                                            <p className="mb-0">
                                                                <div class="form-group row" style={{ margin: '0px 0px 5px 50px' }}>
                                                                    <label class="col-sm-3 col-form-label" htmlFor="sno">I</label>
                                                                    <div class="col-sm-9"><input type="text" id="sno" class="form-control" required /></div>
                                                                </div>
                                                                <div class="form-group row" style={{ margin: '0px 0px 5px 50px' }}>
                                                                    <label class="col-sm-3 col-form-label" htmlFor="sno">s/o, w/o, d/o </label>
                                                                    <div class="col-sm-9"><input type="text" id="sno" class="form-control" required /></div>
                                                                </div>
                                                                <div>
                                                                    <label htmlFor="fees">want to apply for the membership of MNB Foundation (Trust) for category given below:
                                                                        Registration Fees (Rs. 501/)&nbsp;&nbsp;&nbsp;
                                                                        <input class="form-check-input" type="checkbox" value="501" id="fees" required /></label>
                                                                </div>
                                                            </p>
                                                        </div>
                                                        <p className="mt-5">My Personal particulars are as follows:</p>
                                                        <div class="form-group row mb-2">
                                                            <label class="col-sm-1 col-form-label">1</label>
                                                            <label class="col-sm-2 col-form-label" htmlFor="name">Name</label>
                                                            <div class="col-sm-9"><input type="text" id="name" class="form-control" required /></div>
                                                        </div>
                                                        <div class="form-group row mb-2">
                                                            <label class="col-sm-1 col-form-label">2</label>
                                                            <label class="col-sm-2 col-form-label" htmlFor="sex">Sex</label>
                                                            <div class="col-sm-9">
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio" name="sex" id="male" value="Male" required />
                                                                    <label class="form-check-label" htmlFor="male">Male</label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio" name="sex" id="female" value="Female" required />
                                                                    <label class="form-check-label" htmlFor="female">Female</label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio" name="sex" id="transgender" value="Transgender" />
                                                                    <label class="form-check-label" htmlFor="transgender">Transgender </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row mb-2">
                                                            <label class="col-sm-1 col-form-label">3</label>
                                                            <label class="col-sm-2 col-form-label" htmlFor="fname">S/o, W/o, D/o </label>
                                                            <div class="col-sm-9"><input type="text" id="fname" class="form-control" required /></div>
                                                        </div>
                                                        <div class="form-group row mb-2">
                                                            <label class="col-sm-1 col-form-label">4</label>
                                                            <label class="col-sm-2 col-form-label" htmlFor="dob">Date of Birth</label>
                                                            <div class="col-sm-5"><input type="date" id="dob" class="form-control" required max={new Date(Date.parse(new Date()) - (86400000 * 366 * 15)).toLocaleDateString('fr-ca')} onChange={CalAge} required /></div>
                                                            <label class="col-sm-1 col-form-label" htmlFor="age">5.Age</label>
                                                            <div class="col-sm-3"><input type="text" id="age" class="form-control" value={age} required /></div>
                                                        </div>
                                                        <div class="form-group row mb-0">
                                                            <label class="col-sm-1 col-form-label">6</label>
                                                            <label class="col-sm-2 col-form-label" htmlFor="edu">Higher Education</label>
                                                            <div class="col-sm-9"><input type="text" id="edu" class="form-control" required /></div>
                                                        </div>
                                                        <div class="form-group row mb-2">
                                                            <label class="col-sm-1 col-form-label">7</label>
                                                            <label class="col-sm-2 col-form-label" htmlFor="pincode">Pincode</label>
                                                            <div class="col-sm-4">
                                                                <input type="text" id="pincode" class="form-control" onInput={FindLocation} required />
                                                                <span className="text-danger">{pincodeMessage}</span>
                                                            </div>
                                                            <label class="col-sm-1 col-form-label" htmlFor="country">Country</label>
                                                            <div class="col-sm-4"><input type="text" id="country" class="form-control" value={location[0]?.Country ? location[0]?.Country : ""} required /></div>
                                                        </div>
                                                        <div class="form-group row mb-2">
                                                            <label class="col-sm-1 col-form-label"></label>
                                                            <label class="col-sm-2 col-form-label" htmlFor="state">State</label>
                                                            <div class="col-sm-4"><input type="text" id="state" class="form-control" value={location[0]?.State ? location[0]?.State : ""} required /></div>
                                                            <label class="col-sm-1 col-form-label" htmlFor="district">District</label>
                                                            <div class="col-sm-4"><input type="text" id="district" class="form-control" value={location[0]?.District ? location[0]?.District : ""} required /></div>
                                                        </div>
                                                        <div class="form-group row mb-2">
                                                            <label class="col-sm-1 col-form-label"></label>
                                                            <label class="col-sm-2 col-form-label" htmlFor="block">Block</label>
                                                            <div class="col-sm-4"><input type="text" id="block" class="form-control" value={location[0]?.Block ? location[0]?.Block : ""} required /></div>
                                                            <label class="col-sm-1 col-form-label" htmlFor="post">Postoffice</label>
                                                            <div class="col-sm-4">
                                                                <select class="form-select" id="post" required>
                                                                    <option value=''>-- Select post office --</option>
                                                                    {
                                                                        location.map((po) => {
                                                                            return (<option value={po.Name}>{po.Name}</option>)
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row mb-2">
                                                            <label class="col-sm-1 col-form-label"></label>
                                                            <label class="col-sm-2 col-form-label" htmlFor="address">Address</label>
                                                            <div class="col-sm-9"><input type="text" id="address" class="form-control" required /></div>
                                                        </div>
                                                        <div class="form-group row mb-2">
                                                            <label class="col-sm-1 col-form-label">8</label>
                                                            <label class="col-sm-2 col-form-label" htmlFor="phone">Phone</label>
                                                            <div class="col-sm-4"><input type="text" id="phone" class="form-control" required /></div>
                                                            <label class="col-sm-1 col-form-label" htmlFor="email">Email</label>
                                                            <div class="col-sm-4"><input type="email" id="email" class="form-control" required /></div>
                                                        </div>
                                                        <div class="form-group row mb-0">
                                                            <label class="col-sm-1 col-form-label">9</label>
                                                            <label class="col-sm-7 col-form-label" htmlFor="pan">PAN No. (along with designation -Circle/ Ward/ Place) </label>
                                                            <div class="col-sm-4"><input type="text" id="pan" class="form-control" required /></div>
                                                        </div>
                                                        <div class="form-group row mb-0">
                                                            <label class="col-sm-1 col-form-label">10</label>
                                                            <label class="col-sm-11 col-form-label">Choice of Language in which Yog Sandesh is desired (for Corporate Member to Life Member only)  </label>
                                                        </div>
                                                        <div class="form-group row mb-0">
                                                            <label class="col-sm-1 col-form-label"></label>
                                                            <label class="col-sm-11 col-form-label">
                                                                <div class="form-group row mb-0">
                                                                    <div class="col-sm-3">
                                                                        <input class="form-check-input" type="checkbox" id="hindi" name="language" value="Hindi" />
                                                                        <label class="form-check-label" for="hindi">Hindi</label>
                                                                    </div>
                                                                    <div class="col-sm-3">
                                                                        <input class="form-check-input" type="checkbox" id="english" name="language" value="English" />
                                                                        <label class="form-check-label" for="english">English</label>
                                                                    </div>
                                                                    <div class="col-sm-3">
                                                                        <input class="form-check-input" type="checkbox" id="gujarati" name="language" value="Gujarati" />
                                                                        <label class="form-check-label" for="gujarati">Gujarati</label>
                                                                    </div>
                                                                    <div class="col-sm-3">
                                                                        <input class="form-check-input" type="checkbox" id="marathi" name="language" value="Marathi" />
                                                                        <label class="form-check-label" for="marathi">Marathi</label>
                                                                    </div>
                                                                    <div class="col-sm-3">
                                                                        <input class="form-check-input" type="checkbox" id="bengali" name="language" value="Bengali" />
                                                                        <label class="form-check-label" for="bengali">Bengali</label>
                                                                    </div>
                                                                    <div class="col-sm-3">
                                                                        <input class="form-check-input" type="checkbox" id="oriya" name="language" value="Oriya" />
                                                                        <label class="form-check-label" for="oriya">Oriya</label>
                                                                    </div>
                                                                    <div class="col-sm-3">
                                                                        <input class="form-check-input" type="checkbox" id="nepali" name="language" value="Nepali" />
                                                                        <label class="form-check-label" for="nepali">Nepali</label>
                                                                    </div>
                                                                    <div class="col-sm-3">
                                                                        <input class="form-check-input" type="checkbox" id="punjabi" name="language" value="Punjabi" />
                                                                        <label class="form-check-label" for="punjabi">Punjabi</label>
                                                                    </div>
                                                                    <div class="col-sm-3">
                                                                        <input class="form-check-input" type="checkbox" id="assamese" name="language" value="Assamese" />
                                                                        <label class="form-check-label" for="assamese">Assamese</label>
                                                                    </div>
                                                                    <div class="col-sm-3">
                                                                        <input class="form-check-input" type="checkbox" id="kannada" name="language" value="Kannada" />
                                                                        <label class="form-check-label" for="kannada">Kannada</label>
                                                                    </div>
                                                                    <div class="col-sm-3">
                                                                        <input class="form-check-input" type="checkbox" id="telugu" name="language" value="Telugu" />
                                                                        <label class="form-check-label" for="telugu">Telugu</label>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div class="form-group row mt-4">
                                                            <label class="col-sm-1 col-form-label">11</label>
                                                            <label class="col-sm-5 col-form-label" htmlFor="apid">Application received by (Name & ID No.)</label>
                                                            <div class="col-sm-3"><input type="text" id="apid" class="form-control" /></div>
                                                            <label class="col-sm-1 col-form-label" htmlFor="rphone">Phone</label>
                                                            <div class="col-sm-2"><input type="text" id="rphone" class="form-control" /></div>
                                                        </div>
                                                        <div className="text-center mt-4 pt-3"><strong>Declaration</strong></div>
                                                        <div className="mt-2 text-justify">
                                                            I hereby declare that I have carefully read the requisite five pledges, seven norms of good conduct and the seven tenets
                                                            of nationalism appended to the form for Membership of MNB Foundation (Trust). I will fully adhere to the said five
                                                            pledges, seven norms of good conduct and the seven tenets of nationalism. In the event of violation of rules by me under
                                                            any circumstances, MNB Foundation (Trust) is free to take any disciplinary action against me without any show-cause
                                                            notice, for which I shall be fully responsible. Please register me as a Member of MNB Foundation (Trust) in accor-
                                                            dance with the rules of MNB Foundation (Trust).
                                                        </div>
                                                        <div className="text-end mt-5">
                                                            Signature of Applicant
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                {
                                    loader ?
                                        <button type="submit" class="btn btn-primary" disabled>
                                            <div class="spinner-border" role="status"></div>
                                        </button>
                                        :
                                        <button type="submit" class="btn btn-primary"> Submit</button>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>)
}
export default Add_volunnteer;