import Form from 'react-bootstrap/Form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
const Add_What_we_do_List = () => {
    const [conts, getComponent] = useState([]);
    const urlredirect = useNavigate();
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        if (e.target.title.value!='') {
            fetch(process.env.REACT_APP_API_URL + '/content', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({
                    icon: '',
                    section: 'what-we-do',
                    title: e.target.title.value,
                    content: conts,
                    status: e.target.status.value
                })
            }).then(response => response.json())
                .then(data => {
                    if (data['status'] == 'success') {
                        toast_alert('successfully added', 0);
                        urlredirect('/admin/what-we-do');
                    } else {
                        toast_alert('Fill all requireded field...!!', 1);
                    }
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }else{
            toast_alert('Fill required filed...!!', 1);
        }
    }
    return (<>
        <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className='d-flex justify-content-between'>
                            <div className=''>
                                <div className=''>
                                    <h6 className="card-title">What we do</h6>
                                    <p className="text-muted mb-3">Add New</p>
                                </div>
                            </div>
                            <div>
                                <Link to="/admin/what-we-do"><button className='custom-btn'>View Listing</button></Link>
                            </div>
                        </div>
                        <div className="table-responsive pt-3">
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control id="title" type="text" placeholder="Title" />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Content</Form.Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        onChange={(event, editor) => {
                                            getComponent(editor.getData());
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="status">
                                    <Form.Label htmlFor="status">Status</Form.Label>
                                    <Form.Select id="status">
                                        <option value="0">Inactive</option>
                                        <option value="1">Active</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="description">
                                    <input type='submit' className="btn btn-outline-primary" value="Add New" />
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default Add_What_we_do_List;