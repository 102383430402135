import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import { FaUserTie } from "react-icons/fa";
import { BiDonateBlood } from "react-icons/bi";
import { FaUsersCog } from "react-icons/fa";
import Api from './../Auth/Auth';
import Moment from 'react-moment';
import 'moment-timezone';
const Events = () => {
    const [events, setEvents] = useState({ evenet: [{ sno: '' }], our_work: [{ sno: '', title: '', content: '' }], banner: [{ sno: '', image: '' }] });
    useEffect(() => {
        Api.get('/events').then((response) => {
            if (response.status === 200 && response.data.status === 'success') {
                setEvents(response.data.data);
            } else {
                console.log("Api error: " + response.status);
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [])
    return (<>
        <div className="breadcrumb-area" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMG_BASE_URL + "/img/" + events?.banner[0]?.image + ")" }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner">
                            <div className="icon">
                                <img src="/main/files/static/yellow-hart.png" />
                            </div>
                            <h2 className="page-title">Events</h2>
                            <ul className="page-list">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Events Blog </Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section className="attend-events-area padding-top-115 padding-bottom-90">
            <div className="container">
                <div className="row">
                    {
                        events.evenet && events.evenet.map((evn) => {
                            return (<>
                                <div className="col-lg-6">
                                    <div className="events-single-item bg-image margin-bottom-30"
                                        style={{ backgroundImage: "url(/main/files/static/bg-event.png)" }}>
                                        <div className="thumb">
                                            <div className="bg-image" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMG_BASE_URL + "/events/" + evn.image + ")" }}>
                                                <div className="post-time">
                                                    <h5 className="title">{<Moment format="DD">{evn.start_time}</Moment>}</h5>
                                                    <span>{<Moment format="MMMM">{evn.start_time}</Moment>}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <Link to="Events details" className="title" href>{evn.title}</Link>
                                                <ul className="info-items-03">
                                                    <li><Link to="#"><i className="far fa-clock"></i>{<Moment format="hh:mm A">{evn.start_time}</Moment>} - {<Moment format="hh:mm A">{evn.end_time}</Moment>}</Link></li>
                                                    <li><Link to={evn.url}><i className="fas fa-map-marker-alt"></i> {evn.location}</Link></li>
                                                </ul>
                                                <div className="events-btn-wrapper">
                                                    <Link to={"/events/" + evn.title} className="book-btn">Book Seat</Link>
                                                    <Link className="free-btn">{+evn.charges == 0 ? 'Free' : evn.charges}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>)
                        })
                    }
                </div>
            </div>
        </section>
        <div className="volunteer-area padding-bottom-120 padding-top-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-xl-4 col-md-6">
                        <div className="volunteer-single-item margin-bottom-30 bg-image" style={{ backgroundImage: "url(main/img/volunteer/bg.png)" }}>
                            <div className="icon">
                                <FaUserTie />
                            </div>
                            <div className="content">
                                <h4 className="title">
                                    <Link to="">{events?.our_work[0]?.title}</Link>
                                </h4>
                                <p><div className="Container" dangerouslySetInnerHTML={{ __html: events?.our_work[0]?.content }}></div></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-4 col-md-6">
                        <div className="volunteer-single-item style-01 margin-bottom-30 bg-image" style={{ backgroundImage: "url(/main/img/volunteer/bg.png)" }}>
                            <div className="icon">
                                <BiDonateBlood />
                            </div>
                            <div className="content">
                                <h4 className="title">
                                    <Link to="">{events?.our_work[1]?.title}</Link>
                                </h4>
                                <p><div className="Container" dangerouslySetInnerHTML={{ __html: events?.our_work[1]?.content }}></div></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-4 col-md-6">
                        <div className="volunteer-single-item style-02 margin-bottom-30 bg-image" style={{ backgroundImage: "url(/main/img/volunteer/bg.png)" }}>
                            <div className="icon">
                                <FaUsersCog />
                            </div>
                            <div className="content">
                                <h4 className="title">
                                    <Link to="">{events?.our_work[2]?.title}</Link>
                                </h4>
                                <p><div className="Container" dangerouslySetInnerHTML={{ __html: events?.our_work[2]?.content }}></div></p>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="testimonial-area bg-image padding-bottom-100 padding-top-105"
                    style={{ backgroundImage: "url(/main/files/static/bg03.jpg)" }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="section-title white desktop-center margin-bottom-50">
                                    <h3 className="title">What <span>peoples</span> say about us
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters justify-content-center">
                            <div className="col-lg-7 col-md-12">
                                <div className="testimonial-carousel-area bg-blue">
                                    <div className="testimonial-carousel">
                                        <Carousel>
                                            {
                                                events.testimonial && events.testimonial.map((test) => {
                                                    return (
                                                        <Carousel.Item>
                                                            <div className="d-block w-100">
                                                                <div className="thumb-prof text-center" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMG_BASE_URL + "/img/" + test.image + ")", width: "120px", height: "120px", borderRadius: "50%", margin: "auto", marginBottom: "30px" }}></div>
                                                                <h4 className="title text-center text-light">{test.name}</h4>
                                                                <p className="description text-center mb-5 text-light"><div className="Container" dangerouslySetInnerHTML={{ __html: test.content }}></div></p>
                                                            </div>
                                                        </Carousel.Item>
                                                    );
                                                })
                                            }
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="volunteer-area m-top-02 padding-bottom-90 padding-top-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="volunteer-title-content margin-bottom-50">
                                    <div className="section-title desktop-left">
                                        <span>Our Volunteer</span>
                                        <h3 className="title"><span>Volunteers</span> who always support us
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                events.volunteer && events.volunteer.map((volun) => {
                                    return(<div className="col-lg-3 col-sm-6">
                                    <div className="team-single-item">
                                        <div className="thumb">
                                            <img src={process.env.REACT_APP_IMG_BASE_URL+"/volunteer/"+volun.image} />
                                            <div className="content">
                                                <h4 className="title">{volun.name}</h4>
                                                <div className="social-link">
                                                    <ul>
                                                        <li><Link to="#"><i className="fas fa-share-alt"></i></Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                                })
                            }
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </>)
}
export default Events;