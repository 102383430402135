import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MDBFile } from 'mdb-react-ui-kit';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import Moment from 'react-moment';
import 'moment-timezone';
import Api from '../Auth/Auth';
const Banner = () => {
    const [menulist,setMenu]= useState([]);
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const [lgShow, setLgShow] = useState(false);
    const [banners, setUsers] = useState([]);
    const getBanner = async () => {
        Api.get("/banners").then(function (response) {
            setUsers(response.data.data);
        }).catch(function (err) {
            console.log(err);
        });
        Api.get("/menu-list").then(function (response) {
           if(response.status===200 && response.data.status === 'success'){
            setMenu(response.data.data);
           }
        }).catch(function (err) {
            console.log(err);
        })
    }
    useEffect(() => {
        getBanner();
    }, [])
    const Delete_bnr = (b) => {
        fetch(process.env.REACT_APP_API_URL + '/delete_baner', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({
                sno: b.currentTarget.id
            })
        }).then(response => response.json())
            .then(data => {
                if (data['status'] == 'success') {
                    toast_alert('Banner Succussfully deleted.', 0);
                    getBanner();
                } else {
                    toast_alert(data['status'], 1);
                }
            }).catch((error) => {
                toast_alert('something went wrong', 1);
            });
    }
    const [content, setcontent] = useState([]);
    const AddBanner = (b) => {
        b.preventDefault();
        if (b.currentTarget.bannerimg.value != '' && b.currentTarget.page.value != '') {
            var img = b.currentTarget.bannerimg.files;
            const formData = new FormData();
            formData.append('image', img[0]);
            formData.append('Pagetitle', b.currentTarget.page.value);
            formData.append('Heading', b.currentTarget.head.value);
            formData.append('Contant', content);
            formData.append('status', b.currentTarget.status.value);
            fetch(process.env.REACT_APP_API_URL + '/add-banner', {
                method: 'POST',
                body: formData
            }).then(response => response.json())
                .then(data => {
                    if (data['status'] == 'success') {
                        toast_alert('Banner Succussfully added.', 0);
                        getBanner();
                        setLgShow(false);
                    } else {
                        toast_alert(data['status'], 1);
                    }
                }).catch((error) => {
                    toast_alert('Somthing a worng...!!', 1);
                });
        } else {
            if (b.currentTarget.bannerimg.value == '') { toast_alert('Please select banner image...', 1); return false; }
            if (b.currentTarget.page.value == '') { toast_alert('Please enter page title...', 1); return false; }
        }
    }
    return (<>
        <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card" id="bdy">
                    <div className="card-body">
                        <div className='d-flex justify-content-between'>
                            <div className=''>
                                <h6 className="card-title">banner</h6>
                                <p className="text-muted mb-3">Total Banner :<code>{banners.length < 10 ? '0' + banners.length : banners.length}</code></p>
                            </div>
                            <div>
                                <button onClick={() => setLgShow(true)} className='custom-btn'><AiOutlinePlus /> Add New</button>
                            </div>
                        </div>
                        <div className="table-responsive pt-3">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Images</th>
                                        <th>Pges</th>
                                        <th>Status</th>
                                        <th>Last modify</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        banners.map((bnr) => {
                                            return (<>
                                                <tr>
                                                    <td><img src={process.env.REACT_APP_IMG_BASE_URL + "/img/" + bnr.image} className="baneimg" /></td>
                                                    <td>{bnr.page}</td>
                                                    <td>{bnr.status == 1 ? <div className='bg-success text-white rounded-pill text-center'>Active</div> : <div className='bg-warning text-white rounded-pill text-center'>inactive</div>}</td>
                                                    <td>{<Moment format="DD/MMMM/YYYY hh:mm:ss">{bnr.last_modify}</Moment>}</td>
                                                    <td>
                                                        <DropdownButton id="dropdown-basic-button" title="Action">
                                                            <Dropdown.Item><Link to={"/admin/banner/edit/" + bnr.sno}>Edit</Link></Dropdown.Item>
                                                            <Dropdown.Item onClick={Delete_bnr} id={bnr.sno}>Delete</Dropdown.Item>
                                                        </DropdownButton>
                                                    </td>
                                                </tr>
                                            </>)
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <>
                <Modal
                    size="lg"
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    aria-labelledby="example-modal-sizes-title-lg" id="pop"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Add Banner
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12 grid-margin stretch-card">
                                <div className="card-body">
                                    <div className="table-responsive pt-3 px-3">
                                        <Form onSubmit={AddBanner} method="POST" enctype="multipart/form-data">
                                            <Form.Group className="mb-3" controlId="bannerimg">
                                                <MDBFile label='Image *' id='bannerimg' accept="image/*" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="status">
                                                <Form.Label htmlFor="page">Page Title *</Form.Label>
                                                <Form.Select id="page">
                                                    <option>Select Page</option>
                                                    {
                                                        menulist.map((menu)=>{
                                                            return (<option>{menu.name}</option>)
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="Headding">
                                                <Form.Label>Headding</Form.Label>
                                                <Form.Control type="text" id="head" placeholder="Headding" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="Content">
                                                <Form.Label>Content</Form.Label>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data=""
                                                    onChange={(event, editor) => {
                                                        setcontent(editor.getData());
                                                    }}
                                                />
                                                <Form.Group className="mb-3" controlId="status">
                                                    <Form.Label htmlFor="status">Status</Form.Label>
                                                    <Form.Select id="status">
                                                        <option value={0}>Inactive</option>
                                                        <option value={1}>Active</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group className="mt-3" controlId="description">
                                                    <input type="submit" className="btn btn-success" value='Banner Add' />
                                                </Form.Group>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        </div>
    </>)
}
export default Banner;