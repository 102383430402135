import { Table } from 'antd';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';
import 'moment-timezone';
import { useEffect, useState } from 'react';
import { BiRupee } from 'react-icons/bi';
import Api from '../Auth/Auth';
const Donation_List = () => {
    let amt=0;
    const [donation, getDonation] = useState([]);
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        
        {
            title: 'Donation date',
            dataIndex: 'dates',
            key: 'dates',
        },
    ];
    const data = [];
    useEffect(()=>{
        Api.get('/donation_list').then((responce)=>{
            if(responce.data.status=='success'){
                getDonation(responce.data.data);
            }
        }).catch((error)=>{
            console.error(error);
        });
    },[]);
    donation && donation.map((info,i)=>{
        amt+=donation[i].amount;
        data.push({
            key: i+1,
            name: donation[i].name,
            phone: donation[i].phone,
            email: donation[i].email,
            amount: <div className="Container" dangerouslySetInnerHTML={{ __html: '<span>&#8377;</span> ' + donation[i].amount }}></div>,
            dates: <Moment format="DD/MMMM/YYYY">{donation[i].donation_date}</Moment>,
            description: <table>
                <tr>
                    <td><strong>Transaction Id :</strong> {donation[i].transaction_id}</td>
                    <td><strong>User Id :</strong> {donation[i].user_id}</td>
                    <td><strong>Pan No :</strong> {donation[i].pan}</td>
                </tr>
                <tr>
                    <td colspan="3"><strong>Address :</strong> {donation[i].address+', '+donation[i].post+', '+donation[i].city+', '+donation[i].state+', '+donation[i].country+', '+donation[i].pin}</td>
                </tr>
                <tr>
                    <td colspan="3"><strong>Exact Time :</strong> <Moment format="DD/MMMM/YYYY ">2015-02-01T00:00:00</Moment></td>
                </tr>
            </table>
        });
    })
    return(<>
        <Card>
            <Card.Header>Donation List</Card.Header>
            <Card.Body>
                <div className='d-flex justify-content-between'>
                    <div className=''>
                        <p className="text-muted mb-3">Total Amount :<code><BiRupee/>{amt}.00</code>,
                        <span> Donation: <code>{donation.length<10?'0'+donation.length:donation.length}</code></span>
                        </p>
                    </div>
                    <div>
                        <Link to="/admin/donation-grid"><button className='custom-btn'>Donation Grid</button></Link>
                    </div>
                </div>
                <Table
                    columns={columns}
                    expandable={{
                        expandedRowRender: (record) => (
                            <p
                                style={{
                                    margin: 0,
                                }}
                            >
                                {record.description}
                            </p>
                        ),
                        rowExpandable: (record) => record.name !== 'Not Expandable',
                    }}
                    dataSource={data}
                />
            </Card.Body>
        </Card>
    </>);
};
export default Donation_List;