import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';
import Api from './../Auth/Auth';
const Blog = () => {
    const url = useParams();
    const [blog,setBlog] = useState({ banner: [{	sno: '1',image: ''}], blog: [{ sno: '',image: '',title: '',content: ''}]});
    useEffect(()=>{
        Api.get('/blog-details?title='+url.id).then((responce)=>{
            if(responce.status===200 && responce.data.status==='success'){
                setBlog(responce.data.data);
            }else{
                console.log('Api Error: '+responce.status,responce.data.status);
            }
        }).catch((error)=>{
            console.log(error);
        })
    },[])
    return (<>
        <div className="breadcrumb-area" style={{ backgroundImage: "url("+process.env.REACT_APP_IMG_BASE_URL+'/img/'+blog?.banner[0]?.image+")" }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner">
                            <div className="icon">
                                <img src="/main/files/static/green-hart.png" />
                            </div>
                            <h2 className="page-title">Blog Details</h2>
                            <ul className="page-list">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Blog Details</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="page-content our-attoryney padding-top-70 padding-bottom-20">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="blog-classic-item-01 margin-bottom-60">
                            <div className="thumbnail">
                                <img src={process.env.REACT_APP_IMG_BASE_URL+"/blogs/"+blog?.blog[0]?.image} alt="blog" />
                            </div>
                            <div className="content-wrapper">
                                <div className="news-date">
                                    <h5 className="title"><Moment format="DD">{blog?.blog[0]?.last_modify}</Moment></h5>
                                    <span><Moment format="MMMM">{blog?.blog[0]?.last_modify}</Moment></span>
                                </div>
                                <div className="content">
                                    <ul className="post-meta">
                                        <li><Link to="#">By <span>mnb doundation</span></Link></li>
                                        {/* <li><Link to="#">Comments<span>(03)</span></Link></li> */}
                                    </ul>
                                    <h4 className="title"><Link>{blog?.blog[0]?.title}</Link></h4>
                                </div>
                            </div>
                            <div className="blog-bottom">
                                <p><div className="Container" dangerouslySetInnerHTML={{__html: blog?.blog[0]?.description}}></div></p>
                                {/* <div className="btn-wrapper">
                                    <Link to="#" className="boxed-btn reverse-color">Read More</Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="widget-area">
                            <div className="widget widget_recent_posts style-01">
                                <h4 className="widget-title style-01">Recent Post</h4>
                                <ul className="recent_post_item">
                                    {
                                        blog?.recent_blog && blog?.recent_blog.map((blg)=>{
                                            return(<li className="single-recent-post-item">
                                            <div className="thumb">
                                                <img src={process.env.REACT_APP_IMG_BASE_URL+"/blogs/"+blg.image} alt="recent post" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title"><Link to={"/blog/"+blg.title}>{blg.title}</Link></h4>
                                                <span className="time"><i className="far fa-calendar-alt"></i><Moment format="DD-MMMM-YYYYY">{blg.last_modify}</Moment></span>
                                            </div>
                                        </li>)
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default Blog;