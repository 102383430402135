import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUserTie } from "react-icons/fa";
import { BiDonateBlood } from "react-icons/bi";
import { FaUsersCog } from "react-icons/fa";
import { MdHealthAndSafety } from "react-icons/md";
import { FaGraduationCap } from "react-icons/fa";
import { FaEnvira } from "react-icons/fa";
import Api from './../Auth/Auth';
const Ourwork = () => {
    const [ourwork,setourwork] = useState({ what_we_do: [{ sno:'1', title: '',content: ''}], our_work: [{ sno:'1', title: '',content:''}],banner: [{ sno:'', image: ''}]});
    useEffect(()=>{
        Api.get("/our_work").then((response)=>{
            if(response.status===200 && response.data.status==="success"){
                setourwork(response.data.data)
            }else{
                console.log("Api Error: " + response.status);
            }
        }).catch((error)=>{
            console.log(error);
        })
    },[])
    return (
        <>
            <div className="breadcrumb-area" style={{ backgroundImage: "url("+process.env.REACT_APP_IMG_BASE_URL+"/img/"+ourwork?.banner[0]?.image+")" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner">
                                <div className="icon">
                                    <img src="/main/files/static/green-hart.png" />
                                </div>
                                <h2 className="page-title">Our Work</h2>
                                <ul className="page-list">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="">Our Work</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-bottom-area m-top bg-image padding-bottom-120 padding-top-120"
                style={{ backgroundImage: "url(/main/files/static/bg_trans.png)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-12">
                            <div className="help-and-support-left">
                                <div className="section-title margin-bottom-35">
                                    <h1><i className="fas fa-heart"></i>Our Work</h1>
                                    <h2 className="title">Your <span>Support</span> can help us a lot </h2>
                                    <p>Join us in our mission to change people's lives and build a better future. We can have a
                                        major and long-lasting influence if we work together. as well as emotional.</p>
                                </div>
                                <div className="content">
                                    <ul>
                                        <li><Link to="#"><i className="fas fa-check"></i>If you want to change the world</Link></li>
                                        <li><Link to="#"><i className="fas fa-check"></i>Keep the same cleaner for every visit</Link>
                                        </li>
                                        <li><Link to="#"><i className="fas fa-check"></i>One-off, weekly or fortnightly visits</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 offset-xl-1">
                            <div className="help-and-support-right bg-image"
                                style={{ backgroundImage: "url(/main/files/static/bg-doute.png)" }}>
                                <div className="support-img">
                                    <div className="thumb">
                                        <img src="/main/files/static/work1.jpg" />
                                    </div>
                                    <div className="donation-content">
                                        <h3 className="price">₹8860</h3>
                                        <span> Donated</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="volunteer-area padding-top-160">
                        <div className="row">
                            <div className="col-lg-6 col-xl-4 col-md-6">
                                <div className="volunteer-single-item margin-bottom-30 bg-image" style={{ backgroundImage: "url(/main/img/volunteer/bg.png)" }}>
                                    <div className="icon">
                                        <FaUserTie />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">
                                            <Link to="">{ourwork?.our_work[0]?.title}</Link>
                                        </h4>
                                        <p><div className="Container" dangerouslySetInnerHTML={{__html: ourwork?.our_work[0]?.content}}></div></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4 col-md-6">
                                <div className="volunteer-single-item style-01 margin-bottom-30 bg-image" style={{ backgroundImage: "url(/main/img/volunteer/bg.png)" }}>
                                    <div className="icon">
                                        <BiDonateBlood />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">
                                            <Link to="">{ourwork?.our_work[1]?.title}</Link>
                                        </h4>
                                        <p><div className="Container" dangerouslySetInnerHTML={{__html: ourwork?.our_work[1]?.content}}></div></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4 col-md-6">
                                <div className="volunteer-single-item style-02 margin-bottom-30 bg-image" style={{ backgroundImage: "url(/main/img/volunteer/bg.png)" }}>
                                    <div className="icon">
                                        <FaUsersCog />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">
                                            <Link to="">{ourwork?.our_work[2]?.title}</Link>
                                        </h4>
                                        <p><div className="Container" dangerouslySetInnerHTML={{__html: ourwork?.our_work[2]?.content}}></div></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="problem-area padding-top-90 padding-bottom-85">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-sm-11 col-11">
                            <div className="section-title b-top desktop-center padding-top-25 margin-bottom-55">
                                <span>What we do</span>
                                <h2 className="title">We have selected the following problems</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex">
                        <div className="col-lg-4 col-md-6">
                            <div className="problem-single-item margin-bottom-30">
                                <div className="icon">
                                    <MdHealthAndSafety />
                                </div>
                                <div className="content">
                                    <h4 className="title">
                                        <Link href>{ourwork?.what_we_do[0]?.title}</Link>
                                    </h4>
                                    <p><div className="Container" dangerouslySetInnerHTML={{__html: ourwork?.what_we_do[0]?.content}}></div><br /><br /><br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="problem-single-item margin-bottom-30">
                                <div className="icon style-01">
                                    <FaGraduationCap />
                                </div>
                                <div className="content">
                                    <h4 className="title">
                                        <Link href="">{ourwork?.what_we_do[1]?.title}</Link>
                                    </h4>
                                    <p><div className="Container" dangerouslySetInnerHTML={{__html: ourwork?.what_we_do[1]?.content}}></div><br /> <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="problem-single-item margin-bottom-30">
                                <div className="icon style-02">
                                    <FaEnvira />
                                </div>
                                <div className="content">
                                    <h4 className="title">
                                        <Link href="">{ourwork?.what_we_do[2]?.title}</Link>
                                    </h4>
                                    <p><div className="Container" dangerouslySetInnerHTML={{__html: ourwork?.what_we_do[2]?.content}}></div></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="work-towards-area bg-image padding-bottom-115 padding-top-120"
                style={{ backgroundImage: "url(/main/files/static/works-towards.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-12">
                            <div className="left-content">
                                <div className="inner-section-title padding-top-160 bg-image"
                                    style={{ backgroundImage: "url(main/img/charity/01.png)" }}>
                                    <h2 className="title">Works towards supporting underprivileged people</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="right-content">
                                <div className="vdo-btn">
                                    <Link className="video-play mfp-iframe" to="https://www.youtube.com/watch?v=-ZwQtICNbRc"><i
                                        className="fas fa-play"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Ourwork;