import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { MDBDataTable } from 'mdbreact';
import 'mdbreact/dist/css/mdb.css';
import Api from '../Auth/Auth';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import 'moment-timezone';
const Vender_query = () => {
  const data = {
    columns: [
      {
        label: '#',
        field: 'sno',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Phone',
        field: 'phone',
        sort: 'asc',
        width: 270
      },
      
      ,
      {
        label: 'Date',
        field: 'dt',
        sort: 'asc',
        width: 200
      },
      {
        label: 'View',
        field: 'view',
        sort: 'asc',
        width: 200
      }
    ],
    rows: [{}]
  };
  const [query, setQuery] = useState();
  const [count, setcount] = useState();
  useEffect(() => {
    Api.get("/volunteer-query").then((response)=>{
        setQuery(response.data.data);
        setcount(response.data.data.length);
      }).catch((error)=>{
        console.log(error);
      })
  }, [query]);
  query && query.map((inf,i) => {
    data.rows.push({
      sno: i+1,
      name: query[i].name,
      phone: query[i].phone,
      sms: query[i].message,
      dt: <Moment format="DD/MMMM/YYYY">{query[i].last_modify}</Moment>,
      view: <Link to={"/admin/volunteer-query/"+query[i].sno}> <Badge bg="info"> View </Badge></Link>
    })
  })

  return (
    <>
      <Card>
        <Card.Header>Volunteer Request</Card.Header>
        <Card.Body>
          <div className='d-flex justify-content-between'>
            <div className=''>
              <p className="text-muted mb-3">Total Request :<code>{count<10?'0'+count:count}</code></p>
            </div>
          </div>
          <MDBDataTable
            striped
            bordered
            small
            data={data}
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default Vender_query;