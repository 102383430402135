import Form from 'react-bootstrap/Form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MDBFile } from 'mdb-react-ui-kit';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Api from '../Auth/Auth';
const EditBanner = () => {
    const urlredirect = useNavigate();
    const url = useParams();
    const [menulist, setMenu] = useState([]);
    const [pagedata, setPagedata] = useState([{ page: "", Heading: "", Content: "", status: "" }])
    const getvalue = (e) => setPagedata({ ...pagedata, [e.currentTarget.name]: e.currentTarget.value });
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    useEffect(() => {
        Api.get("/editbanner-view?sno=" + url.sno).then(function (response) {
            setPagedata(response.data.data[0])
        }).catch(function (err) {
            console.log(err);
        })
        Api.get("/menu-list").then(function (response) {
            if (response.status === 200 && response.data.status === 'success') {
                setMenu(response.data.data);
            }
        }).catch(function (err) {
            console.log(err);
        })
    }, []);
    const updateBaner = (b) => {
        b.preventDefault();
        if (b.currentTarget.title.value != '') {
            var img = b.currentTarget.image.files;
            const formData = new FormData();
            formData.append('image', img[0]);
            formData.append('sno', url.sno);
            formData.append('title', pagedata.page);
            formData.append('heading', pagedata.Heading);
            formData.append('content', pagedata.Content);
            formData.append('status', pagedata.status);
            fetch(process.env.REACT_APP_API_URL + '/update-banner', {
                method: 'POST',
                body: formData
            }).then(response => response.json())
                .then(data => {
                    if (data['status'] == 'success') {
                        toast_alert('Banner Succussfully up to date.', 0);
                        urlredirect('/admin/banner');
                    } else {
                        toast_alert(data['status'], 1);
                    }
                }).catch((error) => {
                    toast_alert('Somthing a worng...!!', 1);
                });
        } else {
            if (b.currentTarget.title.value == '') { toast_alert('Please select page title...', 1); return false; }
        }
    }
    return (<>
        <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className='d-flex justify-content-between'>
                            <div className=''>
                                <h6 className="card-title"> Edit banner</h6>
                                <p className="text-muted mb-3"><Link to="/admin/banner">Banner</Link> /<code>Edit banner</code></p>
                            </div>
                            <div>
                                <Link to="/admin/banner"><button className='custom-btn'> Banner List</button></Link>
                            </div>
                        </div>
                        <div className="table-responsive pt-3">
                            <Form onSubmit={updateBaner}>
                                <Form.Group className="mb-3" controlId="img">
                                    <MDBFile label='Image' id='image' />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="status">
                                    <Form.Label htmlFor="title">Page Title *</Form.Label>
                                    <Form.Select id="title" name="page" onChange={getvalue}>
                                        <option>Select Page</option>
                                        {
                                            menulist.map((menu) => {
                                                return (<option defailtS={(pagedata?.page).toLowerCase()===(menu?.name).toLowerCase()?true:false}>{menu.name}</option>)
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="Headding">
                                    <Form.Label>Headding</Form.Label>
                                    <Form.Control type="text" value={pagedata.Heading} placeholder="Headding" id="head" name="Heading" onInput={getvalue} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="Content">
                                    <Form.Label id="contsrs">Content</Form.Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={pagedata.Content}
                                        id={'conent'}
                                        onChange={(event, editor) => {
                                            setPagedata({ ...pagedata, 'Content': editor.getData() });
                                        }}
                                    />
                                    <Form.Group className="mb-3" controlId="status">
                                        <Form.Label htmlFor="status">Status</Form.Label>
                                        <Form.Select id="status" name="status" onChange={getvalue}>
                                            <option value="0" selected={pagedata.status === 0 ? true : false}>Inactive</option>
                                            <option value="1" selected={pagedata.status === 1 ? true : false}>Active</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="mt-3" controlId="description">
                                        <input type="submit" className="btn btn-success" value='Save Details' />
                                    </Form.Group>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default EditBanner;