import Api from './../Auth/Auth';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';
const Blog_news = () => {
    const [blog,setBlog] = useState();
    const [banner,setBanner] = useState();
    useEffect(()=>{
        Api.get('/blog').then((response)=>{
            if(response.status===200 && response.data.status==='success'){
                setBlog(response.data.data.blog);
                setBanner(response.data.data.banner[0]);
            }else{
                console.log('Api Error'+response.status);
            }
        }).catch((error)=>{
            console.log(error);
        })
    },[])
    return (
        <>
            <div className="breadcrumb-area" style={{backgroundImage: "url("+process.env.REACT_APP_IMG_BASE_URL+"/img/"+banner?.image+")"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner">
                                <div className="icon">
                                    <img src="/main/files/static/green-hart.png" />
                                </div>
                                <h2 className="page-title">Blog</h2>
                                <ul className="page-list">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Blog </Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="blog-area bg-image padding-top-120 padding-bottom-40"
                style={{backgroundImage: "url(/main/files/static/bg_trans.png)"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title desktop-center margin-bottom-50">
                                <span>Latest Blog</span>
                                <h3 className="title">Check latest <span>Blogs</span></h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            blog && blog.map((blg)=>{
                                return(<div className="col-lg-6 col-xl-4">
                                <div className="single-blog-grid-01 margin-bottom-30">
                                    <div className="thumb">
                                        <img src={process.env.REACT_APP_IMG_BASE_URL+"/blogs/"+blg.image} alt="blog image"/>
                                    </div>
                                    <div className="content-wrapper">
                                        <div className="news-date">
                                            <h5 className="title"><Moment format="DD">{blg.last_modify}</Moment></h5>
                                            <span><Moment format="MMMM">{blg.last_modify}</Moment></span>
                                        </div>
                                        <div className="content">
                                            <ul className="post-meta">
                                                <li><Link to="#">By <span>Mnb foundation</span></Link></li>
                                                {/* <li><Link to="#">Comments<span>(03)</span></Link></li> */}
                                            </ul>
                                            <h4 className="title"><Link to={"/blog/"+blg.title}><div className="Container" dangerouslySetInnerHTML={{__html: blg.title}}></div></Link></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    )
}
export default Blog_news;