import Form from 'react-bootstrap/Form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastContainer, toast } from 'react-toastify';
import Api from '../Auth/Auth';
import React, { useState } from 'react';
const Sendmail = () => {
    const [loader, setLoader] = useState(false);
    const [maildata, setMaildata] = useState({ to: "", subject: "", message: "" });
    const getfromdata = (e) => setMaildata({ ...maildata, [e.currentTarget.id]: e.currentTarget.value })
    const SendMail = (e) => {
        e.preventDefault();
        setLoader(true);
        Api.post('/sendmail', maildata).then((res) => {
            if (res.status === 200 && res.data.status === 'success') {
                toast('Mail sent successfully');
            } else {
                toast('Something went wrong...!!');
            }
            setLoader(false);
        }).catch((err) => {
            toast('Try again..!!');
            setLoader(false);
        })
    }
    return (<>
        <div className="row">
            <ToastContainer />
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className='d-flex justify-content-between'>
                            <div className=''>
                                <h6 className="card-title"> Mail</h6>
                                <p className="text-muted mb-3">Send Mail</p>
                            </div>
                        </div>
                        <div className="table-responsive pt-3">
                            <Form onSubmit={SendMail}>
                                <Form.Group className="mb-3" controlId="Email">
                                    <Form.Label>To</Form.Label>
                                    <Form.Control id="to" type="email" placeholder="Email" onInput={getfromdata} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="subject">
                                    <Form.Label>Subject</Form.Label>
                                    <Form.Control id="subject" type="text" placeholder="Subject" onInput={getfromdata} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="content">
                                    <Form.Label>content</Form.Label>
                                    <CKEditor id="cont"
                                        editor={ClassicEditor}
                                        data=""
                                        onChange={(event, editor) => {
                                            setMaildata({ ...maildata, message: editor.getData() })
                                        }}
                                    />
                                    <Form.Group className="mt-3" controlId="description">
                                        {
                                            loader ?
                                                <button class="btn btn-primary" type="button" disabled>
                                                    <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                    <span role="status"> Sending...</span>
                                                </button>
                                                :
                                                <input type="submit" className="btn btn-success" value="Send Mail"></input>
                                        }
                                    </Form.Group>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default Sendmail;