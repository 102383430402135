import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Api from '../Auth/Auth';
import 'mdbreact/dist/css/mdb.css';
import Moment from 'react-moment';
import 'moment-timezone';
const Subscriber = () => {
  const data = {
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Date',
        field: 'date',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 200
      }
    ],
    rows: [{}]
  };
  const [users, setUsers] = useState([{ data: { sno: '1' } }]);
  const getdata = async () => {
    Api.get("/view_subscriber").then(function (response) {
      setUsers(response.data.data);
    }).catch(function (err) {
      console.log(err);
    })
  }
  useEffect(() => {
    getdata();
  }, [users]);
  users && users.map((inf, i) => {
    var act;
    if(users[i].status==1){ act='<div className="bg-success text-white rounded-pill text-center">Active</div>' }else{ act='<div className="bg-warning text-white rounded-pill text-center">Inactive</div>'; }
    data.rows.push({
      id: i + 1,
      email: users[i].email,
      date: <Moment format="DD/MMMM/YYYY hh:mm:ss">{users[i].date}</Moment>,
      status:  <div className="Container" dangerouslySetInnerHTML={{__html: act}}></div>
    })
  })
  return (
    <>
      <Card>
        <Card.Header>Subscriber</Card.Header>
        <Card.Body>
          <div classemail='d-flex justify-content-between'>
            <div classemail=''>
              <p className="text-muted mb-3">Total Subscriber :<code>{users.length<10?'0'+users.length:users.length}</code></p>
            </div>
          </div>
          <MDBDataTable
            striped
            bordered
            small
            data={data}
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default Subscriber;