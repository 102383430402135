import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import 'moment-timezone';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { MDBBtn } from 'mdb-react-ui-kit';
import { toast } from 'react-toastify';
import Api from './../Auth/Auth';
const Event_booking = () => {
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const url = useParams();
    const [events, setEvents] = useState({ banner: [{ image: { image: '' } }], event: [{ sno: '', image: '', title: '', start_time: '', end_time: '', location: '', charges: '', content: '', map_url: '', organizer: '' }], info: [{ phone: '' }] });
    useEffect(() => {
        Api.get('/event-datails?title=' + url.id).then((response) => {
            if (response.status === 200 && response.data.status === 'success') {
                setEvents(response.data.data);
            } else {
                console.log("Api error: " + response.status);
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [])
    const booking = (tr_id) => {
        fetch(process.env.REACT_APP_API_URL + "/event_bokking", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({
                event_id: events?.event[0].sno,
                transaction_id: tr_id,
                name: document.getElementById('name').value,
                dob: document.getElementById('dob').value,
                phone: document.getElementById('phone').value,
                email: document.getElementById('email').value,
                doc: document.getElementById('doc').value
            })
        }).then(response => response.json()).then(data => {
            if (data.status == 'success') {
                toast_alert('Donation Successs...', 0);
            } else {
                toast_alert(data.status, 1);
            }
        }).catch(error => {
            console.log(error);
        })
    }
    const bookingEvents = (e) => {
        e.preventDefault();
        if (e.currentTarget.name.value && e.currentTarget.dob.value && e.currentTarget.phone.value && e.currentTarget.email.value && e.currentTarget.doc.value) {
            if (!/^\(?([6-9]{1})\)?([0-9]{9})$/.test(e.currentTarget.phone.value)) {
                toast_alert("Enter valid phone no...!!", 1);
                return;
            }
            if (events?.event[0].charges === 0) {
                booking('');
            } else {
                toast_alert('payment soon...!!', 1);
            }
        }else{
            if(!e.currentTarget.name.value){ e.currentTarget.name.focus(); return; }
            if(!e.currentTarget.dob.value){ e.currentTarget.dob.focus(); return; }
            if(!e.currentTarget.phone.value){ e.currentTarget.phone.focus(); return; }
            if(!e.currentTarget.email.value){ e.currentTarget.email.focus(); return; }
            if(!e.currentTarget.doc.value){ e.currentTarget.doc.focus(); return; }
        }
    }
    const phones = (e) => {
        if (e.target.value.length > 10) {
            e.target.value = e.target.value.slice(0, 10);
        }
    }
    return (
        <>
            <div className="breadcrumb-area" style={{ backgroundImage: "url(" + process.env.REACT_APP_IMG_BASE_URL + "/img/" + events?.banner[0]?.image + ")" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner">

                                <h2 className="page-title">Event Booking</h2>
                                <ul className="page-list">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Event Booking</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-content our-attoryney padding-bottom-120 padding-top-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="widget-area">
                                <div className="widget widget_event style-01">
                                    <h3 className="widget-title style-02">Event Details</h3>
                                    <ul>
                                        <li><Link to="#">Price :<span>{events?.event[0]?.charges === 0 ? 'Free' : <span className="Container" dangerouslySetInnerHTML={{ __html: '&#8377; ' + events?.event[0]?.charges }}></span>}</span></Link></li>
                                        <li><Link to="#">Date : <span> <Moment format="DD-MMMM">{events?.event[0]?.start_time}</Moment> - <Moment format="DD-MMMM">{events?.event[0]?.end_time}</Moment></span></Link></li>
                                        <li><Link to="#">Duration : <span> <Moment format="hh:mm A">{events?.event[0]?.start_time}</Moment> - <Moment format="hh:mm A">{events?.event[0]?.end_time}</Moment> </span></Link></li>
                                        <li><Link to="#">Organizer : <span> {events?.event[0]?.organizer}</span></Link></li>
                                        <li><Link to={"tel:" + events?.info[0]?.phone}>Contact : <span> {events?.info[0]?.phone} </span></Link></li>
                                        <div className="d-grid gap-2">
                                            <Link to={"/event/booking/" + events?.event[0]?.title}><button to="" style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '130px',
                                                border: 'none',
                                                outline: 'none',
                                                background: '#96BE0F',
                                                color: '#FFF',
                                                height: '40px',
                                                marginTop: '20px',
                                            }}>Booking</button></Link>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="widget-area">
                                <div className="widget widget_event style-01 px-4 py-4">
                                    <Form onSubmit={bookingEvents}>
                                        <Row className='mb-2'>
                                            <Form.Label className='mb-0'>Name</Form.Label>
                                            <InputGroup>
                                                <Form.Control type="text" autoCapitalize='off' id="name" aria-describedby="basic-addon1" />
                                            </InputGroup>
                                        </Row>
                                        <Row className='mb-2'>
                                            <Form.Label className='mb-0'>Date of Birth</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text id="basic-addon1">DOB</InputGroup.Text>
                                                <Form.Control type="date" autoCapitalize='off' id="dob" aria-describedby="basic-addon1" max={new Date().toISOString().split("T")[0]}/>
                                            </InputGroup>
                                        </Row>
                                        <Row className='mb-2'>
                                            <Form.Label className='mb-0'>Phone</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
                                                <Form.Control type="number" autoCapitalize='off' id="phone" aria-describedby="basic-addon1"onInput={phones} />
                                            </InputGroup>
                                        </Row>
                                        <Row className='mb-2'>
                                            <Form.Label className='mb-0'>Email</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                                <Form.Control type="email" autoCapitalize='off' id="email" aria-describedby="basic-addon1" />
                                            </InputGroup>
                                        </Row>
                                        <Row className='mb-4'>
                                            <Form.Label className='mb-0'>Document No</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text id="basic-addon1">DOC</InputGroup.Text>
                                                <Form.Control type="text" placeholder="Aadhar/Pen"autoCapitalize='off' id="doc" aria-describedby="basic-addon1" />
                                            </InputGroup>
                                        </Row>
                                        <div>
                                            <Link to="/events"><button className='dnt-back'>Back</button></Link>
                                            <MDBBtn className='me-1 donate-continue'> Continue </MDBBtn>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default Event_booking;