import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { MDBDataTable } from 'mdbreact';
import 'mdbreact/dist/css/mdb.css';
import Api from '../Auth/Auth';
import Moment from 'react-moment';
import 'moment-timezone';
import { Button, Dropdown, Space } from 'antd';
import { Link } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import { AiFillDelete } from 'react-icons/ai';
import { AiOutlineTool } from 'react-icons/ai';
import { AiOutlineEye } from 'react-icons/ai';
import { toast } from 'react-toastify';
const EventsList = () => {
  const [event, getEvent] = useState([]);
  const toast_alert = (sms, status) => {
    if (status == 0) {
      toast.success(sms, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(sms, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  const eventList = () => {
    Api.get("/eventlist").then(function (response) {
      if (response.data.status == 'success') {
        getEvent(response.data.data);
      }
    }).catch(function (err) {
      console.log(err);
    })
  }
  const Deletepage = (e) => {
    fetch(process.env.REACT_APP_API_URL + '/del', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', },
      body: JSON.stringify({
        table: 'event',
        sno: e.target.id
      })
    }).then(response => response.json())
      .then(data => {
        if (data['status'] == 'success') {
          toast_alert('Menu Succussfully deleted.', 0);
          eventList();
        } else {
          toast_alert(data['status'], 1);
        }
      }).catch((error) => {
        toast_alert('something went wrong', 1);
      })
  }
  const data = {
    columns: [
      {
        label: '#',
        field: 'sno',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Event',
        field: 'enevt',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Booking',
        field: 'booking',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Date',
        field: 'date',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'asc',
        width: 200
      }
    ],
    rows: []
  };
  useEffect(() => {
    eventList();
  }, []);
  event && event.map((inf, i) => {
    const items = [
      {
        key: '1',
        label: (
          <Link rel="noopener noreferrer" to={"edit/" + event[i].sno}><BiEdit /> Edit</Link>
        ),
      },
      {
        key: '2',
        label: (
          <Link rel="noopener noreferrer" to={"booking/"+event[i].sno} ><AiOutlineEye/> Details</Link>
        ),
      },
      {
        key: '3',
        label: (
          <Link rel="noopener noreferrer" id={event[i].sno} onClick={Deletepage}><AiFillDelete /> Delete</Link>
        ),
      },
    ];
    data.rows.push({
      sno: i + 1,
      enevt: event[i].title,
      booking: '1',
      status: event[i].status==1? 'Active':'Inactive',
      booking: event[i].booking,
      date: <Moment format="DD/MMMM/YYYY, hh:mmA">{event[i].start_time}</Moment>,
      action: <Space direction="vertical">
        <Space wrap>
          <Dropdown
            menu={{
              items,
            }}
            placement="bottom"
          >
            <Button><AiOutlineTool /> Action</Button>
          </Dropdown>
        </Space>
      </Space>
    })
  })

  return (
    <>
      <Card>
        <Card.Header>Event List</Card.Header>
        <Card.Body>
          <div className='d-flex justify-content-between'>
            <div className=''>
              <p className="text-muted mb-3">Total Event :<code>{event.length < 10 ? '0' + event.length : event.length}</code></p>
            </div>
            <div>
              <Link to="/admin/event/add"><button className='custom-btn'> Add Event</button></Link>
            </div>
          </div>

          <MDBDataTable
            striped
            bordered
            small
            data={data}
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default EventsList;