import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from "react";
import Api from '../Auth/Auth';
const Info = () => {
    const [address,setAddress] = useState([]);
    const [aboutFooter,setaboutFooter] = useState([]);
    const [copyright,setcopyright] = useState([]);
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    useEffect(()=>{
        Api.get('/header').then((respponse)=>{
            if(respponse.status===200){
                setAddress(respponse.data.info[0].address);
                setaboutFooter(respponse.data.info[0].small_about);
                setcopyright(respponse.data.info[0].copyright);
                document.getElementById('others').value=respponse.data.info[0].other;
                document.getElementById("mapurl").value=respponse.data.info[0].location;
                document.getElementById('phone').value=respponse.data.info[0].phone;
                document.getElementById('email').value=respponse.data.info[0].email;
                document.getElementById('title').value=respponse.data.info[0].title;
                document.getElementById('copyphoneright').value=respponse.data.info[0].copyright;
            }else{
                console.log('Api Error');
            }
        }).catch((error)=>{
            console.log(error);
        })
    },[])
    const updateInfo = (e) =>{
        e.preventDefault();
        var headerlogo = e.target.headerlogo.files;
        var footerlogo = e.target.footerlogo.files;
        var favicon = e.target.faviconicon.files;
            const formData = new FormData();
            formData.append('header_logo', headerlogo[0]);
            formData.append('footer_logo', footerlogo[0]);
            formData.append('favicon_icon', favicon[0]);
            formData.append('title', e.target.title.value);
            formData.append('phone', e.target.phone.value);
            formData.append('email', e.target.email.value);
            formData.append('copyright', copyright);
            formData.append('address', address);
            formData.append('footer_abouts', aboutFooter);
            formData.append('map', e.target.mapurl.value);
            formData.append('other', '');
            fetch(process.env.REACT_APP_API_URL + '/application', {
                method: 'POST',
                body: formData
            }).then(response => response.json())
                .then(data => {
                    if (data['status'] == 'success') {
                        toast_alert('Banner Succussfully up to date...', 0);
                    } else {
                        toast_alert('Try agine...!!', 1);
                    }
                }).catch((error) => {
                    console.log(error);
                });


    }
    return (<>
        <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className='d-flex justify-content-between'>
                            <div className=''>

                                <h6 className="card-title"> App Info</h6>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <Form onSubmit={updateInfo}>
                                <Form.Group controlId="headerlogo" className="mb-3">
                                    <Form.Label>Header Logo</Form.Label>
                                    <Form.Control type="file" id="headerlogo" />
                                </Form.Group>
                                <Form.Group controlId="footerlogo" className="mb-3">
                                    <Form.Label>Footer Logo</Form.Label>
                                    <Form.Control type="file"id="footerlogo"/>
                                </Form.Group>
                                <Form.Group controlId="faviconicon" className="mb-3">
                                    <Form.Label>Favicon</Form.Label>
                                    <Form.Control type="file"id="faviconicon" />
                                </Form.Group>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control type="tel" placeholder="Phone" id="phone" name="phone" autocomplete="off" required />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="Email" id="email" name="email" autocomplete="off" required />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label>App Title</Form.Label>
                                        <Form.Control type="tel" placeholder="App Title" id="title" name="title" autocomplete="off" required />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <Form.Label>Other</Form.Label>
                                        <Form.Control type="text"id="others" />
                                    </Form.Group>
                                </Row>
                                <Form.Group className="mb-3" controlId="copyrught">
                                    <Form.Label>Copyright Message</Form.Label>
                                    <CKEditor id="cont"
                                        editor={ClassicEditor}
                                        data={copyright}
                                        onChange={(event, editor) => {
                                            setcopyright(editor.getData());
                                            const data = editor.getData();
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="address">
                                    <Form.Label>Address</Form.Label>
                                    <CKEditor id="cont"
                                        editor={ClassicEditor}
                                        data={address}
                                        onChange={(event, editor) => {
                                            setAddress(editor.getData());
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="footerad">
                                    <Form.Label>Footer About</Form.Label>
                                    <CKEditor id="cont"
                                        editor={ClassicEditor}
                                        data={aboutFooter}
                                        onChange={(event, editor) => {
                                            setaboutFooter(editor.getData());
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group controlId="mapurl" className="mb-3">
                                    <Form.Label>Map Url</Form.Label>
                                    <Form.Control type="text" id="mapurl" />
                                </Form.Group>
                                <Form.Group className="mb-3" id="rememberme">
                                    <Form.Check type="checkbox" label="Remember me" required />
                                </Form.Group>
                                <Button variant="primary" type="submit">Submit</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default Info;