import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { GrAdd } from 'react-icons/gr';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
const New_Event = () => {
    const [limt_min, setTimeMin] = useState();
    const [limt_max, setTimeMax] = useState();
    const urlredirect = useNavigate();
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const [conent, getContent] = useState();
    const AddEvents = (e) => {
        e.preventDefault();
        if (e.currentTarget.image.value != '' && e.currentTarget.title.value != '' && e.currentTarget.event_location.value != '' && e.currentTarget.form.value != '' && e.currentTarget.to.value != '' && conent != '') {
            var img = e.currentTarget.image.files;
            const formData = new FormData();
            formData.append('image', img[0]);
            formData.append('title', e.currentTarget.title.value);
            formData.append('event_location', e.currentTarget.event_location.value);
            formData.append('map_url', e.currentTarget.map_url.value);
            formData.append('start_time', e.currentTarget.form.value);
            formData.append('end_time', e.currentTarget.to.value);
            formData.append('total_sheet', e.currentTarget.sheet.value);
            formData.append('event_charege', e.currentTarget.charges.value);
            formData.append('content', conent);
            formData.append('organizer', e.currentTarget.organizer.value);
            formData.append('status', e.currentTarget.status.value);
            fetch(process.env.REACT_APP_API_URL + '/newevent', {
                method: 'POST',
                body: formData
            }).then(response => response.json())
                .then(data => {
                    if (data['status'] === 'success') {
                        toast_alert('Event succussfully Add.', 0);
                        urlredirect('/admin/event');
                    } else {
                        toast_alert('Somthing a worng...!!', 1)
                    }
                }).catch((error) => {
                    console.log(error);
                });
        } else {
            if (e.currentTarget.image.value == '') { toast_alert('Please select image...!!', 1); return; }
            if (e.currentTarget.title.value == '') { toast_alert('Please enter title...!!', 1); return; }
            if (e.currentTarget.event_location.value == '') { toast_alert('Please enter event location...!!', 1); return; }
            if (e.currentTarget.form.value == '') { toast_alert('Select event start time...!!', 1); return; }
            if (e.currentTarget.to.value == '') { toast_alert('Select event end time...!!', 1); return; }
            if (conent == '') { toast_alert('Enter event About...!!', 1); return; }

        }
    }
    useEffect(()=>{
        var date = new Date();
        var m = parseInt(date.getMonth() + 1)<10?'0'+parseInt(date.getMonth() + 1):parseInt(date.getMonth() + 1);
        setTimeMin(date.getFullYear()+'-'+m+'-'+parseInt(date.getDate()+1)+'T'+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds());
        setTimeMax(parseInt(date.getFullYear()+1)+'-'+m+'-'+parseInt(date.getDate()+1)+'T'+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds());
    },[1])
    return (<>
        <Card>
            <Card.Header>Add Event</Card.Header>
            <Card.Body>
                <div className='d-flex justify-content-between'>
                    <div className=''>
                        <p className="text-muted mb-3"><GrAdd /> Add New</p>
                    </div>
                    <div>
                        <Link to="/admin/event"><button className='custom-btn'>Event List</button></Link>
                    </div>
                </div>
                <Form onSubmit={AddEvents}>
                    <Form.Group controlId="faviconicon" className="mb-3">
                        <Form.Label>Image</Form.Label>
                        <Form.Control type="file" id="image" accept="image/*" required />
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} >
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Title" id="title" autocomplete="off" required />
                        </Form.Group>

                        <Form.Group as={Col} >
                            <Form.Label>Location</Form.Label>
                            <Form.Control type="text" placeholder="Location" id="event_location" autocomplete="off" required />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Form</Form.Label>
                            <Form.Control type="datetime-local" id="form"  />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>To </Form.Label>
                            <Form.Control type="datetime-local" id="to" />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} >
                            <Form.Label>Total Sheet</Form.Label>
                            <Form.Control type="number" placeholder="Sheet" id="sheet" autocomplete="off" required />
                        </Form.Group>

                        <Form.Group as={Col} >
                            <Form.Label>Charges</Form.Label>
                            <Form.Control type="number" placeholder="charges" id="charges" autocomplete="off" required />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} >
                            <Form.Label>Event Organizer</Form.Label>
                            <Form.Control type="text" placeholder="Event Organizer" id="organizer" autocomplete="off" required />
                        </Form.Group>
                        <Form.Group as={Col} >
                            <Form.Label htmlFor="status">Status</Form.Label>
                            <Form.Select id="status">
                                <option value="0">Inactive</option>
                                <option value="1">Active</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3">
                        <Form.Label>Event about</Form.Label>
                        <CKEditor id="cont"
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                                getContent(editor.getData());
                            }}
                        />
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} >
                            <Form.Label>Location map url</Form.Label>
                            <Form.Control type="url" placeholder="Map url" id="map_url" autocomplete="off" required />
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3">
                        <Form.Check type="checkbox" label="Remember me" />
                    </Form.Group>
                    <input type="Submit" className='custom-btn' />
                </Form>
            </Card.Body>
        </Card>
    </>)
}
export default New_Event;