import { Card, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BiRupee } from 'react-icons/bi';
import Api from '../Auth/Auth';
const Donation_Grid = () => {
    var amt=0;
    const [user, getUser] = useState([]);
    useEffect(() => {
        Api.get('/donation_grid').then((responce) => {
            if (responce.data.status == 'success') {
                getUser(responce.data.data);
            }
        }).catch((error) => {
            console.error(error);
        });
    }, []);
    user && user.map((amiounts)=>{
        amt = amt + amiounts.amount;
    })
    return (<>
        <div className='d-flex justify-content-between mb-3'>
            <div className=''>
                <p className="text-muted mb-3">Total Amount :<code><BiRupee />{amt}.00</code>,
                    <span> Donation: <code>{user.length < 10 ? '0' + user.length : user.length}</code></span>
                </p>
            </div>
            <div>
                <Link to="/admin/donation-list"><button className='custom-btn'>Donation List</button></Link>
            </div>
        </div>
        <Row gutter={16}>
            {
                user && user.map((inf) => {
                    return (<>
                        <Col span={8}  className='mb-3'>
                            <Card title={inf.name} bordered={false}>
                                <div>{inf.phone}</div>
                                <div>{inf.email}</div>
                                <div>Amount : <BiRupee/>{inf.amount},&nbsp; &nbsp;  Count : {inf.num}</div>
                            </Card>
                        </Col>
                    </>)
                })
            }
        </Row>
    </>)
}
export default Donation_Grid;