import axios from 'axios';
const WhitegoldApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json', },
    headers: { "x-auth-token": sessionStorage.getItem("mnbfoundation") }
});
WhitegoldApi.interceptors.request.use((config) => {
    config.headers["x-auth-token"] = sessionStorage.getItem("mnbfoundation");
    return config;
}, (error) => {
    return Promise.reject(error);
});
WhitegoldApi.interceptors.response.use((response) => {
    if (response.status === 200 && response.data.Status === 3) {
        //sessionStorage.clear();
    }
    if (response.status === 401) { sessionStorage.clear(); }
    return response;
}, (error) => {
    if (error.response.status === 401) {
        sessionStorage.clear();
    }
    return Promise.reject(error);
}
);
export default WhitegoldApi;
