import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiPhoneCall } from 'react-icons/fi';
import { FiSend } from 'react-icons/fi';
import axios from 'axios';
import Api from './../Auth/Auth';
let Footer = () => {
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const [ftr, setData] = useState([]);
    const fetchUsers = async () => {
        Api.get('/footer').then(response => {
            if(response.status==200 && response.data.status==='success'){
                setData(response.data.data[0]);
            }else{
                console.log('Apo Error: ' + response.status , response.data.status);
            }
        }).catch(err => {
            console.log(err);
        })
    }
    useEffect(() => {
        fetchUsers();
    }, []);
    let handleSubmit = async (e) => {
        e.preventDefault();
        var email = e.currentTarget.elements.subscribe.value;
        var rule = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
        if (!email) {
            toast_alert('please enter email...!!', 1);
        } else if (!rule.test(email)) {
            toast_alert('invalid email address...!!', 1);
        } else {
            fetch(process.env.REACT_APP_API_URL + "/subscriber", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({
                    email: email
                })
            }).then(response => response.json()).then(data => {
                if (data.status == 'success') {
                    toast_alert('Successfully Subscribed.', 0);
                } else {
                    toast_alert(data.status, 1);
                }
            })
        }
    }
    return (<>
        <section>
            
            <footer className="footer-area">
                <div className="footer-top bg-black bg-image padding-top-85 padding-bottom-50" style={{ backgroundImage: "url(/main/files/static/footer-bg.png)" }}>
                    <div className="container">
                        <div className="footer-top-border">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="footer-widget widget">
                                        <div className="about_us_widget">
                                            <Link to="" className="footer-logo"> <img src={process.env.REACT_APP_IMG_BASE_URL + '/img/' + ftr?.footer_logo} alt="footer logo" /></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="footer-widget widget widget_subscribe">
                                        <h4 className="widget-title">Subscribe Newsletter</h4>
                                        <form className="subscribe-form d-flex" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Your email address" id="subscribe" />
                                            </div>
                                            <button type="submit" className="submit-btn subscribe-btn"><FiSend /></button>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="footer-widget widget">
                                        <ul className="contact_info_list">
                                            <li className="single-info-item-02">
                                                <div className="icon">
                                                    <FiPhoneCall />
                                                </div>
                                                <div className="details">
                                                    {ftr?.phone}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="footer-widget widget ">
                                    <h4 className="widget-title">About Me</h4>
                                    <div className="Container" dangerouslySetInnerHTML={{ __html: ftr?.small_about }}></div>
                                </div>
                            </div>
                            <div className="col-lg-2 offset-lg-1 col-md-6 col-sm-6">
                                <div className="footer-widget widget widget_nav_menu">
                                    <h4 className="widget-title">Quick Link</h4>
                                    <ul>
                                        <li><NavLink to="about-us">About Us</NavLink></li>
                                        <li><NavLink to="our-work">Our Work</NavLink></li>
                                        <li><NavLink to="donation">Donation</NavLink></li>
                                        <li><NavLink to="blog/">Blog</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="footer-widget widget widget_nav_menu">
                                    <h4 className="widget-title">OUR WORK</h4>
                                    <ul>
                                        <li><NavLink to="volunteer/">Become a Volunteer</NavLink></li>
                                        <li><NavLink to="terms-and-conditions">Terms & Conditions</NavLink></li>
                                        <li><NavLink to="privacy-policy">Privacy & Policy</NavLink></li>
                                        <li><NavLink to="refund-cancellation">Refund & Cancellation</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <ul className="contact_info_list">
                                    <li className="single-info-item">
                                        <div className="title">
                                            Our Address
                                        </div>
                                        <div className="details d-flex">
                                            <div className="Container" dangerouslySetInnerHTML={{ __html: ftr?.address }}></div>
                                        </div>
                                    </li>
                                    <li className="single-info-item">
                                        <div className="title">
                                            Contact us
                                        </div>
                                        <div className="details">
                                            <Link to={'tel:' + ftr?.phone} className='details'>{ftr?.phone}</Link>
                                            <br />
                                            <Link to={'tel:' + ftr?.email} className='details'>{ftr?.email}</Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright-area-inner">
                                    <div className="Container" dangerouslySetInnerHTML={{ __html: ftr?.copyright }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="back-to-top">
                <span className="back-top"><i className="fa fa-angle-up"></i></span>
            </div>
        </section>
    </>)
}
export default Footer;