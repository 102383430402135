import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from "react";
import Api from '../Auth/Auth';
const CountDwon = () => {
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    useEffect(()=>{
        Api.get('/counter').then((respponse)=>{
            if(respponse.status===200){
                if(respponse.data.status=='success'){
                    document.getElementById('title0').value=respponse.data.data[0].title;
                    document.getElementById('num0').value=respponse.data.data[0].count;
                    document.getElementById('title1').value=respponse.data.data[1].title;
                    document.getElementById('num1').value=respponse.data.data[1].count;
                    document.getElementById('title2').value=respponse.data.data[2].title;
                    document.getElementById('num2').value=respponse.data.data[2].count;
                }else{
                    console.log('Error: '+respponse.status);
                }
            }else{
                console.log('Api Error');
            }
        }).catch((error)=>{
            console.log(error);
        })
    },[])
    const UpdateCounter = (e) =>{
        e.preventDefault();
        for(let i=0;i<e.target.title.length;i++){
            if(e.target.title[i].value !=''){
                fetch(process.env.REACT_APP_API_URL+'/counter', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', },
                    body: JSON.stringify({
                        sno: i+1,
                        title: e.target.title[i].value,
                        num: e.target.num[i].value
                    })
                }).then(response => response.json())
                .catch((error) => {
                    console.log(error);
                });
            }
        }
        toast_alert('Successfully Update....', 0);
    }
    return (<>
        <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className='d-flex justify-content-between'>
                            <div className=''>
                                <h6 className="card-title"> Counter</h6>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <Form onSubmit={UpdateCounter}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Title : 1</Form.Label>
                                        <Form.Control type="title" placeholder="Title"name="title" id="title0"autocomplete="off" required />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>No. </Form.Label>
                                        <Form.Control type="text" placeholder="Number"name="num" id="num0"autocomplete="off" required />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Title: 2</Form.Label>
                                        <Form.Control type="title" placeholder="Title"name="title" id="title1"autocomplete="off" required />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>No. </Form.Label>
                                        <Form.Control type="text" placeholder="Number"name="num" id="num1"autocomplete="off" required />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Title: 3</Form.Label>
                                        <Form.Control type="title" placeholder="Title"name="title" id="title2"autocomplete="off" required />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>No.</Form.Label>
                                        <Form.Control type="text" placeholder="Number"name="num" id="num2"autocomplete="off" required />
                                    </Form.Group>
                                </Row>
                                <Button variant="primary" type="submit">Submit</Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default CountDwon;