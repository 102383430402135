import Form from 'react-bootstrap/Form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
const AddBlog = () => {
    const [contents, getContent] = useState([]);
    const urlredirect = useNavigate();
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    let handleSubmit = async (e) => {
        e.preventDefault();
        if (e.currentTarget.img.value!='' && e.currentTarget.title.value!='' && contents!='') {
            var img = e.currentTarget.img.files;
            const formData = new FormData();
            formData.append('image', img[0]);
            formData.append('title', e.currentTarget.title.value);
            formData.append('status', e.currentTarget.currnrt_status.value);
            formData.append('content',contents);
            fetch(process.env.REACT_APP_API_URL+'/add_blog', {
                method: 'POST',
                body: formData
            }).then(response => response.json())
                .then(data => {
                    if (data['status'] == 'success') {
                        toast_alert('Blog Succussfully added.', 0);
                        urlredirect('/admin/blog');
                    } else {
                        toast_alert(data['status'], 1);
                    }
                }).catch((error) => {
                    console.log(error);
                });
        }else{
            if(e.currentTarget.img.value==''){ toast_alert('Please select blog image...', 1); return false; }
            if(e.currentTarget.title.value==''){ toast_alert('Please enter title...', 1); return false; }
            if(contents==''){ toast_alert('Please enter content...', 1); return false; }
        }
    }
    return (<>
        <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className='d-flex justify-content-between'>
                        <div className=''>
                        <div className=''>
                                <h6 className="card-title">Blog</h6>
                                <p className="text-muted mb-3">Add Blog</p>
                            </div>
                        </div>
                        <div>
                        <Link to="/admin/blog"><button className='custom-btn'>Blog Lsit</button></Link>
                        </div>
                    </div>
                        <div className="table-responsive pt-3">
                            <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                    <Form.Label>Image</Form.Label>
                                    <Form.Control id="img" type="file" placeholder="Title" accept="image/*" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Add Title</Form.Label>
                                    <Form.Control id="title" type="text" placeholder="Title" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Content</Form.Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data=""
                                        onChange={(event, editor) => {
                                            getContent(editor.getData());
                                        }}
                                    />
                                    <Form.Group className="mb-3" controlId="status">
                                        <Form.Label htmlFor="status">Status</Form.Label>
                                        <Form.Select id="currnrt_status">
                                            <option value="0">Inactive</option>
                                            <option value="1">Active</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="mt-3" controlId="description">
                                        <input type='submit' className="btn btn-outline-primary" value="Add New" />
                                    </Form.Group>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default AddBlog;