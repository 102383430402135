import { Link } from "react-router-dom";
const MembershipSuccess = () => {
    return (<>
        <section className="thnk">
            <div className="cont">
                <div className="text-cnt">
                    <span className='checkicon'>
                        <div className="success-checkmark">
                            <div className="check-icon">
                                <span className="icon-line line-tip"></span>
                                <span className="icon-line line-long"></span>
                                <div className="sucess-circle"></div>
                                <div className="icon-fix"></div>
                            </div>
                        </div>
                    </span>
                    <h2>Success!</h2>
                    <div className="message">for contacting us, we will reply promptly once your message is received.</div>
                    <Link to="/">HOME PAGE</Link>
                </div>
            </div>
        </section>
    </>)
}
export default MembershipSuccess;