import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { MDBDataTable } from 'mdbreact';
import 'mdbreact/dist/css/mdb.css';
import Api from '../Auth/Auth';
import Moment from 'react-moment';
import 'moment-timezone';
import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
const Form = () => {
  const data = {
    columns: [
      {
        label: '#',
        field: 'sno',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Phone',
        field: 'phone',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Date',
        field: 'dt',
        sort: 'asc',
        width: 200
      },
      {
        label: 'View',
        field: 'view',
        sort: 'asc',
        width: 200
      }
    ],
    rows: [{}]
  };
  const [users, setUsers] = useState([{ data: { sno: '1' } }]);
  const getdata = async () => {
    Api.get("/form_details").then(function (response) {
        setUsers(response.data.data);
      }).catch(function (err) {
        console.log(err);
      })
  }
  useEffect(() => {
    getdata();
  }, [users]);
  users && users.map((inf,i) => {
    data.rows.push({
      sno: i+1,
      name: users[i].name,
      phone: users[i].phone,
      dt: <Moment format="DD/MMMM/YYYY">{users[i].date}</Moment>,
      view: <Link to={"/admin/form/"+users[i].sno}> <Badge bg="info"> View </Badge></Link>
    })
  })

  return (
    <>
      <Card>
        <Card.Header>Form</Card.Header>
        <Card.Body>
          <div className='d-flex justify-content-between'>
            <div className=''>
              <p className="text-muted mb-3">Total Request :<code>{users.length<10?'0'+users.length:users.length}</code></p>
            </div>
          </div>
          <MDBDataTable
            striped
            bordered
            small
            data={data}
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default Form;