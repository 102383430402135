import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Api from '../Auth/Auth';
function Submenu() {
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    let [info, setData] = useState({ data: [{ sno: '1', name: '' }] });
    const getApiData = async () => {
        Api.get("/menu-list").then(function (response) {
            setData(response.data);
        }).catch(function (err) {
            console.log(err);
        })
    };
    useEffect(() => {
        getApiData();
    }, []);
    const addsubmenu = (sub) => {
        sub.preventDefault();
        var name = sub.currentTarget.elements.name.value;
        var cate = sub.currentTarget.elements.preant.value;
        var url = sub.currentTarget.elements.url.value;
        var ststs = sub.currentTarget.elements.actstatus.value;
        if (name != '' && cate != '' && url != '') {
            fetch(process.env.REACT_APP_API_URL +'/menu', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({
                    name: name,
                    icon: '',
                    url: url,
                    categorie: cate,
                    ActiveStatus: ststs

                })
            }).then(response => response.json())
                .then(data => {
                    if (data['status'] == 'success') {
                        toast_alert('Menu Succussfully added.', 0);
                        getApiData();
                    } else {
                        toast_alert(data['status'], 1);
                    }
                }).catch((error) => {
                    toast_alert('something went wrong', 1);
                });
        } else {
            if (name == '') { toast_alert('Please enter manu name...!!', 1); return; }
            if (url == '') { toast_alert('Please enter manu url...!!', 1); return; }
            if (cate == '') { toast_alert('Please select manu category...!!', 1); return; }
        }
    }
    const Delete_menu = (m) => {
        fetch(process.env.REACT_APP_API_URL +'/del-menu', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({
                sno: m.currentTarget.id
            })
        }).then(response => response.json())
            .then(data => {
                if (data['status'] == 'success') {
                    toast_alert('Menu Succussfully deleted.', 0);
                    getApiData();
                } else {
                    toast_alert(data['status'], 1);
                }
            }).catch((error) => {
                toast_alert('something went wrong', 1);
            });
    }
    return (<>
        <Card>
            <Card.Header>Submenu</Card.Header>
        </Card>
        <div className='row mt-4'>
            <div className='col-lg-5 col-xl-5 grid-margin grid-margin-xl-0 stretch-card'>
                <Card>
                    <Card.Header>Add Submenu</Card.Header>
                    <Card.Body>
                        <Form onSubmit={addsubmenu}>
                            <fieldset>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="name">Submenu Name</Form.Label>
                                    <Form.Control id="name" placeholder="Name" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="url">Url</Form.Label>
                                    <Form.Control id="url" placeholder="Url" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="preant">Parent</Form.Label>
                                    <Form.Select id="preant">
                                        <option value=''>--Select Mainmenu --</option>
                                        {
                                            info && info.data.map((menu) => {
                                                if (menu.category == 0) {
                                                    return (<>
                                                        <option value={menu.sno}>{menu.name}</option>
                                                    </>)
                                                }
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label htmlFor="status">Status</Form.Label>
                                    <Form.Select id="actstatus">
                                        <option value={0}>Inactive</option>
                                        <option value={1}>Active</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        type="checkbox"
                                        id="disabledFieldsetCheck"
                                        label="Remember Me"
                                    />
                                </Form.Group>
                                <Button type="submit">Add Menu</Button>
                            </fieldset>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
            <div className='col-lg-5 col-xl-7 grid-margin grid-margin-xl-0 stretch-card'>
                <Card>
                    <Card.Header>Submenu List</Card.Header>
                    <Card.Body>
                        <Table bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Menu</th>
                                    <th>Parent</th>
                                    <th>Children</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    info && info.data.map((pmenu, i) => {
                                        if (pmenu.category == 0) {
                                            var cate = pmenu.sno;
                                            return (<>{
                                                info && info.data.map((smenu, i) => {
                                                    if (cate == smenu.category)
                                                        return (<>
                                                            <tr>
                                                                <td>{smenu.name}</td>
                                                                <td>{pmenu.name}</td>
                                                                <td>
                                                                    {
                                                                        info && info.data.map((chl) => {
                                                                            if (smenu.sno == chl.category)
                                                                                return (<>
                                                                                    <div className="Container" dangerouslySetInnerHTML={{ __html: chl.name + ",<br/>" }}></div>
                                                                                </>)
                                                                        })
                                                                    }
                                                                </td>
                                                                <td>{smenu.status === 1 ? <div className='bg-success text-white rounded-pill text-center'>Active</div> : <div className='bg-warning text-white rounded-pill text-center'>inactive</div>}</td>
                                                                <td>
                                                                    <DropdownButton id="dropdown-basic-button" title="Action">
                                                                        <Dropdown.Item><Link to={process.env.REACT_APP_BASE_URL+"/admin/submenu/" + smenu.sno}>Edit</Link></Dropdown.Item>
                                                                        <Dropdown.Item onClick={Delete_menu} id={smenu.sno}>Delete</Dropdown.Item>
                                                                    </DropdownButton>
                                                                </td>
                                                            </tr>
                                                        </>)
                                                })
                                            }
                                            </>)
                                        }
                                    })

                                }


                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
        </div>
    </>);
}

export default Submenu;