import Form from 'react-bootstrap/Form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Api from '../Auth/Auth';
const Editpage = () => {
    const toast_alert = (sms, status) => {
        if (status == 0) {
            toast.success(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(sms, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    const url = useParams();
    const urlredirect = useNavigate();
    const [content, getdata] = useState([]);
    useEffect(() => {
        Api.get('/select?table=pages&sno=' + url.sn).then(function (response) {
            getdata(response.data.data[0].content);
            document.getElementById('title').value = response.data.data[0].title;
            document.getElementById('route').value = response.data.data[0].route;
            document.getElementById('css').value = response.data.data[0].css;
            if (response.data.data[0].status == 1) {
                document.getElementById('currnrt_status').innerHTML = '<option value="0">Inactive</option><option value="1" selected>Active</option>';
            }
        }).catch(function (error) {
            console.log(error);
        })
    }, [])
    let handleSubmit = async (e) => {
        e.preventDefault();
        fetch(process.env.REACT_APP_API_URL + '/update_page', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({
                sno: url.sn,
                title: e.target.title.value,
                content: content,
                status: e.target.currnrt_status.value,
                route: e.target.route.value,
                css: e.target.css.value
            })
        }).then(response => response.json())
            .then(data => {
                console.log(data.status);
                if (data.status == 'success') {
                    toast_alert('Page Successfully Update...', 0)
                    urlredirect('/admin/pagelist');
                } else {
                    toast_alert('try agine...!!', 1);
                }
            })
            .catch((error) => {
                toast_alert('Somthing a worng...!!', 1);
            });
    }
    return (<>
        <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className='d-flex justify-content-between'>
                            <div className=''>
                                <h6 className="card-title"> Update</h6>
                                <p className="text-muted mb-3">Page</p>
                            </div>
                            <div>
                                <Link to="/admin/pagelist"><button className='custom-btn'>Page List </button></Link>
                            </div>
                        </div>
                        <div className="table-responsive pt-3">
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control id="title" type="text" placeholder="Title" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Route</Form.Label>
                                    <Form.Control id="route" type="text" placeholder="Route" required />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Content</Form.Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={content}
                                        onChange={(event, editor) => {
                                            getdata(editor.getData());
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Additional CSS</Form.Label><br />
                                    <textarea id="css" cols="30" rows="10" style={{ width: "100%", height: "200px" }}></textarea>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="status">
                                    <Form.Label htmlFor="status">Status</Form.Label>
                                    <Form.Select id="currnrt_status">
                                        <option value="0">Inactive</option>
                                        <option value="1">Active</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="description">
                                    <input type='submit' className="btn btn-outline-primary" value="update" />
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default Editpage;